/**
 * This is the workflow that non-authenticated users can use, entire in the browser. 
 * 
 */

import { Button, Divider, Stack, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { setTextRange } from "typescript";
import ComparisonOrchestratorPage from "../comparison/ComparisonOrchestratorPage";
import { StepSelectionView } from "../comparison/Select";
import { StepProps } from "../comparison/steps/StepViews";
import { ImageSrc, RankingStep } from "../datamodel/DataModel";
import ImageListView from "../gallery/ImageListView";
import { ImagePreview } from "../gallery/upload/ImagePreview";
import { mainBarState } from "../Picelo";

export default function AnonymousWorkflow() {
    const theme = useTheme();

    const mainBarHeight = useRecoilValue(mainBarState);

    const [imageFiles, setImageFiles] = useState<{ [index: string]: File }>({})
    const [thumbnailUrls, setThumbnailUrls] = useState<{ [index: string]: string }>({})
    const [imageSrc, setImageSrc] = useState<Array<ImageSrc>>([]);
    const [imageMap, setImageMap] = useState<{ [index: string]: ImageSrc }>({});
    const [advance, setAdvance] = useState<boolean>(false);
    const [done, setDone] = useState<boolean>(false);
    const [beforeStepStack, setBeforeStepStack] = useState<Array<StepProps>>([]);
    const [afterStepStack, setAfterStepStack] = useState<Array<StepProps>>([]);
    const [selected, setSelected] = useState<Set<string>>(new Set());
    const [ratingFile, setRatingFile] = useState<string>("");

    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }
    useEffect(() => {
        var maxWidth = 1920;
        Object.entries(imageFiles).forEach(([key, value]) => {
            var image = new Image();
            image.onload = function () {
                var canvas: HTMLCanvasElement = document.getElementById("canvas") as HTMLCanvasElement;
                if (image.width > maxWidth) {
                    image.height *= maxWidth / image.width;
                    image.width = maxWidth;
                }
                var ctx = canvas.getContext("2d");
                if (ctx != null) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    canvas.width = image.width;
                    canvas.height = image.height;
                    ctx.drawImage(image, 0, 0, image.width, image.height);
                    setThumbnailUrls(urls => { return { ...urls, [key]: canvas.toDataURL() } });
                }
            }
            image.src = URL.createObjectURL(value);
        }, {} as { [index: string]: string })
        return (() => {
            Object.keys(thumbnailUrls).forEach(url => URL.revokeObjectURL(url));
        })
    }, [imageFiles]);


    useEffect(() =>
        setImageSrc(Object.entries(imageFiles).map(([key, file]) => {
            return {
                id: key + "-" + file.name,
                userId: "",
                source: { type: "url", src: URL.createObjectURL(file) },
                name: file.name,
                createDate: file.lastModified,
                lastModified: file.lastModified,
                type: file.type,
                size: "full",
                alternateSizes: {
                    "thumbnail": { type: "url", src: thumbnailUrls[key] },
                    "medium": { type: "url", src: thumbnailUrls[key] }
                },
                parentFolderId: ""
            } as ImageSrc
        }))
        , [imageFiles, thumbnailUrls])

    useEffect(() => setImageMap(imageSrc.reduce((dict, element) => {
        dict[element.id] = element; return dict;
    }, {} as { [index: string]: ImageSrc })),
        [imageSrc])

    useEffect(() => {
        if (advance) {
            setAfterStepStack(() => {
                let stepStack = beforeStepStack.map((step, index) => {
                    return {
                        ...step,
                        thisStep: {
                            id: index.toString(),
                            userId: "-1",
                            imageRankingId: "-1",
                            name: step.name,
                            source: (index - 1).toString(),
                            target: [],
                            meta: {}
                        },
                        stepMap: {},
                        imageMap: imageMap,
                        setProgress: (s: [number, number]) => void (0)
                    }
                })
                let stepMap = stepStack.reduce((dict, element) => {
                    dict[element.thisStep.id] = element.thisStep; return dict;
                }, {} as { [index: string]: RankingStep });

                stepMap["-1"] = {
                    id: "-1",
                    userId: "-1",
                    imageRankingId: "-1",
                    name: "data",
                    source: "",
                    target: imageSrc.map(image => { return { id: image.id, rating: 0, meta: {} } }),
                    meta: {}
                }
                stepStack = stepStack.map(step => {
                    return { ...step, stepMap: stepMap }
                })
                return stepStack as Array<StepProps>;
            })
        }
    }, [advance, beforeStepStack, imageMap])

    useEffect(() => {
        if (done) {
            setRatingFile(URL.createObjectURL(
                new Blob(
                    [JSON.stringify(afterStepStack, undefined, 2)],
                    { type: "text/plain" }
                )
            ));
        }
    }, [done])

    const uploadPreviewRender = useMemo(() => {
        if (!advance) {

            return <React.Fragment>
                <Typography variant="h6" align="center" color={theme.palette.text.primary} sx={{
                    mt: (mainBarHeight + 8 + "px")
                }}>Select Images</Typography>
                <Box sx={{ height: "65vh", width: "90vw" }}>
                    <ImagePreview
                        maxHeight="65vh"
                        imageFiles={imageFiles}
                        setImageFiles={setImageFiles} />
                </Box>
                <Divider sx={{ m: 8, visibility: "hidden" }} />
            </React.Fragment>
        }
    }, [mainBarHeight, advance, imageFiles, theme]);

    const stepSelection = useMemo(() => {
        if (!advance) {
            return <React.Fragment>
                <Stack spacing={2} sx={{ alignItems: "center" }}>
                    <StepSelectionView stepStack={beforeStepStack} setStepStack={setBeforeStepStack} images={imageSrc} />
                    <Box><Button onClick={() => setAdvance(true)} variant="contained">Start</Button></Box>
                </Stack>
            </React.Fragment>
        }
        else {
            return undefined;
        }
    }, [advance, beforeStepStack, imageSrc])

    const downloadRatings = useMemo(() =>
        <Box sx={{ display: "flex", justifyContent: "center", mt: mainBarHeight + 8 + "px" }}>
            <Button component="a" variant="contained" href={ratingFile} download="ratings.txt">Download Ratings</Button>
            <Button onClick={() => window.location.reload()} variant="contained">Reset</Button>
        </Box>
        , [ratingFile])

    const comparisonOrchestrator = useMemo(() => {
        if (!done) {
            return <React.Fragment>
                <ComparisonOrchestratorPage
                    setDone={setDone}
                    stepStack={afterStepStack}
                    setStepStack={setAfterStepStack} />
            </React.Fragment>
        }
        else {
            return downloadRatings
        }
    }, [stepSelection, afterStepStack, done, downloadRatings])

    const listView = useMemo(() => {
        if (done) {
            return <Box sx={{ p: 4 }}>
                <ImageListView
                    images={{
                        ordering: afterStepStack[afterStepStack.length - 1]
                            .thisStep.target.sort((a, b) => a.rating - b.rating).reverse().map(i => i.id),
                        images: imageMap,
                    }}
                    ratingMap={afterStepStack[afterStepStack.length - 1]
                        .thisStep.target.reduce((dict, element) => {
                            dict[element.id] = element.rating; return dict;
                        }, {} as { [index: string]: number })}
                    contentWidth={windowX}
                    selected={selected}
                    setSelected={setSelected} />
            </Box>
        }
        else {
            return undefined;
        }
    }, [done, afterStepStack, selected, windowX, imageMap])


    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 1
        }}>
            <canvas id="canvas" style={{ visibility: 'hidden', height: 1, width: 1 }} />
            {uploadPreviewRender}
            {stepSelection}
            {comparisonOrchestrator}
            {listView}
        </Box>
    );
    // Images are resized to 1080p
    // Image gallery, restricted to 80% of the page height
    // Reuse the Select view's ranker choice
    // Create temporary ImageRanking
    // Reuse the ranking steps 
}

/**
 * Just renders everything
 */
function WorkflowView() {

}