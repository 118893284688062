import { AddAPhoto, AddAPhotoOutlined, CreateNewFolder, CreateNewFolderOutlined, DeleteOutline, DeselectOutlined, DriveFileMoveOutlined, GridView, Home, MenuSharp, SelectAllOutlined, ViewCarousel } from "@mui/icons-material";
import { AppBar, Backdrop, Breadcrumbs, Button, CircularProgress, Divider, FormControl, IconButton, InputLabel, Link, List, ListItemButton, ListItemText, MenuItem, Select, Skeleton, skeletonClasses, SpeedDial, SpeedDialAction, SpeedDialIcon, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/system";
import React, { Dispatch, MouseEvent, SetStateAction, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import { createFolder, deleteFolder, getFoldersByUserId, moveFolder } from "../api/FolderApi";
import { deleteImages, getFolderImages, getImagesByIds, moveImages } from "../api/ImageApi";
import { getRankingSteps, getUserImageRankings } from "../api/ImageRankingApi";
import { deleteImagesTags, getUserTags, updateImagesTags } from "../api/TagApi";
import { FolderTree, FolioDrawer } from "../components/FolioDrawer";
import TagBar from "../components/TagBar";
import { Folder, ImageRanking, ImageSrc, RankingStep, User } from "../datamodel/DataModel";
import { mainBarState, subBarState } from "../Picelo";
import { sortString } from "../util/sortString";
import CarouselView, { singleHighlightedState } from "./CarouselView";
import FolderMoveDialog from "./FolderMoveDialog";
import ImageMoveDialog from "./ImageMoveDialog";
import ImageUploadDialog from "./upload/ImageUploadDialog";
import ImageListView from "./ImageListView";
import NewFolderDialog from "./NewFolderDialog";


/**
 * Is responsible for determining and loading the images it is responsible for.
 * We'll try with the image list for now and have folders listed to the side. 
 * 
 * The folder hierarchy will be retreived separately from the images. 
 */
export default function Gallery(props: GalleryProps) {

  const [ordering, setOrdering] = useState<string>("name-ascending");

  const [contextImages, setContextImages] = useState<{ ordering: Array<string>, images: { [index: string]: ImageSrc } }>({ ordering: [], images: {} });

  const [rootFolder, setRootFolder] = useState<Folder>();
  const [folders, setFolders] = useState<{ [index: string]: Folder }>({});

  const [imageRankings, setImageRankings] = useState<Array<ImageRanking>>([]);
  const [rankingSteps, setRankingSteps] = useState<Array<RankingStep>>([]);
  const [selectedRankingStep, setSelectedRankingStep] = useState<RankingStep>();
  const [selectedRatingMap, setSelectedRatingMap] = useState<{ [index: string]: number }>({});

  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState<boolean>(false);
  const [folderToMove, setFolderToMove] = useState<Folder>();
  const [moveFolderDialogOpen, setMoveFolderDialogOpen] = useState<boolean>(false);
  const [moveImageDialogOpen, setMoveImageDialogOpen] = useState<boolean>(false);

  const [displayChoice, setDisplayChoice] = useState(localStorage.getItem("displayChoice") ?? "list");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const [drawerWidth, setDrawerWidth] = useState<number>(250);

  const [selectedFolio, setSelectedFolio] = useState<Folder | ImageRanking>();
  const [selectedImage, setSelectedImage] = useState<Set<string>>(new Set());
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [userTags, setUserTags] = useState<Array<string>>([]);
  const [commonTags, setCommonTags] = useState<Array<string>>([]);
  const [imageToTags, setImageToTags] = useState<{ [index: string]: Array<string> }>({});
  const [updatedTags, setUpdatedTags] = useState<{ new: Array<string>, deleted: Array<string> }>({ new: [], deleted: [] });

  const [reload, setReload] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);

  const controller = new AbortController();
  const signal = controller.signal;
  const isMounted = { isMounted: true };

  const [windowY, setWindowY] = useState<number>(window.innerHeight);
  const [windowX, setWindowX] = useState<number>(window.innerWidth);

  window.addEventListener("resize", handleResize);

  function handleResize() {
    setWindowY(window.innerHeight);
    setWindowX(window.innerWidth);
  }

  const [mainBarHeight, setMainBarHeight] = useRecoilState(mainBarState);
  const [appBarHeight, setAppBarHeight] = useRecoilState(subBarState);
  const [singleHighlighted, setSingleHighlighted] = useRecoilState(singleHighlightedState);

  const theme = useTheme();

  const appBarRef = (node: HTMLDivElement) => {
    if (node != null) {
      setAppBarHeight(node.clientHeight)
    }
  };

  const contentWidthPx = useMemo(() =>
    ((isDrawerOpen ? -drawerWidth : 0) + windowX - 20)
    , [windowX, drawerWidth, isDrawerOpen])

  useEffect(() => {
    return () => {
      isMounted.isMounted = false;
      controller.abort();
    };
  }, [])

  useEffect(() => {
    if (reload && props.user != undefined) {
      let user = props.user;
      getFoldersByUserId(user.id, signal)
        .then(result => {
          if (Object.keys(result).length > 0) {
            setFolders(folder => result);
            setRootFolder(folder => result[user.rootFolderId]);
            setSelectedFolio(location => {
              let local = localStorage.getItem("selectedFolio");
              if (local != null && local != undefined) {
                return JSON.parse(local);
              }
              else {
                return result[user.rootFolderId]
              }
            })
          }
        })
      getUserImageRankings(user.id, signal)
        .then(rankings => setImageRankings(rankings));
      getUserTags(props.user.id, signal)
        .then(tags => {
          setUserTags(tags.map(tag => tag.name));
          setImageToTags(tags.reduce((acc, tag) => {
            for (let image of tag.imageIds) {
              if (!acc[image]) {
                acc[image] = [tag.name]
              }
              else {
                acc[image] = [...acc[image], tag.name]
              }
            }
            return acc;
          }, {} as { [index: string]: Array<string> })
          )
        })
      setReload(false);
    }
  }, [props.user, reload])


  useEffect(() => {
    if (selectedFolio !== undefined) {
      getRankingSteps((selectedFolio as ImageRanking).id, signal).then(rankings => {
        let sourceMap = rankings.reduce((dict, e) => { dict[e.source] = e; return dict; }, {} as { [index: string]: RankingStep })
        let presentSet = rankings.reduce((set, e) => { set.add(e.id); return set; }, new Set<string>());
        let orderedRankings = []
        let start = rankings.find(step => !presentSet.has(step.source))
        if (start) {
          orderedRankings.push(start);
          let current = start.id;
          while (current && sourceMap[current]) {
            orderedRankings.push(sourceMap[current]);
            current = sourceMap[current].id;
          }
          setRankingSteps(orderedRankings);
          setSelectedRankingStep(orderedRankings[orderedRankings.length - 1]);
        }
      })
    }
  }, [selectedFolio])

  // Get images per folder
  useEffect(() => {
    if (selectedFolio !== undefined && (selectedFolio as Folder).parentFolderIds !== undefined) {
      getFolderImages(selectedFolio.id, signal)
        .then(newThumbnails => {
          if (newThumbnails instanceof Error) {

          }
          else if (isMounted.isMounted) {
            setContextImages({
              ordering: sortByOrdering(ordering, newThumbnails),
              images: newThumbnails.reduce((map, image) => { return { ...map, [image.id]: image } }, {})
            });
          }
        })
    }
    else if (selectedFolio !== undefined && (selectedFolio as ImageRanking).imageIds !== undefined) {
      getImagesByIds((selectedFolio as ImageRanking).imageIds).then(newThumbnails => {
        if (newThumbnails instanceof Error) {

        }
        else if (isMounted.isMounted) {
          setContextImages({
            ordering: sortByOrdering(ordering, newThumbnails, selectedRatingMap),
            images: newThumbnails.reduce((map, image) => { return { ...map, [image.id]: image } }, {})
          });
        }
      });

    }
  }, [ordering, selectedFolio, folders, rankingSteps, selectedRatingMap])

  useEffect(() => {
    if (selectedRankingStep) {
      setSelectedRatingMap(selectedRankingStep.target.reduce((dict, element) => {
        dict[element.id] = element.rating; return dict;
      }, {} as { [index: string]: number }))
    }
  }, [selectedRankingStep])

  useEffect(() => {
    setSelectedImage(s => new Set(s));
    if (contextImages.ordering.length > 0) {
      setLoading(false);
    }
  }, [contextImages])

  useEffect(() => {
    if (selectedImage.size > 0 || (displayChoice == "carousel" && singleHighlighted != undefined)) {
      let images: Array<ImageSrc> = [];
      if (displayChoice == "carousel" && singleHighlighted != undefined) {
        images.push(singleHighlighted);
      }
      selectedImage.forEach(id => images.push(contextImages.images[id]))
      setCommonTags(images.reduce((collector, image) =>
        collector.filter(value => imageToTags[image.id] && imageToTags[image.id].includes(value)), imageToTags[images[0].id] ?? []));
    }
  }, [selectedImage, singleHighlighted, contextImages])

  useEffect(() => {
    if (selectAll) {
      setSelectedImage(s => {
        Object.keys(contextImages.images).forEach(key => s.add(key));
        return new Set(s);
      })
    } else {
      setSelectedImage(s => {
        Object.keys(contextImages.images).forEach(key => s.delete(key));
        return new Set(s);
      })
    }
  }, [selectAll])

  useEffect(() => {
    if (displayChoice == "carousel" && singleHighlighted) {
      if (updatedTags.new.length > 0) {
        updateImagesTags([singleHighlighted.id], updatedTags.new, signal)
          .then(() => { props.setReloadUser(true); setReload(true); });
      }
      if (updatedTags.deleted.length > 0) {
        Promise.resolve(deleteImagesTags([singleHighlighted.id], updatedTags.deleted, signal))
          .then(() => { props.setReloadUser(true); setReload(true); });
      }
    }
    else if (selectedImage.size > 0) {
      if (updatedTags.new.length > 0) {
        updateImagesTags(Array.from(selectedImage), updatedTags.new, signal)
          .then(() => { props.setReloadUser(true); setReload(true); });
      }
      if (updatedTags.deleted.length > 0) {
        deleteImagesTags(Array.from(selectedImage), updatedTags.deleted, signal)
          .then(() => { props.setReloadUser(true); setReload(true); });
      }
    }
  }, [updatedTags])

  const handleRenderChoice = useCallback((event: MouseEvent<HTMLElement>, newChoice: string) => {
    if (newChoice != null) {
      localStorage.setItem("displayChoice", newChoice);
      setDisplayChoice(newChoice);
    }
  }, []);

  const handleFolderSelect = useCallback((folder: Folder) => {
    if (folder !== selectedFolio) {
      if (ordering === "rating-ascending") {
        setOrdering("name-ascending")
      }

      if (ordering === "rating-descending") {
        setOrdering("name-descending")
      }
      setSelectedRankingStep(undefined);
      setSelectedRatingMap({});
      setRankingSteps([]);
      let location = [...folder.parentFolderIds.map(parentFolderId => folders[parentFolderId]), folder];
      setSelectedFolio(folder);
      setSelectAll(false);
      setSelectedImage(new Set());
      localStorage.setItem("selectedFolio", JSON.stringify(folder))
      localStorage.setItem("folderLocation", JSON.stringify([...folder.parentFolderIds.map(parentFolderId => folders[parentFolderId]), folder]));
    }
  }, [selectedFolio, folders, ordering]);

  const handleNewFolder = useCallback((event: SyntheticEvent, newFolderName: string) => {
    if (selectedFolio) {
      createFolder(newFolderName, props.user.id, [...(selectedFolio as Folder).parentFolderIds, selectedFolio.id])
        .then(folder => {
          setReload(true)
        })
    }
    setNewFolderDialogOpen(false);
  }, [selectedFolio]);

  const handleOpenDialogCancel = useCallback((event: SyntheticEvent) => {
    setNewFolderDialogOpen(false);
    setMoveFolderDialogOpen(false);
    setMoveImageDialogOpen(false);
  }, []);

  const handleFolderDelete = useCallback((folder: Folder) => {
    setLoading(true);
    deleteFolder(folder).then(() => {
      setReload(true);
      setLoading(false);
      handleFolderSelect(folders[folder.parentFolderIds[folder.parentFolderIds.length - 1]])
    })
  }, [folders])

  const handleImageDelete = useCallback(() => {
    if (selectedImage.size > 0) {
      setLoading(true);
      deleteImages(Array.from(selectedImage).map(image => contextImages.images[image])).then(() => {
        setReload(true);
        setLoading(false);
        setSelectedImage(new Set());
        setSelectAll(false);
      })
    }
  }, [selectedImage, contextImages]);

  const handleImageMove = useCallback((event: SyntheticEvent, destinationFolder: string) => {
    setMoveImageDialogOpen(false);
    if (selectedImage.size > 0) {
      setLoading(true);
      moveImages(Array.from(selectedImage).map(image => contextImages.images[image]), destinationFolder).then(() => {
        setLoading(false);
        setReload(true);
        setSelectedImage(new Set());
        setSelectAll(false);
      })
    }
  }, [selectedImage, contextImages])

  const handleFolderSelectMove = useCallback((folder: Folder) => {
    setFolderToMove(folder);
    setMoveFolderDialogOpen(true);
  }, [])

  const handleFolderMove = useCallback((event: SyntheticEvent, destinationFolder: string) => {
    if (folderToMove !== undefined) {
      setMoveFolderDialogOpen(false);
      moveFolder(folderToMove, destinationFolder).then(() => {
        setReload(true);
        setFolderToMove(undefined);
        setSelectedImage(new Set());
        setSelectAll(false);
      })
    }
  }, [folderToMove]);

  function handleRankingSelect(ranking: ImageRanking) {
    setSelectedFolio(ranking);
  }

  const drawerButton = useMemo(() =>
    <Button
      color="primary"
      disabled={rootFolder == undefined}
      onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      variant={isDrawerOpen ? "contained" : "outlined"}
      sx={{ mr: "1rem" }}>
      <MenuSharp />
    </Button>,
    [rootFolder, isDrawerOpen])

  const createButtons = useMemo(() =>
    <React.Fragment>
      {selectedFolio && (selectedFolio as Folder).parentFolderIds ? <Tooltip title="Upload Images">
        <IconButton
          color="primary"
          onClick={() => setIsUploadOpen(true)}>
          {isUploadOpen ? <AddAPhoto /> : <AddAPhotoOutlined />}
        </IconButton>
      </Tooltip> : null}
      {selectedFolio && (selectedFolio as Folder).parentFolderIds ? <Tooltip title="New Folder">
        <IconButton
          color="primary"
          onClick={() => setNewFolderDialogOpen(true)}>
          {newFolderDialogOpen ? <CreateNewFolder /> : <CreateNewFolderOutlined />}
        </IconButton>
      </Tooltip> : null}
    </React.Fragment>,
    [isUploadOpen, newFolderDialogOpen, selectedFolio]);

  const modifyButtons = useMemo(() =>
    selectedImage.size > 0 || (displayChoice == "carousel" && singleHighlighted != undefined) ?
      <React.Fragment>
        <TagBar freeSolo
          tags={userTags}
          selectedTags={commonTags}
          deletedTags={updatedTags.deleted}
          setUpdatedTags={setUpdatedTags} />
        <Tooltip title="Delete">
          <IconButton
            color="primary"
            onClick={() => handleImageDelete()}><DeleteOutline /></IconButton>
        </Tooltip>
        <Tooltip title="Move">
          <IconButton
            color="primary"
            onClick={() => setMoveImageDialogOpen(true)}><DriveFileMoveOutlined /></IconButton>
        </Tooltip>
      </React.Fragment>
      : null
    , [displayChoice, singleHighlighted, userTags, commonTags, updatedTags, selectedImage])


  const selectRenderButtons = useMemo(() =>
    <React.Fragment>
      {selectAll || selectedImage.size > 0 ? <Tooltip title={"Clear Selection"}>
        <IconButton
          color="primary"
          onClick={() => { setSelectAll(s => false); setSelectedImage(new Set()); }}><DeselectOutlined /></IconButton>
      </Tooltip> : null}
      {!selectAll ? <Tooltip title={"Select All"}>
        <IconButton
          color="primary"
          onClick={() => setSelectAll(s => true)}><SelectAllOutlined /></IconButton>
      </Tooltip> : null}
      <Divider orientation="vertical" sx={{ mx: 1 }} />
      <FormControl>
        <InputLabel>Sort by:</InputLabel>
        {selectedFolio && (selectedFolio as ImageRanking).imageIds ?
          <Select value={ordering} onChange={e => setOrdering(e.target.value)} size="small" label="Sort by:" >
            <MenuItem key={1} value="none">None</MenuItem>
            <MenuItem key={2} value="rating-descending">Rating high to low</MenuItem>
            <MenuItem key={3} value="rating-ascending">Rating low to high</MenuItem>
            <MenuItem key={4} value="name-ascending">Name A to Z</MenuItem>
            <MenuItem key={5} value="name-descending">Name Z to A</MenuItem>
            <MenuItem key={6} value="create-time-ascending">Least recent</MenuItem>
            <MenuItem key={7} value="create-time-descending">Most recent</MenuItem>
          </Select>
          :
          <Select value={ordering} onChange={e => setOrdering(e.target.value)} size="small" label="Sort by:" >
            <MenuItem key={1} value="none">None</MenuItem>
            <MenuItem key={4} value="name-ascending">Name A to Z</MenuItem>
            <MenuItem key={5} value="name-descending">Name Z to A</MenuItem>
            <MenuItem key={6} value="create-time-ascending">Least recent</MenuItem>
            <MenuItem key={7} value="create-time-descending">Most recent</MenuItem>
          </Select>
        }
      </FormControl>
      {selectedFolio && (selectedFolio as ImageRanking).imageIds && rankingSteps.length > 0 && selectedRankingStep ?
        <FormControl>
          <InputLabel>Step:</InputLabel>
          <Select
            value={JSON.stringify(selectedRankingStep)}
            renderValue={v => JSON.parse(v).name}
            onChange={e => setSelectedRankingStep(JSON.parse(e.target.value))}
            size="small" label="Step:" >
            {  //@ts-ignore
              rankingSteps.map((step, index) => (<MenuItem key={index} value={JSON.stringify(step)}>{index + " - " + step.name}</MenuItem>))}
          </Select>
        </FormControl> : null}
      <Divider orientation="vertical" sx={{ mx: 1 }} />
      <ToggleButtonGroup
        color="primary" value={displayChoice} exclusive onChange={handleRenderChoice}>
        <ToggleButton value="list">
          <GridView />
        </ToggleButton>
        <ToggleButton value="carousel">
          <ViewCarousel />
        </ToggleButton>
      </ToggleButtonGroup>
    </React.Fragment>,
    [selectAll, selectedImage, displayChoice, selectedFolio, selectedRankingStep, rankingSteps])

  const folderTreeSelector = useMemo(() =>
    < FolderTree
      selected={selectedFolio && (selectedFolio as Folder).parentFolderIds ? (selectedFolio as Folder).id : ""}
      moveable
      deletable
      key={2}
      folders={folders}
      rootFolder={rootFolder}
      handleFolderSelectMove={handleFolderSelectMove}
      handleFolderClick={handleFolderSelect}
      handleFolderDelete={handleFolderDelete} />,
    [folders, rootFolder, selectedFolio])

  const imageRankingSelector = useMemo(() =>
    <ImageRankingList
      key={4}
      selected={selectedFolio && (selectedFolio as ImageRanking).imageIds ? selectedFolio as ImageRanking : undefined}
      imageRankings={imageRankings}
      handleRankingSelect={handleRankingSelect} />,
    [imageRankings, selectedFolio])

  const imageUploadDialog = useMemo(() =>
    <ImageUploadDialog
      setReload={setReload}
      isOpen={isUploadOpen}
      setIsOpen={setIsUploadOpen}
      currentFolder={selectedFolio as Folder} />,
    [isUploadOpen]);

  const newFolderDialog = useMemo(() =>
    <NewFolderDialog
      open={newFolderDialogOpen}
      handleSubmit={handleNewFolder}
      handleCancel={handleOpenDialogCancel} />,
    [newFolderDialogOpen]);

  const imageMoveDialog = useMemo(() =>
    <ImageMoveDialog
      open={moveImageDialogOpen}
      handleSubmit={handleImageMove}
      handleCancel={handleOpenDialogCancel}
      rootFolder={rootFolder as Folder}
      folders={folders} />,
    [moveImageDialogOpen, rootFolder, folders]);

  const folderMoveDialog = useMemo(() =>
    <FolderMoveDialog
      open={moveFolderDialogOpen}
      handleSubmit={handleFolderMove}
      handleCancel={handleOpenDialogCancel}
      rootFolder={rootFolder as Folder}
      folders={folders} />,
    [moveFolderDialogOpen, rootFolder, folders]);

  const listDisplay = useMemo(() =>
    <ImageListView
      setSelected={setSelectedImage}
      selected={selectedImage}
      contentWidth={contentWidthPx}
      ratingMap={selectedRatingMap}
      images={contextImages} />,
    [selectedImage, selectedRatingMap, selectedRankingStep, contentWidthPx, contextImages, ordering]);

  const carouselDisplay = useMemo(() =>
    <CarouselView
      selected={contextImages.images[contextImages.ordering[0]]}
      images={contextImages} />,
    [windowX, windowY, contextImages]);


  const imageDisplay = useMemo(() => <Box
    sx={{
      ml: isDrawerOpen ? drawerWidth + "px" : 0,
      flexShrink: 1,
      width: contentWidthPx + "px",
      height: "auto",
      boxSizing: "border-box"
    }} >
    {displayChoice == "list" ? listDisplay : null}
    {displayChoice == "carousel" ? carouselDisplay : null}
  </Box>,
    [isDrawerOpen, drawerWidth, displayChoice, listDisplay, carouselDisplay, selectedFolio, selectedImage])

  const folioDrawerDisplay = useMemo(() =>
    <FolioDrawer
      isDrawerOpen={isDrawerOpen}
      handleDrawer={() => setIsDrawerOpen(!isDrawerOpen)}
      drawerWidth={drawerWidth}
      drawerHeight="100%"
      anchor="left"
      variant="persistent"
      setSize={setDrawerWidth}
      components={[
        <Divider key={1}>Folders</Divider>,
        folderTreeSelector,
        <Divider key={3}>Rankings</Divider>,
        imageRankingSelector]} />,
    [isDrawerOpen, drawerWidth, folderTreeSelector, imageRankingSelector])

  const loadingRender = useMemo(() => {
    if (loading) {
      return (
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: 5000, display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Backdrop>
      )
    }
    else {
      return null;
    }
  }, [loading])

  const everythingSpeedDial = useMemo(() =>
    <SpeedDial
      ariaLabel="Small screen speedial"
      sx={{ position: 'absolute', bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />} >
      {displayChoice !== "carousel" ?
        < SpeedDialAction
          key={"Carousel View"}
          tooltipTitle={"Carousel"}
          icon={<ViewCarousel />}
          tooltipOpen
          onClick={(e) => handleRenderChoice(e, "carousel")} /> : null}
      {displayChoice !== "list" ?
        <SpeedDialAction
          key={"List View"}
          tooltipTitle={"List"}
          icon={<GridView />}
          tooltipOpen
          onClick={(e) => handleRenderChoice(e, "list")} /> : null}
      {!selectAll ?
        <SpeedDialAction
          key={"Select All"}
          tooltipTitle={"Select All"}
          icon={<SelectAllOutlined />}
          tooltipOpen
          onClick={() => setSelectAll(s => true)} /> : null}
      {selectAll || selectedImage.size > 0 ?
        <SpeedDialAction
          key={"Clear Selection"}
          tooltipTitle={"Clear Selection"}
          icon={<DeselectOutlined />}
          tooltipOpen
          onClick={() => { setSelectAll(s => false); setSelectedImage(new Set()); }} /> : null}
      {selectedImage.size > 0 || (displayChoice == "carousel" && singleHighlighted != undefined) ?
        <SpeedDialAction
          key={"Move"}
          tooltipTitle={"Move"}
          icon={<DriveFileMoveOutlined />}
          tooltipOpen
          onClick={() => setMoveImageDialogOpen(true)} /> : null}
      {selectedImage.size > 0 || (displayChoice == "carousel" && singleHighlighted != undefined) ?
        <SpeedDialAction
          key={"Delete"}
          tooltipTitle={"Delete"}
          icon={<DeleteOutline />}
          tooltipOpen
          onClick={() => handleImageDelete()} /> : null}
      {selectedFolio && (selectedFolio as Folder).parentFolderIds ?
        <SpeedDialAction
          key={"New Folder"}
          tooltipTitle={"New Folder"}
          icon={newFolderDialogOpen ? <CreateNewFolder /> : <CreateNewFolderOutlined />}
          tooltipOpen
          onClick={() => setNewFolderDialogOpen(true)}
        /> : null}
      {selectedFolio && (selectedFolio as Folder).parentFolderIds ?
        <SpeedDialAction
          key={"Upload Images"}
          tooltipTitle={"Upload"}
          icon={isUploadOpen ? <AddAPhoto /> : <AddAPhotoOutlined />}
          tooltipOpen
          onClick={() => setIsUploadOpen(true)} /> : null}
    </SpeedDial >
    , [, selectedFolio, selectedImage, displayChoice, singleHighlighted, selectAll])

  const largeToolbar = useMemo(() =>
    <Toolbar sx={{ display: "flex", flexWrap: "wrap" }}>
      {drawerButton}
      {createButtons}
      <Divider orientation="vertical" sx={{ mx: 1 }} />
      {selectedFolio && (selectedFolio as Folder).parentFolderIds ? <BreadCrumbs
        folder={selectedFolio as Folder}
        folders={folders} handleFolderSelect={handleFolderSelect} /> : null}
      <Divider orientation="vertical" sx={{ flexGrow: 1, visibility: 'hidden' }} />
      {modifyButtons}
      <Divider orientation="vertical" sx={{ flexGrow: 0, visibility: 'hidden' }} />
      {selectRenderButtons}
    </Toolbar>, [drawerButton, createButtons, selectedFolio, folders, modifyButtons, selectRenderButtons])

  const smallToolbar = useMemo(() =>
    <Toolbar sx={{ display: "flex", flexWrap: "wrap" }}>
      {drawerButton}
      <Divider orientation="vertical" sx={{ flexGrow: 1, visibility: 'hidden' }} />
      {selectedImage.size > 0 || (displayChoice == "carousel" && singleHighlighted != undefined) ?
        <TagBar freeSolo
          tags={userTags}
          selectedTags={commonTags}
          deletedTags={updatedTags.deleted}
          direction="bottom"
          setUpdatedTags={setUpdatedTags} /> : null}
      <FormControl>
        <InputLabel>Sort by:</InputLabel>
        {selectedFolio && (selectedFolio as ImageRanking).imageIds ?
          <Select value={ordering} onChange={e => setOrdering(e.target.value)} size="small" label="Sort by:" >
            <MenuItem key={1} value="none">None</MenuItem>
            <MenuItem key={2} value="rating-descending">Rating high to low</MenuItem>
            <MenuItem key={3} value="rating-ascending">Rating low to high</MenuItem>
            <MenuItem key={4} value="name-ascending">Name A to Z</MenuItem>
            <MenuItem key={5} value="name-descending">Name Z to A</MenuItem>
            <MenuItem key={6} value="create-time-ascending">Least recent</MenuItem>
            <MenuItem key={7} value="create-time-descending">Most recent</MenuItem>
          </Select>
          :
          <Select value={ordering} onChange={e => setOrdering(e.target.value)} size="small" label="Sort by:" >
            <MenuItem key={1} value="none">None</MenuItem>
            <MenuItem key={4} value="name-ascending">Name A to Z</MenuItem>
            <MenuItem key={5} value="name-descending">Name Z to A</MenuItem>
            <MenuItem key={6} value="create-time-ascending">Least recent</MenuItem>
            <MenuItem key={7} value="create-time-descending">Most recent</MenuItem>
          </Select>
        }
      </FormControl>
      {selectedFolio && (selectedFolio as ImageRanking).imageIds && rankingSteps.length > 0 && selectedRankingStep ?
        <FormControl>
          <InputLabel>Step:</InputLabel>
          <Select
            value={JSON.stringify(selectedRankingStep)}
            renderValue={v => JSON.parse(v).name}
            onChange={e => setSelectedRankingStep(JSON.parse(e.target.value))}
            size="small" label="Step:" >
            {  //@ts-ignore
              rankingSteps.map((step, index) => (<MenuItem key={index} value={JSON.stringify(step)}>{index + " - " + step.name}</MenuItem>))}
          </Select>
        </FormControl> : null}
      <Divider orientation="vertical" sx={{ flexGrow: 0, visibility: 'hidden' }} />
    </Toolbar>, [drawerButton, selectedImage, displayChoice, selectedFolio, folders, ordering, selectedRankingStep, rankingSteps])


  return (
    <Box sx={{ display: "block", minHeight: "100vh" }}>
      <Box sx={{ display: 'flex' }}>
        <AppBar
          ref={appBarRef}
          position="fixed"
          color="default"
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: (mainBarHeight + "px"),
            zIndex: 2100,
            boxShadow: 0
          }}>
          {windowX > 800 ? largeToolbar : smallToolbar}
        </AppBar>
        {loadingRender}
        {folioDrawerDisplay}
        {imageUploadDialog}
        {newFolderDialog}
        {imageMoveDialog}
        {folderMoveDialog}
        {windowX > 800 ? null : everythingSpeedDial}
      </Box>
      {imageDisplay}
    </Box>
  );

}

export interface GalleryProps {
  user: User
  setReloadUser: Dispatch<SetStateAction<boolean>>
}

function ImageRankingList(props: ImageRankingListProps) {
  return (
    <List>
      {props.imageRankings.map(imageRanking =>
        <ListItemButton selected={props.selected ? props.selected.id === imageRanking.id : undefined} onClick={() => props.handleRankingSelect(imageRanking)} key={imageRanking.id}>
          <ListItemText primary={imageRanking.name} secondary={imageRanking.imageIds.length + " images"} />
        </ListItemButton>
      )}
    </List>
  )
}

interface ImageRankingListProps {
  selected: ImageRanking | undefined
  imageRankings: Array<ImageRanking>
  handleRankingSelect: (ranking: ImageRanking) => void
}

function BreadCrumbs(props: BreadCrumbsProps) {
  const folderLocation = [...props.folder.parentFolderIds.map(parentFolderId => props.folders[parentFolderId]), props.folder];
  const crumbs = useMemo(() => folderLocation.map((folder) => {
    if (folder === folderLocation[folderLocation.length - 1]) {
      return (
        <Link key={folder.name} href="/" underline="always" color="inherit">
          {folder === folderLocation[0] ? <Home sx={{ mr: 0.5 }} fontSize="inherit" /> : null}
          {folder.name}
        </Link>
      );
    }
    else {
      return (
        <Link key={folder.name} href="/" underline="hover" color="inherit" onClick={(e) => { e.preventDefault(); props.handleFolderSelect(folder) }}>
          {folder === folderLocation[0] ? <Home sx={{ mr: 0.5 }} fontSize="inherit" /> : null}
          {folder.name}
        </Link>
      );
    }
  }), [folderLocation])

  return <Breadcrumbs>
    {crumbs}
  </Breadcrumbs>;
}

interface BreadCrumbsProps {
  folder: Folder
  folders: { [index: string]: Folder }
  handleFolderSelect: (folder: Folder) => void
}

function sortByOrdering(ordering: string, images: Array<ImageSrc>, ratings?: { [index: string]: number }): Array<string> {
  switch (ordering) {
    case "none":
      return images.map(image => image.id);
    case "rating-ascending":
      if (ratings !== undefined) {
        return images.map(image => image.id).sort((a, b) => (ratings[a] ? ratings[a] : 0) - (ratings[b] ? ratings[b] : 0));
      }
      else {
        return images.map(image => image.id);
      }
    case "rating-descending":
      if (ratings !== undefined) {
        return images.map(image => image.id).sort((a, b) => (ratings[a] ? ratings[a] : 0) - (ratings[b] ? ratings[b] : 0)).reverse();
      }
      else {
        return images.map(image => image.id);
      }
    case "name-ascending":
      return images.sort(sortString).map(images => images.id);
    case "name-descending":
      return images.sort(sortString).reverse().map(images => images.id);
    case "create-time-ascending":
      return images.sort((a, b) => a.createDate - b.createDate).map(images => images.id);
    case "create-time-descending":
      return images.sort((a, b) => a.createDate - b.createDate).reverse().map(images => images.id);
    default:
      return images.map(image => image.id);
  }
}

function handleRenderChoice() {
  throw new Error("Function not implemented.");
}
