import { Box, Button, Stack, TextField } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import FourPaneView from "../../components/FourPaneView";
import { PreviewList } from "../../components/PreviewList";
import RatingBar from "../../components/RatingBar";
import { SingleImage } from "../../components/SingleImage";
import { ImageSrc, RankingStep, StepDatum } from "../../datamodel/DataModel";
import { mainBarState, subBarState } from "../../Picelo";
import { stepStates } from "../ComparisonOrchestratorPage";

/**
 * parent provides step and setters - orchestrates interstep behaviors.
 * 
 * We assume that the "this" step is the new step. The reference step, if it exists, 
 * is the original step. We update the this step with the results. The orchestrator then
 * decides what to do with the this step data w.r.t the reference steps.
 */
export interface StepProps {
    name: string
    thisStep: RankingStep
    referenceStep?: RankingStep // for merging in new data
    setThisStep: (s: RankingStep) => void // for setting the final result of this step
    setReferenceStep?: (s: RankingStep) => void // for setting the final result of this step
    stepMap: { [index: string]: RankingStep }
    imageMap: { [index: string]: ImageSrc }
    setProgress: (s: [number, number]) => void
}

export interface CurrentStep {
    readQueue: Array<StepDatum> | Array<[StepDatum, StepDatum]>
    current: StepDatum | [StepDatum, StepDatum]
    writeQueue: Array<StepDatum>
}

export function QualityStep(props: QualityStepProps) {
    const [readQueue, setReadQueue] = useState<StepDatum[]>([]);
    const [writeQueue, setWriteQueue] = useState<StepDatum[]>([]);
    const [current, setCurrent] = useState<StepDatum>();
    const [value, setValue] = useState<number>(-1);
    const [undo, setUndo] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);
    const [progress, setProgress] = useState<[number, number]>([0, 0]);

    const [appBarHeight, setAppBarHeight] = useRecoilState(subBarState);
    const [mainBarHeight, setMainBarHeight] = useRecoilState(mainBarState);

    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }

    const [stepState, setStepState] = useRecoilState(stepStates);

    useEffect(() => {
        if (stepState[props.thisStep.id] === undefined) {
            setReadQueue(props.stepMap[props.thisStep.source].target);
            setProgress([0, props.stepMap[props.thisStep.source].target.length])
            props.setProgress([0, props.stepMap[props.thisStep.source].target.length])
            setWriteQueue([]);
            setCurrent(undefined);
        }
        else {
            setReadQueue(stepState[props.thisStep.id].readQueue as Array<StepDatum>);
            setCurrent(stepState[props.thisStep.id].current as StepDatum);
            setWriteQueue(stepState[props.thisStep.id].writeQueue);
        }
    }, [props.thisStep])

    useEffect(() => {
        if (readQueue.length > 0 && current == undefined) {
            setCurrent(readQueue[0]);
            setReadQueue(queue => [...queue.slice(1, queue.length)])
        }
        else if (readQueue.length == 0 && writeQueue.length > 0 && current == undefined) {
            props.setThisStep({ ...props.thisStep, target: writeQueue })
        }
    }, [readQueue])

    useEffect(() => {
        if (submit && current != undefined) {
            setWriteQueue(queue => [...queue, { id: current.id, rating: value, meta: "" }])
            setCurrent(readQueue[0]);
            setProgress(p => { props.setProgress([p[0] + 1, p[1]]); return [p[0] + 1, p[1]]; })
            setReadQueue(queue => [...queue.slice(1, queue.length)])
            setStepState(state => {
                return {
                    ...state
                    , [props.thisStep.id]: {
                        readQueue: readQueue.slice(1, readQueue.length),
                        current: readQueue[0],
                        writeQueue: [...writeQueue, { id: current.id, rating: value, meta: "" }]
                    }
                }
            })
        }
        setSubmit(false);
    }, [submit])

    useEffect(() => {
        if (undo && current != undefined && writeQueue.length > 0) {
            setCurrent(writeQueue[writeQueue.length - 1])
            setWriteQueue(writeQueue => writeQueue.slice(0, -2))
            setReadQueue(readQueue => [current, ...readQueue])
        }
        setUndo(false);
    }, [undo])

    function handleSubmit(value: number) {
        setValue(value);
        setSubmit(true);
    }

    function handleUndo(event: SyntheticEvent) {
        setUndo(true);
    }
    return (
        <React.Fragment>
            {current != undefined ?
                <FourPaneView
                    leftPane={windowX > 900 ? <PreviewList
                        imageData={readQueue}
                        imageSrcMap={props.imageMap}
                        contentWidth={300}
                        height={windowY - (250 + appBarHeight + mainBarHeight)} /> : <React.Fragment />}
                    middlePane={<SingleImage
                        imageSrc={props.imageMap[current?.id]}
                        nextImage={readQueue.length > 0 ? props.imageMap[readQueue[0].id] : undefined}
                        height={windowY - (250 + appBarHeight + mainBarHeight)} />}
                    rightPane={windowX > 900 ? <PreviewList
                        reverse={true}
                        imageData={writeQueue}
                        imageSrcMap={props.imageMap}
                        contentWidth={300}
                        height={windowY - (250 + appBarHeight + mainBarHeight)} /> : <React.Fragment />}
                    bottomPane={<RatingBar
                        resolution={5}
                        setValue={handleSubmit}
                        handleUndo={handleUndo}
                        showValue="on" />} />
                : null}
        </React.Fragment>
    )
}

export interface QualityStepProps extends StepProps {
    resolution: number
}

export function CutoffStep(props: CutoffStepProps) {
    const [cutoff, setCutoff] = useState<number>(props.threshold);
    const [below, setBelow] = useState<Array<StepDatum>>([]);
    const [above, setAbove] = useState<Array<StepDatum>>([]);
    const [submit, setSubmit] = useState<boolean>(false);
    const [appBarHeight, setAppBarHeight] = useRecoilState(subBarState);
    const [mainBarHeight, setMainBarHeight] = useRecoilState(mainBarState);

    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }

    useEffect(() => props.setProgress([0, 0]), [])

    useEffect(() => {
        let dataSource = props.stepMap[props.thisStep.source].target;
        setBelow(dataSource.filter(datum => datum.rating < cutoff));
        setAbove(dataSource.filter(datum => datum.rating >= cutoff));
    }, [cutoff])

    useEffect(() => {
        if (submit) {
            props.setThisStep({ ...props.thisStep, target: above });
        }
        setSubmit(false);
    }, [submit])

    function handleSubmit() {
        setSubmit(true);
    }

    return (
        <FourPaneView
            leftPane={<PreviewList
                imageData={below}
                imageSrcMap={props.imageMap}
                contentWidth={windowX / 2 - 50}
                height={windowY - (250 + appBarHeight + mainBarHeight)} />}
            middlePane={<React.Fragment />}
            rightPane={<PreviewList
                reverse={true}
                imageData={above}
                imageSrcMap={props.imageMap}
                contentWidth={windowX / 2 - 50}
                height={windowY - (250 + appBarHeight + mainBarHeight)} />}
            bottomPane={<Stack>
                <TextField label="threshold" value={cutoff} onChange={(e) => setCutoff(+e.target.value)} />
                <Button variant="contained" onClick={() => handleSubmit()}>Confirm</Button>
            </Stack>} />
    )
}

export interface CutoffStepProps extends StepProps {
    percent: boolean
    threshold: number
}

export function RandomCompareStep(props: RandomCompareStepProps) {
    const [readQueue, setReadQueue] = useState<Array<[StepDatum, StepDatum]>>([]);
    const [writeQueue, setWriteQueue] = useState<Array<StepDatum>>([]);
    const [current, setCurrent] = useState<[StepDatum, StepDatum]>();
    const [submit, setSubmit] = useState<boolean>(false);
    const [undo, setUndo] = useState<boolean>(false);
    const [value, setValue] = useState<number>(0);
    const [progress, setProgress] = useState<[number, number]>([0, 0]);

    const [appBarHeight, setAppBarHeight] = useRecoilState(subBarState);
    const [mainBarHeight, setMainBarHeight] = useRecoilState(mainBarState);

    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }

    const [stepState, setStepState] = useRecoilState(stepStates);

    /**
     * 
     * @param cycles number of times an element will be compared (except in odd/odd cases)
     */
    useEffect(() => {
        if (stepState[props.thisStep.id] === undefined) {
            if (props.referenceStep) {
                let combined = [...props.stepMap[props.thisStep.source].target, ...props.stepMap[props.referenceStep.source].target];
                let current = props.stepMap[props.thisStep.source].target;
                combined.sort((a, b) => a.rating - b.rating)
                shuffleArray(combined);
                if (readQueue.length === 0 && writeQueue.length === 0) {
                    let pairs = generateReferencePairs(current, combined, props.cycles);
                    setProgress([0, pairs.length])
                    props.setProgress([0, pairs.length])
                    setReadQueue(pairs);
                }
            }
            else {
                let data = [...props.stepMap[props.thisStep.source].target];
                shuffleArray(data);
                if (readQueue.length === 0 && writeQueue.length === 0) {
                    let pairs = generatePairs(data, props.cycles);
                    setProgress([0, pairs.length])
                    props.setProgress([0, pairs.length])
                    setReadQueue(pairs);
                }
            }
            setCurrent(undefined);
        }
        else {
            setReadQueue(stepState[props.thisStep.id].readQueue as Array<[StepDatum, StepDatum]>);
            setCurrent(stepState[props.thisStep.id].current as [StepDatum, StepDatum]);
            setWriteQueue(stepState[props.thisStep.id].writeQueue);
        }
    }, [props.thisStep, props.stepMap, props.referenceStep])

    useEffect(() => {
        if (readQueue.length > 0 && current == undefined) {
            setCurrent(readQueue[0]);
            setReadQueue(queue => [...queue.slice(1, queue.length)])
        }
        else if (readQueue.length == 0 && writeQueue.length > 0 && current == undefined) {
            props.setThisStep({ ...props.thisStep, target: writeQueue })
        }
    }, [readQueue])

    useEffect(() => {
        if (submit && current != undefined) {
            let a = { id: current[0].id, rating: current[0].rating, meta: { pairedDatum: current[1], score: ((-value + 2) / 4) } };
            let b = { id: current[1].id, rating: current[1].rating, meta: { pairedDatum: current[1], score: ((value + 2) / 4) } };
            setWriteQueue(queue => [...queue, a, b])
            setCurrent(readQueue[0]);
            setProgress(p => { props.setProgress([p[0] + 1, p[1]]); return [p[0] + 1, p[1]]; })
            setReadQueue(queue => [...queue.slice(1, queue.length)])
            setStepState(state => {
                return {
                    ...state,
                    [props.thisStep.id]: {
                        readQueue: readQueue.slice(1, readQueue.length),
                        current: readQueue[0],
                        writeQueue: [...writeQueue, a, b]
                    }
                }
            })
        }
        setSubmit(false);
    }, [submit, value])

    useEffect(() => {
        if (undo && current != undefined && writeQueue.length > 1) {
            let a = writeQueue[writeQueue.length - 2];
            let b = writeQueue[writeQueue.length - 1];
            setWriteQueue(writeQueue => writeQueue.slice(0, -2))
            setReadQueue(readQueue => [current, ...readQueue])
            setCurrent([a, b])
        }
        setUndo(false);
    }, [undo])

    function handleSubmit(value: number) {
        setValue(value);
        setSubmit(true);
    }

    function handleUndo(event: SyntheticEvent) {
        setUndo(true);
    }

    function clickRateA(event: SyntheticEvent) {
        window.dispatchEvent(new KeyboardEvent("keydown", { "key": "ArrowLeft" }))
    }

    function clickRateB(event: SyntheticEvent) {
        window.dispatchEvent(new KeyboardEvent("keydown", { "key": "ArrowRight" }))
    }

    return (
        <FourPaneView
            leftPane={windowX > 800 ? <SingleImage
                title="Image A"
                imageSrc={current ? props.imageMap[current[0].id] : undefined}
                nextImage={readQueue.length > 0 ? props.imageMap[readQueue[0][0].id] : undefined}
                height={windowY - (270 + appBarHeight + mainBarHeight)}
                onClick={clickRateA}
            /> : <React.Fragment />}
            middlePane={windowX > 800 ? <React.Fragment /> :
                < Box sx={{ display: "flex", flexDirection: "column" }}>
                    <SingleImage
                        title="Image A"
                        imageSrc={current ? props.imageMap[current[0].id] : undefined}
                        nextImage={readQueue.length > 0 ? props.imageMap[readQueue[0][0].id] : undefined}
                        height={(windowY - (270 + appBarHeight + mainBarHeight))}
                        onClick={clickRateA}
                    />
                    <SingleImage
                        title="Image B"
                        imageSrc={current ? props.imageMap[current[1].id] : undefined}
                        nextImage={readQueue.length > 0 ? props.imageMap[readQueue[0][1].id] : undefined}
                        height={(windowY - (270 + appBarHeight + mainBarHeight))}
                        onClick={clickRateB}
                    /></Box>}

            rightPane={windowX > 800 ? <SingleImage
                title="Image B"
                imageSrc={current ? props.imageMap[current[1].id] : undefined}
                nextImage={readQueue.length > 0 ? props.imageMap[readQueue[0][1].id] : undefined}
                height={windowY - (270 + appBarHeight + mainBarHeight)}
                onClick={clickRateB}
            /> : <React.Fragment />
            }
            bottomPane={< RatingBar
                resolution={5}
                min={- 2}
                max={2}
                default={0}
                track={false}
                stepLabels={windowX > 800 ? [{ value: -2, label: "Strongly Prefer A (1)" },
                { value: -1, label: "Prefer A (2)" },
                { value: 0, label: "No Preference (3)" },
                { value: 1, label: "Prefer B (4)" },
                { value: 2, label: "Strongly Prefer B (5)" }] :
                    [{ value: -2, label: "++A" },
                    { value: -1, label: "A" },
                    { value: 0, label: "No Preference" },
                    { value: 1, label: "B" },
                    { value: 2, label: "++B" }]}
                setValue={handleSubmit}
                handleUndo={handleUndo} />} />
    )
}

export interface RandomCompareStepProps extends StepProps {
    cycles: number
}

export interface ComparisonMeta {
    pairedDatum: StepDatum,
    score: number
}

// export function GroupAdjacentCompareStep() {
// }

// interface GroupAdjacentCompareStepProps extends StepProps {
//     cycles: number
// }

export function AdjacentCompareStep(props: AdjacentCompareStepProps) {
    const [readQueue, setReadQueue] = useState<Array<[StepDatum, StepDatum]>>([]);
    const [writeQueue, setWriteQueue] = useState<Array<StepDatum>>([]);
    const [current, setCurrent] = useState<[StepDatum, StepDatum]>();
    const [submit, setSubmit] = useState<boolean>(false);
    const [undo, setUndo] = useState<boolean>(false);
    const [value, setValue] = useState<number>(0);
    const [progress, setProgress] = useState<[number, number]>([0, 0]);

    const [appBarHeight, setAppBarHeight] = useRecoilState(subBarState);
    const [mainBarHeight, setMainBarHeight] = useRecoilState(mainBarState);

    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }

    const [stepState, setStepState] = useRecoilState(stepStates);

    useEffect(() => {
        if (stepState[props.thisStep.id] == undefined) {
            if (props.referenceStep) {
                let combined = [...props.stepMap[props.thisStep.source].target, ...props.stepMap[props.referenceStep.source].target];
                let current = props.stepMap[props.thisStep.source].target;
                combined.sort((a, b) => a.rating - b.rating)
                if (readQueue.length === 0 && writeQueue.length === 0) {
                    let pairs = generateReferencePairs(current, combined, props.cycles);
                    setProgress([0, pairs.length])
                    props.setProgress([0, pairs.length])
                    setReadQueue(pairs);
                }
            }
            else {
                let data = [...props.stepMap[props.thisStep.source].target];
                data.sort((a, b) => a.rating - b.rating)
                if (readQueue.length === 0 && writeQueue.length === 0) {
                    let pairs = generatePairs(data, props.cycles);
                    setProgress([0, pairs.length])
                    props.setProgress([0, pairs.length])
                    setReadQueue(pairs);
                }
            }
            setCurrent(undefined);
        }
        else {
            setReadQueue(stepState[props.thisStep.id].readQueue as Array<[StepDatum, StepDatum]>);
            setCurrent(stepState[props.thisStep.id].current as [StepDatum, StepDatum]);
            setWriteQueue(stepState[props.thisStep.id].writeQueue);
        }
    }, [props, stepState])


    useEffect(() => {
        if (readQueue.length > 0 && current == undefined) {
            setCurrent(readQueue[0]);
            setReadQueue(queue => [...queue.slice(1, queue.length)])
        }
        else if (readQueue.length == 0 && writeQueue.length > 0 && current == undefined) {
            props.setThisStep({ ...props.thisStep, target: writeQueue })
        }
    }, [readQueue])

    useEffect(() => {
        if (submit && current != undefined) {
            let a = { id: current[0].id, rating: current[0].rating, meta: { pairedDatum: current[1], score: ((-value + 2)) / 4 } };
            let b = { id: current[1].id, rating: current[1].rating, meta: { pairedDatum: current[1], score: ((value + 2)) / 4 } };
            setWriteQueue(queue => [...queue, a, b])
            setCurrent(readQueue[0]);
            setProgress(p => { props.setProgress([p[0] + 1, p[1]]); return [p[0] + 1, p[1]]; })
            setReadQueue(queue => [...queue.slice(1, queue.length)])
            setStepState(state => {
                return {
                    ...state,
                    [props.thisStep.id]: {
                        readQueue: readQueue.slice(1, readQueue.length),
                        current: readQueue[0],
                        writeQueue: [...writeQueue, a, b]
                    }
                }
            })
        }
        setSubmit(false);
    }, [submit, value])

    useEffect(() => {
        if (undo && current != undefined && writeQueue.length > 1) {
            let a = writeQueue[writeQueue.length - 2];
            let b = writeQueue[writeQueue.length - 1];
            setWriteQueue(writeQueue => writeQueue.slice(0, -2))
            setReadQueue(readQueue => [current, ...readQueue])
            setCurrent([a, b])
        }
        setUndo(false);
    }, [undo])

    function handleSubmit(value: number) {
        setValue(value);
        setSubmit(true);
    }

    function handleUndo(event: SyntheticEvent) {
        setUndo(true);
    }

    function clickRateA(event: SyntheticEvent) {
        window.dispatchEvent(new KeyboardEvent("keydown", { "key": "ArrowLeft" }))
    }

    function clickRateB(event: SyntheticEvent) {
        window.dispatchEvent(new KeyboardEvent("keydown", { "key": "ArrowRight" }))
    }

    return (
        <FourPaneView
            leftPane={windowX > 800 ? <SingleImage
                title="Image A"
                imageSrc={current ? props.imageMap[current[0].id] : undefined}
                nextImage={readQueue.length > 0 ? props.imageMap[readQueue[0][0].id] : undefined}
                height={windowY - (270 + appBarHeight + mainBarHeight)}
                onClick={clickRateA}
            /> : <React.Fragment />}
            middlePane={windowX > 800 ? <React.Fragment /> :
                < Box sx={{ display: "flex", flexDirection: "column" }}>
                    <SingleImage
                        title="Image A"
                        imageSrc={current ? props.imageMap[current[0].id] : undefined}
                        nextImage={readQueue.length > 0 ? props.imageMap[readQueue[0][0].id] : undefined}
                        height={(windowY - (270 + appBarHeight + mainBarHeight))}
                        onClick={clickRateA}
                    />
                    <SingleImage
                        title="Image B"
                        imageSrc={current ? props.imageMap[current[1].id] : undefined}
                        nextImage={readQueue.length > 0 ? props.imageMap[readQueue[0][1].id] : undefined}
                        height={(windowY - (270 + appBarHeight + mainBarHeight))}
                        onClick={clickRateB}
                    /></Box>}
            rightPane={windowX > 800 ? <SingleImage
                title="Image B"
                imageSrc={current ? props.imageMap[current[1].id] : undefined}
                nextImage={readQueue.length > 0 ? props.imageMap[readQueue[0][1].id] : undefined}
                height={windowY - (270 + appBarHeight + mainBarHeight)}
                onClick={clickRateB}
            /> : <React.Fragment />
            }
            bottomPane={< RatingBar
                resolution={5}
                min={- 2}
                max={2}
                default={0}
                track={false}
                stepLabels={windowX > 800 ? [{ value: -2, label: "Strongly Prefer A (1)" },
                { value: -1, label: "Prefer A (2)" },
                { value: 0, label: "No Preference (3)" },
                { value: 1, label: "Prefer B (4)" },
                { value: 2, label: "Strongly Prefer B (5)" }] :
                    [{ value: -2, label: "++A" },
                    { value: -1, label: "A" },
                    { value: 0, label: "No Preference" },
                    { value: 1, label: "B" },
                    { value: 2, label: "++B" }]}
                setValue={handleSubmit}
                handleUndo={handleUndo} />} />
    )
}

export interface AdjacentCompareStepProps extends StepProps {
    cycles: number
}

export function EloEvaluatorStep(props: EloEvaluatorStepProps) {
    const [images, setImages] = useState<Array<StepDatum>>([]);
    const [sortedImages, setSortedImages] = useState<Array<StepDatum>>([]);
    const [leaderboard, setLeaderboard] = useState<{ [index: string]: number }>({});
    const [submit, setSubmit] = useState<boolean>(false);

    const [appBarHeight, setAppBarHeight] = useRecoilState(subBarState);
    const [mainBarHeight, setMainBarHeight] = useRecoilState(mainBarState);

    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }

    useEffect(() => {
        setImages(props.stepMap[props.thisStep.source].target.map(datum => datum))
        props.setProgress([0, 0])
    }, [props.thisStep.source])

    useEffect(() => {
        setLeaderboard(images.reduce((dict, element) => { dict[element.id] = 1400; return dict; }, {} as { [index: string]: number }))
    }, [images])

    useEffect(() => {
        if (Object.keys(leaderboard).length > 0 && images.length != sortedImages.length) {
            for (let image of images) {
                let result = calculateElo(image, leaderboard, props.elo_S, props.elo_K)
                leaderboard[image.id] = result[0]
                leaderboard[(image.meta as ComparisonMeta).pairedDatum.id] = result[1];
            }
            setSortedImages(Object.values(images.reduce((dict, datum) => {
                dict[datum.id] = { ...datum, rating: leaderboard[datum.id] };
                return dict;
            }, {} as { [index: string]: StepDatum })).sort((a, b) => a.rating - b.rating).reverse());
        }
    }, [leaderboard])

    useEffect(() => {
        if (submit) {
            props.setThisStep({ ...props.thisStep, target: sortedImages })
        }
        setSubmit(false);
    }, [submit])

    function handleSubmit() {
        setSubmit(true);
    }
    return (
        <FourPaneView
            leftPane={<React.Fragment />}
            middlePane={<PreviewList
                imageData={sortedImages}
                imageSrcMap={props.imageMap}
                contentWidth={windowX - 50}
                height={windowY - (250 + appBarHeight + mainBarHeight)} />}
            rightPane={< React.Fragment />}
            bottomPane={<Button variant="contained" onClick={() => handleSubmit()}>Confirm</Button>} />
    )
}

export interface EloEvaluatorStepProps extends StepProps {
    elo_K: number
    elo_S: number
}

function calculateElo(item: StepDatum, leaderboard: { [index: string]: number }, elo_S: number, elo_K: number) {
    let expectation1 = 1 / (1 + Math.pow(10, (leaderboard[(item.meta as ComparisonMeta).pairedDatum.id] - leaderboard[item.id]) / elo_S));
    let expectation2 = 1 / (1 + Math.pow(10, (leaderboard[item.id] - leaderboard[(item.meta as ComparisonMeta).pairedDatum.id]) / elo_S));
    let rating1 = leaderboard[item.id] + elo_K * ((item.meta as ComparisonMeta).score - expectation1);
    let rating2 = leaderboard[(item.meta as ComparisonMeta).pairedDatum.id] + elo_K * ((1 - (item.meta as ComparisonMeta).score) - expectation2);
    return [rating1, rating2];
}


function generatePairs(data: Array<StepDatum>, cycles: number): Array<[StepDatum, StepDatum]> {
    let length = data.length;
    let k = Math.min(cycles, length)

    let remainder = k % 2

    let newPairs: Array<[StepDatum, StepDatum]> = [];

    for (let i = 0; i < length; i++) {
        for (let j = 1; j < (k - remainder) / 2 + 1; j++) {
            newPairs.push([data[i], data[realModulo(i + j, length)]])
        }
        // if we have an odd/odd situation we will have one additional pairing for one element
        if (remainder == 1 && i < ((length - 1) / 2 + length % 2)) {
            newPairs.push([data[i], data[realModulo(i + (length - length % 2) / 2, length)]])
        }
    }
    shuffleArray(newPairs);
    return newPairs;
}

function generateReferencePairs(current: Array<StepDatum>, combined: Array<StepDatum>, cycles: number,): Array<[StepDatum, StepDatum]> {
    let currentIds = new Set(current.map(datum => datum.id));
    let data = combined;
    let length = data.length;
    let k = Math.min(cycles, length)

    let remainder = k % 2

    let newPairs: Array<[StepDatum, StepDatum]> = [];
    for (let i = 0; i < length; i++) {
        for (let j = 1; j < (k - remainder) / 2 + 1; j++) {
            newPairs.push([data[i], data[realModulo(i + j, length)]])
        }
        // if we have an odd/odd situation we will have one additional pairing for one element
        if (remainder == 1 && i < ((length - 1) / 2 + length % 2)) {
            newPairs.push([data[i], data[realModulo(i + (length - length % 2) / 2, length)]])
        }
    }
    shuffleArray(newPairs);
    return newPairs.filter(pair => currentIds.has(pair[0].id) || currentIds.has(pair[1].id));
}

/**
 * Why do different languages implement % differently 
 * @param x number to be modulo'd
 * @param y modulus
 * @returns not sure
 */
export function realModulo(x: number, y: number): number {
    return x > 0 ? x % y : y + (x % y)
}

export function shuffleArray(arr: Array<any>) {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
    }
}