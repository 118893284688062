import RUNTIME_CONFIG from "../config/Configuration";
import { ImageSrc, Tag } from "../datamodel/DataModel";

export async function getUserTags(userId: string, signal?: AbortSignal): Promise<Array<Tag>> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/tags/user/" + userId,
    {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      signal: signal,
    }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      }
      else {
        throw Error(response.statusText);
      }
    });
}

export async function updateImagesTags(imageIds: Array<string>, tags: Array<string>, signal?: AbortSignal): Promise<ImageSrc> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/tags/images/",
    {
      method: "POST",
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      signal: signal,
      body: JSON.stringify({ imageIds: imageIds, tags: tags })
    }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      }
      else {
        throw Error(response.statusText);
      }
    });
}

export async function updateImageRankingTags(imageRankingId: string, tags: Array<string>, signal?: AbortSignal): Promise<ImageSrc> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/tags/imageranking/",
    {
      method: "POST",
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      signal: signal,
      body: JSON.stringify({ imageRankingId: imageRankingId, tags: tags })
    }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      }
      else {
        throw Error(response.statusText);
      }
    });
}


export async function deleteImagesTags(imageIds: Array<string>, tags: Array<string>, signal?: AbortSignal): Promise<ImageSrc> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/tags/images/",
    {
      method: "DELETE",
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      signal: signal,
      body: JSON.stringify({ imageIds: imageIds, tags: tags })
    }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      }
      else {
        throw Error(response.statusText);
      }
    });
}

export async function deleteImageRankingTags(imageRankingId: string, tags: Array<string>, signal?: AbortSignal): Promise<ImageSrc> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/tags/imageranking/",
    {
      method: "DELETE",
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      signal: signal,
      body: JSON.stringify({ imageRankingId: imageRankingId, tags: tags })
    }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      }
      else {
        throw Error(response.statusText);
      }
    });
}