import { AppBar, Box, Button, Divider, Grid, Toolbar, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from 'react';
import { useRecoilState } from "recoil";
import { createImageRanking } from "../api/ImageRankingApi";
import { ImageRanking, User } from "../datamodel/DataModel";
import { Offset, subBarState } from "../Picelo";
import ComparisonOrchestratorPage from './ComparisonOrchestratorPage';
import NewImageComparisonOrchestratorPage from "./NewImageRankingOrchestrator";
import { RankingExplorer } from "./RankingExplorerView";

export default function ComparisonLanding(props: ComparisonLandingProps) {
    const theme = useTheme();

    const [page, setPage] = useState("explorer");
    const [back, setBack] = useState(false)
    const [title, setTitle] = useState("Rankings")
    const [selectedAction, setSelectedAction] = useState<{ imageRanking: (ImageRanking | undefined), mode: ("new" | "resume" | "append") }>
        ({ imageRanking: undefined, mode: "new" });
    const [saveAvailable, setSaveAvailable] = useState<boolean>(false);
    const [save, setSave] = useState<boolean>(false);


    const [appBarHeight, setAppBarHeight] = useRecoilState(subBarState);

    const appBarRef = (node: HTMLDivElement) => {
        if (node != null) {
            setAppBarHeight(node.clientHeight)
        }
    }

    function saveState() {

    }

    return (
        <Box sx={{ display: "block", minHeight: "100vh", p: 2, justifyContent: "center" }}>
            <Box sx={{ display: 'flex' }}>
                <AppBar
                    position="fixed"
                    color="default"
                    sx={{
                        mt: 7,
                        zIndex: 2100,
                        boxShadow: 0
                    }}>
                    <Toolbar ref={appBarRef} >
                        {page !== "explorer" ? <Button variant="outlined" onClick={() => setBack(true)}>Back</Button> : null}
                        {saveAvailable ? <Button variant="outlined" onClick={() => setSave(true)}>Save</Button> : null}
                        <Divider sx={{ flexGrow: 1, visibility: "hidden" }} />
                        <Typography variant="h6" color={theme.palette.text.primary}>{title}</Typography>
                        <Divider sx={{ flexGrow: 1, visibility: "hidden" }} />
                    </Toolbar>
                </AppBar>
            </Box>
            <Offset />
            {page === "explorer" ? <RankingExplorer user={props.user} setPage={setPage} setTitle={setTitle} /> : null}
            {page === "new" ? <NewImageComparisonOrchestratorPage
                user={props.user}
                back={back}
                selectedAction={selectedAction}
                setBack={setBack}
                setPage={setPage}
                setTitle={setTitle}
                setSaveAvailable={setSaveAvailable}
                save={save}
                setSave={setSave} /> : null}
        </Box>
    );

}

interface ComparisonLandingProps {
    user: User
}

