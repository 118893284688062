import { UndoOutlined } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Slider, Stack, Tooltip } from "@mui/material";
import React, { Dispatch, KeyboardEvent, MouseEvent, Ref, SetStateAction, SyntheticEvent, useEffect, useMemo, useState } from "react";

export default function RatingBar(props: RatingBarProps) {
    const [sliderValue, setSliderValue] = useState<number>(0);
    const [submit, setSubmit] = useState<boolean>(false);
    useEffect(() => {
        setSliderValue(props.default ? props.default : 0)
        window.addEventListener("keydown", keyPressEventHandler as unknown as EventListener, true);
        return (() => window.removeEventListener("keydown", keyPressEventHandler as unknown as EventListener, true)
        );

    }, []);

    useEffect(() => {
        if (submit) {
            props.setValue(sliderValue);
        }
        setSubmit(false);
    }, [submit])

    function handleSubmit(event: SyntheticEvent) {
        setSubmit(true);
    }

    function keyPressEventHandler(event: KeyboardEvent) {
        if (props.resolution < 10 && !(Number.isNaN(parseFloat(event.key)))) {
            setSliderValue(+event.key + (props.min ? props.min - 1 : 0));
        }
        else if (!(Number.isNaN(parseFloat(event.key)))) {
            if ((sliderValue * 10 + (+event.key)) < props.resolution) {
                setSliderValue(value => value * 10 + (+event.key));
            }
            else {
                setSliderValue(+event.key);
            }
        }
        else if (event.key === "ArrowLeft") {
            setSliderValue(value => value > (props.min ? props.min : 1) ? value - 1 : value);
        }
        else if (event.key === "ArrowRight") {
            setSliderValue(value => value < (props.max ? props.max : props.resolution) ? value + 1 : value);
        }
        else if (event.key === "Enter") {
            handleSubmit(event);
        }
        else if (event.key === "Backspace") {
            props.handleUndo(event);
        }
    }

    const renderedInput = useMemo(() => {
        // if (props.resolution > 10) {
        return (
            <Stack ref={props.ref} spacing={2} direction="column">
                <Box sx={{ width: "80vw" }}>
                    <Slider
                        defaultValue={props.default}
                        track={props.track}
                        value={sliderValue}
                        valueLabelDisplay={props.showValue}
                        onChange={(e, v) => setSliderValue(v as number)}
                        min={props.min ? props.min : 1}
                        max={props.max ? props.max : props.resolution}
                        marks={props.stepLabels ? props.stepLabels : [{ value: 1, label: "1" }, { value: props.resolution, label: props.resolution.toString() }]} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Tooltip title="Undo (Backspace)"><IconButton onClick={props.handleUndo}><UndoOutlined /></IconButton></Tooltip>
                    <Tooltip title="Submit (Enter)"><Button variant="contained" onClick={handleSubmit}>Submit</Button></Tooltip>
                    <Divider orientation="vertical" />
                </Box>
            </Stack>
        );
        // }
        // else {
        //     return (
        //         <React.Fragment>
        //             {[...Array(props.resolution).fill(0)].map((_, index) => <Button onClick={() => setSliderValue(index)}>{index}</Button>)}
        //             <Button onClick={handleSubmit}>Submit</Button>
        //         </React.Fragment>
        //     );
        // }
    }, [props.resolution, sliderValue]);

    return (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center" }}>
            {renderedInput}
        </Box>
    );
}
interface RatingBarProps {
    ref?: Ref<HTMLDivElement>
    resolution: number
    min?: number
    max?: number
    default?: number
    showValue?: "on" | "off" | "auto"
    track?: "normal" | "inverted" | false
    stepLabels?: Array<{ value: number, label: string }>
    setValue: (value: number) => void
    handleUndo: (event: SyntheticEvent) => void
}
