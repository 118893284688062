import React, { createContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { User } from './datamodel/DataModel';
import LoginPage from './user/LoginPage';
import Gallery from './gallery/GalleryPage';
import { AppBar, Button, Toolbar, Typography, Box, Tabs, styled, Tab, Theme, Tooltip, IconButton, Divider, ButtonGroup, List, Menu, MenuItem, ListItem, ListItemText } from '@mui/material';
import { Routes, Route, useNavigate, Link } from 'react-router-dom';
import { ThemeProvider } from '@mui/system';
import { darkTheme, lightTheme } from './Theme';
import { getUser } from './api/ApiCalls';
import ComparisonLanding from './comparison/ComparisonLandingPage';
import { atom, useRecoilState } from 'recoil';
import { AccountCircle, AccountCircleOutlined, ArrowDropDown, DarkMode, LightMode } from '@mui/icons-material';
import CreateUserPage from './user/CreateUserPage';
import AboutPage from './noauth/About';
import AnonymousWorkflow from './noauth/AnonymousWorkflow';
import RUNTIME_CONFIG from './config/Configuration';

export const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export const mainBarState = atom({
  key: "mainBarState",
  default: 0,
})
export const subBarState = atom({
  key: "subBarState",
  default: 0,
})
export const loggedInTabState = atom({
  key: "loggedInTabState",
  default: "gallery",
})
export const anonymousTabState = atom({
  key: "anonymousTabState",
  default: "about" as string | false,
})

const tabToName = { "about": "About", "gallery": "Gallery", "compare": "Compare", "demo": "Demo" } as { [index: string]: string }

export default function Picelo() {
  const [user, setUser] = useState<User>();
  const [reloadUser, setReloadUser] = useState<boolean>(false);
  const [theme, setTheme] = useState<string>();
  const controller = new AbortController();
  const signal = controller.signal;

  const [windowY, setWindowY] = useState<number>(window.innerHeight);
  const [windowX, setWindowX] = useState<number>(window.innerWidth);

  window.addEventListener("resize", handleResize);

  function handleResize() {
    setWindowY(window.innerHeight);
    setWindowX(window.innerWidth);
  }

  const [mainBarHeight, setMainBarHeight] = useRecoilState(mainBarState);
  const [loggedInTab, setLoggedInTab] = useRecoilState(loggedInTabState);
  const [anonymousTab, setAnonymousTab] = useRecoilState(anonymousTabState);

  const [tabAnchorEl, setTabAnchorEl] = React.useState<null | HTMLElement>(null);
  const [authAnchorEl, setAuthAnchorEl] = React.useState<null | HTMLElement>(null);
  const tabMenuOpen = Boolean(tabAnchorEl);
  const authMenuOpen = Boolean(authAnchorEl);

  function handleClickTabListItem(event: React.MouseEvent<HTMLElement>) {
    if (tabAnchorEl === event.currentTarget) {
      setTabAnchorEl(null);
    }
    else {
      setTabAnchorEl(event.currentTarget);
    }
  };

  function handleTabMenuClose(event: React.MouseEvent<HTMLElement>) {
    setTabAnchorEl(null);
  };

  function handleClickAuthListItem(event: React.MouseEvent<HTMLElement>) {
    if (authAnchorEl === event.currentTarget) {
      setAuthAnchorEl(null);
    }
    else {
      setAuthAnchorEl(event.currentTarget);
    }
  };

  function handleAuthMenuClose(event: React.MouseEvent<HTMLElement>) {
    setAuthAnchorEl(null);
  };

  const appBarRef = (node: HTMLDivElement) => {
    if (node != null) {
      setMainBarHeight(node.clientHeight)
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    let localUser = window.localStorage.getItem("user");
    let localTheme = window.localStorage.getItem("theme");

    if (localUser != null && localUser != undefined) {
      let parsedLocalUser = JSON.parse(localUser) as User;
      getUser(parsedLocalUser.username, signal)
        .then(response => setUser(response))
        .catch(error => {
          window.localStorage.clear();
          navigate("about")
        })
    }
    if (localTheme != null && localTheme != undefined) {
      let parsedTheme = localTheme;
      setTheme(parsedTheme);
    }
    else {
      setTheme("light");
    }
  }, [])

  useEffect(() => {
    if (reloadUser && user != undefined) {
      getUser(user.username, signal).then(user => setUser(user));
      setReloadUser(false);
    }
  }, [reloadUser])

  useEffect(() => {
    if (user != undefined) {
      window.localStorage.setItem("user", JSON.stringify(user));
      let localTab = window.localStorage.getItem("loggedInTab");
      if (localTab != null) {
        setLoggedInTab(JSON.parse(localTab));
        navigate(JSON.parse(localTab));
      }
      else {
        setLoggedInTab("gallery")
        navigate("gallery")
      }
    }
    else {
      let localTab = window.localStorage.getItem("anonymousTab");
      if (localTab != null) {
        setAnonymousTab(JSON.parse(localTab));
        navigate(JSON.parse(localTab));
      }
      else {
        setAnonymousTab("about")
        navigate("about")
      }
    }
  }, [user])

  function handleLoginUser(user: User) {
    setUser(user);
    setReloadUser(true);
  }

  function handleLogout() {
    window.localStorage.clear();
    window.localStorage.setItem("theme", theme ? theme : "light");
    setUser(undefined);
    navigate("login")
  }

  function handleLogin() {
    setAnonymousTab(false);
    navigate("login")
    setAuthAnchorEl(null);
  }

  function handleNewUser() {
    setAnonymousTab(false);
    navigate("create_user")
    setAuthAnchorEl(null);
  }

  function handleLoggedInTabChange(event: React.SyntheticEvent, newValue: string) {
    window.localStorage.setItem("loggedInTab", JSON.stringify(newValue));
    setLoggedInTab(newValue);
    navigate(newValue);
    setTabAnchorEl(null);
  };
  function handleAnonymousTabChange(event: React.SyntheticEvent, newValue: string) {
    window.localStorage.setItem("anonymousTab", JSON.stringify(newValue));
    setAnonymousTab(newValue);
    navigate(newValue);
    setTabAnchorEl(null);
  };

  function handleTheme(event: React.SyntheticEvent) {
    window.localStorage.setItem("theme", theme === "light" ? "dark" : "light")
    setTheme(t => t === "light" ? "dark" : "light")
  }

  const largeScreenTabs = useMemo(() =>
    user != null && user != undefined ?
      <Tabs
        value={loggedInTab}
        onChange={handleLoggedInTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        sx={{ flexGrow: 1 }}>
        <Tab label="Gallery" value="gallery" />
        <Tab label="Compare" value="compare" />
        <Tab label="About" value="about" />
      </Tabs> :
      <Tabs
        value={anonymousTab}
        onChange={handleAnonymousTabChange}
        indicatorColor="secondary"
        textColor="inherit"
        sx={{ flexGrow: 1 }}>
        <Tab label="About" value="about" />
        <Tab label="Demo" value="demo" />
      </Tabs>
    , [user, loggedInTab, anonymousTab])



  const smallLoggedInTabs = useMemo(() =>
    <Box>
      <List>
        <ListItem
          button
          aria-expanded={tabMenuOpen ? 'true' : undefined}
          onClick={handleClickTabListItem}>
          <ListItemText
            primary={<Box sx={{ display: "inline-flex" }}>{loggedInTab ? tabToName[loggedInTab] : "Go to"} <ArrowDropDown /></Box>}
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={tabAnchorEl}
        open={tabMenuOpen}
        onClose={handleTabMenuClose}>
        <MenuItem
          key={"gallery"}
          selected={loggedInTab === "gallery"}
          onClick={(event) => handleLoggedInTabChange(event, "gallery")}>
          Gallery
        </MenuItem>
        <MenuItem
          key={"compare"}
          selected={loggedInTab === "compare"}
          onClick={(event) => handleLoggedInTabChange(event, "compare")} >
          Compare
        </MenuItem>
        <MenuItem
          key={"about"}
          selected={loggedInTab === "about"}
          onClick={(event) => handleLoggedInTabChange(event, "about")} >
          About
        </MenuItem>
      </Menu>
    </Box>,
    [loggedInTab, tabAnchorEl, tabMenuOpen])

  const smallAnonymousTabs = useMemo(() =>
    <Box>
      <List>
        <ListItem
          button
          aria-expanded={tabMenuOpen ? 'true' : undefined}
          onClick={handleClickTabListItem}>
          <ListItemText
            primary={<Box sx={{ display: "inline-flex" }}>{anonymousTab ? tabToName[anonymousTab] : "Go to"} <ArrowDropDown /></Box>}
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={tabAnchorEl}
        open={tabMenuOpen}
        onClose={handleTabMenuClose}>
        <MenuItem
          key={"about"}
          selected={anonymousTab === "about"}
          onClick={(event) => handleAnonymousTabChange(event, "about")} >
          About
        </MenuItem>
        <MenuItem
          key={"demo"}
          selected={anonymousTab === "demo"}
          onClick={(event) => handleAnonymousTabChange(event, "demo")} >
          Demo
        </MenuItem>
      </Menu>
    </Box>
    , [anonymousTab, tabAnchorEl, tabMenuOpen])

  const smallScreenTabs = useMemo(() =>
    user != null && user != undefined ? smallLoggedInTabs : smallAnonymousTabs
    ,
    [user, smallLoggedInTabs, smallAnonymousTabs])


  const largeScreenAuthOptions = useMemo(() =>
    <Box sx={{
      display: "inline-flex"
    }}>
      < Button
        color="inherit"
        onClick={handleLogin} >
        Login
      </Button>
      <Button
        color="inherit"
        onClick={handleNewUser}>
        Sign Up
      </Button>
    </Box>, [])

  const smallScreenAuthOptions = useMemo(() =>
    <Box>
      <List>
        <ListItem
          button
          aria-expanded={authMenuOpen ? 'true' : undefined}
          onClick={handleClickAuthListItem}>
          <ListItemText
            primary={<AccountCircleOutlined />}
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={authAnchorEl}
        open={authMenuOpen}
        onClose={handleAuthMenuClose}>
        <MenuItem
          key={"login"}
          onClick={handleLogin} >
          Login
        </MenuItem>
        <MenuItem
          key={"sign up"}
          onClick={handleNewUser} >
          Sign Up
        </MenuItem>
      </Menu>
    </Box>, [authMenuOpen, authAnchorEl])

  const authOptions = useMemo(() =>
    RUNTIME_CONFIG.allow_login ?
      user != undefined ? <Button color="inherit" onClick={handleLogout}>Logout</Button>
        : windowX > 800 ? largeScreenAuthOptions : smallScreenAuthOptions
      : null
    , [user, windowX, largeScreenAuthOptions, smallScreenAuthOptions])

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <Box sx={{ display: 'flex', justifyContent: "center", overflow: "visible", bgcolor: "background.default", minHeight: "100vh" }}>
        <AppBar ref={appBarRef} position="fixed" sx={{ display: "flex", zIndex: 2100 }}>
          <Toolbar sx={{ display: "flex" }}>
            <Typography
              variant="h4"
              component="div"
              sx={{ mr: 2 }}>
              picelo
            </Typography>
            {windowX > 800 ? largeScreenTabs : smallScreenTabs}
            <Divider sx={{ visibility: "hidden", flexGrow: 1 }} />
            <Tooltip title={theme === "light" ? "Switch to Dark theme" : "Switch to Light theme"} >
              <IconButton onClick={handleTheme}>{theme === "light" ? <DarkMode color="secondary" />
                : <LightMode color="secondary" />}
              </IconButton>
            </Tooltip>
            {authOptions}
          </Toolbar>
        </AppBar >
        <Box sx={{ display: "block", zIndex: 0, bgcolor: "background.default", justifyContent: "center" }}>
          <Routes>
            <Route path="about" element={<AboutPage />} />
            <Route path="demo" element={<AnonymousWorkflow />} />
            <Route path="create_user" element={<CreateUserPage />} />
            <Route path="login" element={<LoginPage submitted={handleLoginUser} />} />
            {user != undefined ?
              <Route path="gallery/*" element={<Gallery user={user} setReloadUser={setReloadUser} />} /> : null}
            {user != undefined ?
              <Route path="compare/*" element={<ComparisonLanding user={user} />} /> : null}
          </Routes>
        </Box>
      </Box >
    </ThemeProvider >
  )
}



