import { Grid } from "@mui/material";

export default function FourPaneView(props: FourPaneViewProps) {

    return (
        <Grid container direction="column" sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            maxWidth: "100vw",
            willChange: "transform",
        }}>
            <Grid container item spacing={1} sx={{
                display: "flex",
                alignContent: "center",
                flexWrap: "nowrap",
                justifyContent: "center",
                maxWidth: "100%",
                willChange: "transform",
            }}>
                <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    {props.leftPane}
                </Grid>
                <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    {props.middlePane}
                </Grid>
                <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                    {props.rightPane}
                </Grid>
            </Grid>
            <Grid item sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                {props.bottomPane}
            </Grid>
        </Grid>
    )
}

interface FourPaneViewProps {
    leftPane: JSX.Element
    middlePane: JSX.Element
    rightPane: JSX.Element
    bottomPane: JSX.Element
}