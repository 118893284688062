import { Grid, Stepper, Step, StepLabel, Divider } from "@mui/material";
import { useMemo } from "react";
import SelectPage from "./Select";
import { QualityStep, QualityStepProps, CutoffStep, CutoffStepProps, RandomCompareStep, RandomCompareStepProps, AdjacentCompareStep, AdjacentCompareStepProps, EloEvaluatorStep, EloEvaluatorStepProps } from "./steps/StepViews";

/**
 * Given components and a stepping function, display the correlated component
 */
export default function WorkflowStepper({ step, componentMap, stepNameMap }: WorkflowStepperProps) {

    const renderedNames = useMemo(() =>
        <Stepper activeStep={step}>
            {Object.keys(stepNameMap).sort().map(index =>
                <Step key={index} completed={step > +index} index={+index}>
                    <StepLabel> {stepNameMap[+index]} </StepLabel>
                </Step>
            )}
        </Stepper>
        ,
        [step, stepNameMap]
    )

    const renderedStep = useMemo(() =>
        componentMap[step],
        [step, componentMap]
    )

    return (
        <Grid container justifyContent="center" alignItems="center" columns={1} >
            <Grid container item>
                <Grid item xs>
                </Grid>
                <Grid item xs>
                    {renderedNames}
                </Grid>
                <Grid item xs>
                </Grid>
            </Grid>
            <Divider sx={{ m: 2 }} />
            {renderedStep}
        </Grid>
    );
}

export interface WorkflowStepperProps {
    stepNameMap: { [index: number]: string }
    componentMap: { [index: number]: JSX.Element }
    step: number
}