import { jsx } from "@emotion/react";
import { Divider, Grid, Step, StepLabel, Stepper } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { getFolderImages } from "../api/ImageApi";
import { createImageRanking, createRankingSteps, updateImageRankingStep, uploadRankingState } from "../api/ImageRankingApi";
import { Folder, ImageRanking, ImageSrc, RankingStep, User } from "../datamodel/DataModel";
import { mainBarState, subBarState } from "../Picelo";
import SelectPage from "./Select";
import { AdjacentCompareStep, AdjacentCompareStepProps, CurrentStep as StepState, CutoffStep, CutoffStepProps, EloEvaluatorStep, EloEvaluatorStepProps, QualityStep, QualityStepProps, RandomCompareStep, RandomCompareStepProps, StepProps } from "./steps/StepViews";
import WorkflowStepper from "./WorkflowStepper";

export const stepStates = atom<{ [index: string]: StepState }>({
    key: "stepStates",
    default: {}
})

export interface ComparisonState {
    stepStates: { [index: string]: StepState }
    step: number;
    imageRanking: ImageRanking;
    rankingSteps: Array<RankingStep>;
    stepStack: Array<StepProps>;
    referenceSteps: Array<RankingStep>;
    referenceImageRanking: ImageRanking;
}

export default function ComparisonOrchestratorPage(props: ComparisonOrchestratorProps) {
    const [step, setStep] = useState<number>(0);
    const [advance, setAdvance] = useState<boolean>(false);
    const [stepStackSet, setStepStackSet] = useState<boolean>(false);

    const mainBarHeight = useRecoilValue(mainBarState);
    const subBarHeight = useRecoilValue(subBarState);

    useEffect(() => {
        if (!stepStackSet && props.stepStack.length !== 0) {
            props.setStepStack(props.stepStack.map((step, index) => { return { ...step, setThisStep: (t: RankingStep) => setThisStep(index, t) } }))
            setStepStackSet(true);
        }
    }, [props.stepStack])

    useEffect(() => {
        if (props.advance === true) {
            setAdvance(true);
        }
    }, [props.advance])

    useEffect(() => {
        if (advance) {
            // Stepstack doesn't include the first step
            if ((props.selectComponent && step == props.stepStack.length && props.stepStack.length > 0)
                || (props.selectComponent === undefined && step === props.stepStack.length - 1 && props.stepStack.length > 0)) {
                props.setDone(true);
            }
            else {
                setStep(s => s + 1);
            }
        }
        props.setAdvance?.(false);
        setAdvance(false);
    }, [advance])

    function setThisStep(index: number, step: RankingStep) {
        props.setStepStack(stack => { stack[index].thisStep = step; stack[index].stepMap[step.id] = step; return [...stack]; });
        setAdvance(true);
    }

    const stepNames = useMemo(() =>
        (props.selectTitle ? [props.selectTitle, ...props.stepStack.map(step => step.name)]
            : props.stepStack.map(step => step.name))
            .reduce((dict, e, index) => {
                dict[index] = e; return dict;
            }, {} as { [index: number]: string })
        , [props.stepStack]
    )

    const renderedSteps = useMemo(() =>
        (props.selectComponent ? [props.selectComponent, ...props.stepStack.map((value, index) => selectStep(value))]
            : props.stepStack.map((value, index) => selectStep(value)))
            .filter((value): value is JSX.Element => value != undefined)
            .reduce((dict, e, index) => { dict[index] = e; return dict; }, {} as { [index: number]: JSX.Element })
        , [props.stepStack]
    )

    return (
        <Grid container justifyContent="center" alignItems="center" columns={1} sx={{ mt: (mainBarHeight + 4 + "px") }}>
            <WorkflowStepper stepNameMap={stepNames} componentMap={renderedSteps} step={step} />
        </Grid>
    );
}

interface ComparisonOrchestratorProps {
    selectTitle?: string
    selectComponent?: JSX.Element
    setDone: Dispatch<SetStateAction<boolean>>
    stepStack: Array<StepProps>
    setStepStack: Dispatch<SetStateAction<Array<StepProps>>>
    advance?: boolean
    setAdvance?: Dispatch<SetStateAction<boolean>>
}

function selectStep(props: StepProps) {
    switch (props.name) {
        case "qualityRating":
            return <QualityStep {...props as QualityStepProps} />;
        case "cutoffFilter":
            return <CutoffStep {...props as CutoffStepProps} />;
        case "randomComparison":
            return <RandomCompareStep {...props as RandomCompareStepProps} />;
        case "adjacentComparison":
            return <AdjacentCompareStep {...props as AdjacentCompareStepProps} />;
        case "eloEvaluator":
            return <EloEvaluatorStep {...props as EloEvaluatorStepProps} />;
    }
}