import { Grid } from "@mui/material";
import { useState, useEffect, useMemo, Dispatch, SetStateAction } from "react";
import { useRecoilState } from "recoil";
import { getFolderImages } from "../api/ImageApi";
import { uploadRankingState, createImageRanking, createRankingSteps, updateImageRankingStep } from "../api/ImageRankingApi";
import { RankingStep, Folder, ImageRanking, ImageSrc, User } from "../datamodel/DataModel";
import ComparisonOrchestratorPage, { ComparisonState, stepStates } from "./ComparisonOrchestratorPage";
import SelectPage from "./Select";
import { StepProps } from "./steps/StepViews";
import WorkflowStepper from "./WorkflowStepper";

export default function NewImageComparisonOrchestratorPage(props: ComparisonOrchestratorProps) {
    const [step, setStep] = useState<number>(0);
    const [advance, setAdvance] = useState<boolean>(false);

    const [beforeStepStack, setBeforeStepStack] = useState<Array<StepProps>>([]);
    const [stepStack, setStepStack] = useState<Array<StepProps>>([]);
    const [selectedTags, setSelectedTags] = useState<Array<string>>();
    const [selectedFolders, setSelectedFolders] = useState<Array<Folder>>();
    const [beforeImageRanking, setBeforeImageRanking] = useState<ImageRanking>();
    const [imageRanking, setImageRanking] = useState<ImageRanking>();
    const [progress, setProgress] = useState<[number, number]>([0, 0]);

    const [done, setDone] = useState<boolean>(false);

    const [referenceSteps, setReferenceSteps] = useState<Array<RankingStep>>();
    const [referenceImageRanking, setReferenceImageRanking] = useState<ImageRanking>();

    const [stepState, setStepState] = useRecoilState(stepStates);

    useEffect(() => {
        if (progress[1] === 0) {
            props.setTitle("")
        }
        else {
            props.setTitle("Step Progress: " + progress[0] + "/" + progress[1])
        }
    }, [progress])

    useEffect(() => {
        if (props.back === true) {
            if (step === 0) {
                setImageRanking(undefined);
                setBeforeImageRanking(undefined);
                setStepStack([]);
                setSelectedFolders([]);
                setSelectedTags([]);
                setStepState({});
                props.setPage("explorer")
            }
            else {
                setStep(s => s - 1)
            }
            props.setBack(false)
        }
    }, [props.back])

    useEffect(() => {
     if(advance) {
        setStep(s => s + 1)
        setAdvance(false);
     }
    }, [advance])


    useEffect(() => {
        async function newRanking() {
            if (imageRanking == undefined
                && beforeImageRanking != undefined
                && selectedTags != undefined
                && beforeStepStack != undefined
                && selectedFolders != undefined) {
                let imageRanking = await createImageRanking(beforeImageRanking.name, beforeImageRanking.userId,
                    beforeImageRanking.imageIds, selectedTags);

                let newRankingSteps: Array<RankingStep> = [];

                let dataSource = imageRanking.imageIds.map(imageId => { return { id: imageId, rating: 0, meta: {} } })
                newRankingSteps.push({
                    id: "-1",
                    userId: props.user.id,
                    imageRankingId: imageRanking.id,
                    name: "data",
                    source: "",
                    target: dataSource,
                    meta: {}
                })
                for (let i = 0; i < beforeStepStack.length; i++) {
                    let current = beforeStepStack[i];
                    newRankingSteps.push({
                        id: "-1",
                        userId: props.user.id,
                        imageRankingId: imageRanking.id,
                        name: current.name,
                        source: newRankingSteps[i].id,
                        target: [],
                        meta: {}
                    })
                }
                let rankingSteps = await createRankingSteps(newRankingSteps);
                let images: Array<ImageSrc> = [];
                for (let folder of selectedFolders) {
                    images = images.concat(await getFolderImages(folder.id))
                }
                let stepMap = rankingSteps.reduce((dict, element) => { dict[element.id] = element; return dict; }, {} as { [index: string]: RankingStep });
                let imageMap = images.reduce((dict, element) => { dict[element.id] = element; return dict; }, {} as { [index: string]: ImageSrc });
                let stepStack = beforeStepStack.map((step, index) => {
                    return {
                        ...beforeStepStack[index],
                        thisStep: rankingSteps[index + 1],
                        setThisStep: (t: RankingStep) => void (0),
                        setProgress: setProgress,
                        referenceStep: referenceSteps?.[index],
                        stepMap: stepMap,
                        imageMap: imageMap,
                    } as StepProps
                })
                setImageRanking(imageRanking);
                setStepStack(stepStack);
            }
        }
        if (advance && imageRanking === undefined && Object.keys(stepState).length === 0) {
            newRanking();
        }
    }, [advance, beforeImageRanking, selectedTags, beforeStepStack, selectedFolders, stepState])

    useEffect(() => {
        async function update() {
            if (imageRanking != undefined) {
                for (let step of stepStack) {
                    await updateImageRankingStep(imageRanking.id, step.thisStep);
                }
            }
        }
        if (done) {
            update();
            setImageRanking(undefined);
            setBeforeImageRanking(undefined);
            setStepStack([]);
            setSelectedFolders([]);
            setSelectedTags([])
            setStepState({});
            props.setPage("explorer")
        }
    }, [done]);

    return (
        <ComparisonOrchestratorPage 
        selectTitle="Select images"       
            selectComponent={< SelectPage
                user={props.user}
                setAdvance={setAdvance}
                setStepStack={setBeforeStepStack}
                setImageRanking={setBeforeImageRanking}
                setSelectedTags={setSelectedTags}
                setSelectedFolders={setSelectedFolders} />
            }
            setDone={setDone}
            stepStack={stepStack}
            setStepStack={setStepStack}
            advance={advance}
            setAdvance={setAdvance} />
    )
}

interface ComparisonOrchestratorProps {
    user: User
    back: boolean
    save: boolean
    selectedAction: { imageRanking: (ImageRanking | undefined), mode: ("new" | "resume" | "append") }
    setBack: Dispatch<SetStateAction<boolean>>
    setSave: Dispatch<SetStateAction<boolean>>
    setPage: Dispatch<SetStateAction<string>>
    setTitle: Dispatch<SetStateAction<string>>
    setSaveAvailable: Dispatch<SetStateAction<boolean>>
}