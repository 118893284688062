import { Container, TextField, Button, useTheme, Typography, Alert, Divider } from '@mui/material';
import { ChangeEvent, useState, useMemo } from 'react';
import { Grid } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import { useEffect } from 'react';
import { checkEmail as checkEmailValid, checkUsername as checkUsernameValid, createUser } from '../api/ApiCalls';
import { useNavigate } from 'react-router-dom';

export default function CreateUserPage(props: CreateUserProps) {

    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const emailController = new AbortController();
    const emailSignal = emailController.signal;
    const usernameController = new AbortController();
    const usernameSignal = usernameController.signal;

    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
    }

    const [email, setEmail] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordRepeat, setPasswordRepeat] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [displayName, setDisplayName] = useState<string>("");
    const [passwordType, setPasswordType] = useState<string>("password")
    const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean>(false);
    const [isEmailAvailable, setIsEmailAvailable] = useState<boolean>(false);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [messages, setMessages] = useState<string[]>([""]);
    const [submit, setSubmit] = useState<boolean>(false);

    const theme = useTheme();

    const navigate = useNavigate();

    useEffect(() => {
        if (email != "" && email.match(emailRegex)) {
            setIsEmailValid(true);
            handleMessage("email format invalid", true)
            let timer1 = setTimeout(() => {
                checkEmailValid(email.trim(), emailSignal).then(response => {
                    if (response) {
                        handleMessage("email taken", true)
                        setIsEmailAvailable(true);
                    }
                    else {
                        handleMessage("email taken", false)
                        setIsEmailAvailable(false);
                    }
                })
            }, 1000);
            return () => {
                clearTimeout(timer1)
            }
        }
        else if (email != "") {
            setIsEmailValid(false);
            handleMessage("email format invalid", false)
        }

    }, [email])

    useEffect(() => {
        let timer1 = setTimeout(() => {
            if (username != "") {
                checkUsernameValid(username.trim(), usernameSignal).then(response => {
                    if (response) {
                        handleMessage("username taken", true)
                        setIsUsernameAvailable(true);
                    }
                    else {
                        handleMessage("username taken", false)
                        setIsUsernameAvailable(false);
                    }
                })
            }
        }, 1000);
        return () => {
            clearTimeout(timer1)
        }
    }, [username])

    useEffect(() => {
        if (password != "" && passwordRepeat !== "" && password != passwordRepeat) {
            handleMessage("passwords must match", false)
        }
        else {
            handleMessage("passwords must match", true)
        }
    }, [password, passwordRepeat])


    useEffect(() => {
        if (submit) {
            if (email == "") {
                handleMessage("email blank", false)
            }
            else {
                handleMessage("email blank", true)
            }
            if (email == "") {
                handleMessage("username blank", false)
            }
            else {
                handleMessage("username blank", true)
            }
            if (email != "" && isEmailValid && isEmailAvailable
                && password === passwordRepeat
                && username != "" && isUsernameAvailable) {

                let newUserDetails = {
                    username: username.toLocaleLowerCase(),
                    password: password,
                    email: email.toLocaleLowerCase(),
                    firstName: firstName,
                    lastName: lastName,
                    displayName: displayName
                }
                createUser(newUserDetails).then(user => {
                    if (user != null && user != undefined) {
                        handleCancel();
                    }
                })
            }
        }
        setSubmit(false);
    })

    function handleMessage(message: string, clear: boolean) {
        if (!clear) {
            setMessages(messages => {
                messages[messages.length - 1] != message ?
                    messages.push(message) : void (0);
                return [...messages];
            })
        }
        else {
            setMessages(messages => {
                messages[messages.length - 1] == message ? messages.pop() : void (0);
                return [...messages];
            })
        }
    }

    function handleShowPassword() {
        "password" === passwordType ? setPasswordType("text") : setPasswordType("password")
    }

    function handleEmail(event: ChangeEvent<HTMLTextAreaElement>) {
        setEmail(event.target.value);
    }

    function handleUsername(event: ChangeEvent<HTMLTextAreaElement>) {
        setUsername(event.target.value);
    }

    function handlePassword(event: ChangeEvent<HTMLTextAreaElement>) {
        setPassword(event.target.value);
    }

    function handlePasswordRepeat(event: ChangeEvent<HTMLTextAreaElement>) {
        setPasswordRepeat(event.target.value);
    }

    function handleFirstName(event: ChangeEvent<HTMLTextAreaElement>) {
        setFirstName(event.target.value);
    }

    function handleLastName(event: ChangeEvent<HTMLTextAreaElement>) {
        setLastName(event.target.value);
    }

    function handleDisplayName(event: ChangeEvent<HTMLTextAreaElement>) {
        setDisplayName(event.target.value);
    }

    function handleCreateAccount() {
        setSubmit(true);
    }

    function handleCancel() {
        setEmail("");
        setUsername("");
        setPassword("");
        setPasswordRepeat("");
        setFirstName("");
        setLastName("");
        setDisplayName("");
        setPasswordType("password")
        setIsEmailAvailable(true);
        setIsUsernameAvailable(true);
        navigate(-1);
    }

    const messageRender = useMemo(() => {
        if (messages[messages.length - 1] != "") {
            return <Grid item><Alert severity="error" >{messages[messages.length - 1]}</Alert></Grid>
        }
        else {
            return undefined;
        }
    }, [messages])

    return (
        <Container maxWidth="xs" sx={{ mt: 10, height: "auto" }}>
            <br />
            <Grid container direction={"column"} spacing={1}>
                <Grid item>
                    <TextField
                        required
                        fullWidth variant="outlined" label="Email" value={email}
                        onChange={handleEmail}
                    ></TextField>
                </Grid>
                <Grid item>
                    <TextField
                        required
                        fullWidth variant="outlined" label="Username" value={username}
                        onChange={handleUsername}
                    ></TextField>
                </Grid>
                <Grid item>
                    <TextField
                        required
                        fullWidth variant="outlined" label="Password" type={passwordType} value={password}
                        onChange={handlePassword}
                    ></TextField>
                </Grid>
                <Grid item>
                    <TextField
                        required
                        fullWidth variant="outlined" label="Repeat Password" type={passwordType} value={passwordRepeat}
                        onChange={handlePasswordRepeat}
                    ></TextField>
                </Grid>
                <Grid item >
                    <FormControlLabel sx={{ display: "flex", alignContent: "flex-start", mx: "auto" }}
                        control={
                            <Checkbox
                                checked={passwordType === "text"}
                                onChange={handleShowPassword} />
                        }
                        label={<Typography color={theme.palette.text.primary}>Show password</Typography>}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth variant="outlined" label="First name" value={firstName}
                        onChange={handleFirstName}
                    ></TextField>
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth variant="outlined" label="Last name" value={lastName}
                        onChange={handleLastName}
                    ></TextField>
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth variant="outlined" label="Display name" value={displayName}
                        onChange={handleDisplayName}
                    ></TextField>
                </Grid>
                {messageRender}
                <Grid item>
                    <Button
                        fullWidth variant="contained"
                        onClick={handleCreateAccount}>
                        Confirm
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth variant="outlined"
                        onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Divider sx={{ visibility: "hidden", m: 4 }} />
                </Grid>
            </Grid>
        </Container>
    )
}

export interface CreateUserProps {
}

export interface NewUserDetails {
    username: string,
    password: string,
    email: string,
    firstName: string,
    lastName: string,
    displayName: string
}