import { AddCircleOutline, Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Autocomplete, Box, Button, ButtonGroup, Divider, Grid, IconButton, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, useTheme } from "@mui/material";
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { getFoldersByUserId } from "../api/FolderApi";
import { getFolderImages } from "../api/ImageApi";
import { getUserTags } from "../api/TagApi";
import { Folder, ImageRanking, ImageSrc, User } from "../datamodel/DataModel";
import { AdjacentCompareStepProps as AdjacentComparisonStepProps, CutoffStepProps, EloEvaluatorStepProps, QualityStepProps, RandomCompareStepProps as RandomComparisonStepProps, StepProps } from "./steps/StepViews";

function UpDownButtons(props: UpDownButtonsProps) {
    return (
        <ButtonGroup size="small" sx={{}}>
            <IconButton onClick={() => props.handleRemove()}><Close fontSize="small" sx={{ mx: -1, fontSize: "1rem" }} /></IconButton>
            <ButtonGroup size="small" orientation="vertical">
                <IconButton onClick={() => props.handleUp()}><KeyboardArrowUp fontSize="small" sx={{ my: -1, fontSize: "1rem" }} /></IconButton>
                <IconButton onClick={() => props.handleDown()}><KeyboardArrowDown fontSize="small" sx={{ my: -1, fontSize: "1rem" }} /></IconButton>
            </ButtonGroup>
        </ButtonGroup>
    );
}
interface UpDownButtonsProps {
    handleUp: Function;
    handleDown: Function;
    handleRemove: Function;
}
/**
 * There is some highly questionable code in here that could and should be refactored to be
 * much more scalable but I'm leaving things like this since the minimum viable concept still
 * needs to be proven.
 * @param props
 * @returns
 */
export default function SelectPage(props: SelectionProps) {
    const theme = useTheme();
    const controller = new AbortController();
    const signal = controller.signal;

    const [images, setImages] = useState<Array<ImageSrc>>([]);
    const [selectedFolders, setSelectedFolders] = useState<Array<Folder>>([]);
    const [cachedImages, setCachedImages] = useState<{ [index: string]: Array<ImageSrc> }>({});
    const [folders, setFolders] = useState<{ [index: string]: Folder }>({});
    const [imageToTags, setImageToTags] = useState<{ [index: string]: Array<string> }>({});
    const [availableTags, setAvailableTags] = useState<Set<string>>(new Set());
    const [selectedIncludeTags, setSelectedIncludeTags] = useState<Array<string>>([]);
    const [selectedExcludeTags, setSelectedExcludeTags] = useState<Array<string>>([]);
    const [stepStack, setStepStack] = useState<Array<StepProps>>([]);
    const [title, setTitle] = useState<string>("");

    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }


    useEffect(() => {
        getUserTags(props.user.id, signal).then(tags => {
            setAvailableTags(new Set(tags.map(tag => tag.name)));
            setImageToTags(tags.reduce((acc, tag) => {
                for (let image of tag.imageIds) {
                    if (!acc[image]) {
                        acc[image] = [tag.name]
                    }
                    else {
                        acc[image] = [...acc[image], tag.name]
                    }
                }
                return acc;
            }, {} as { [index: string]: Array<string> })
            )
        })
        getFoldersByUserId(props.user.id, signal)
            .then(folders => {
                setFolders(folders);
            })
    }, [props.user])

    useEffect(() => {
        async function getImages() {
            let foldersToGet = selectedFolders.filter(folder => cachedImages[folder.id] === undefined);
            let newCachedImages = { ...cachedImages };
            for (let folder of foldersToGet) {
                let images = await getFolderImages(folder.id, signal)
                newCachedImages[folder.id] = images;
            }
            let tags = selectedFolders.flatMap(folder =>
                newCachedImages[folder.id] ? newCachedImages[folder.id].flatMap(image =>
                    imageToTags[image.id] ? imageToTags[image.id] : []) : [])
            setAvailableTags(new Set(tags))
            setCachedImages(newCachedImages);
        }
        getImages();
    }, [selectedFolders])

    useEffect(() => {
        if (Object.keys(cachedImages).length > 0) {
            let includedTagSet = new Set(selectedIncludeTags);
            let excludedTagSet = new Set(selectedExcludeTags);
            if (includedTagSet.size > 0) {
                setImages(selectedFolders.flatMap(folder =>
                    cachedImages[folder.id])
                    .filter(image => image != undefined)
                    .filter(image => imageToTags[image.id] ? imageToTags[image.id]
                        .some(e => includedTagSet.has(e)) && !imageToTags[image.id]
                            .some(e => excludedTagSet.has(e)) : false))
            }
            else {
                setImages(selectedFolders.flatMap(folder => cachedImages[folder.id]))
            }
        }
    }, [selectedIncludeTags, selectedExcludeTags, cachedImages])

    function handleSubmit() {
        if (images.length > 0 && stepStack.length > 0) {
            props.setImageRanking(
                {
                    id: "none",
                    userId: props.user.id,
                    name: title,
                    createDate: 0,
                    lastModified: 0,
                    imageIds: images.map(image => image.id),
                    stateUrl: "",
                });

            props.setStepStack(stepStack);
            props.setSelectedTags(selectedIncludeTags);
            props.setSelectedFolders(selectedFolders);
            props.setAdvance(true);
        }
    }

    return (
        <Grid container direction="column">
            <Grid container item direction={windowX > 900 ? "row" : "column"} columns={windowX > 900 ? 2 : 1} spacing={1} sx={{ display: "flex", justifyContent: "center", alignItems: "flex-center" }}>
                <Grid item xs>
                    <Grid container direction="column" justifyContent="flex-cente" alignContent="center" spacing={2}>
                        <Grid item>
                            <TextField fullWidth value={title} onChange={e => setTitle(e.target.value)} label="Ranker Title" /></Grid>
                        <Grid item><Divider sx={{ m: 1 }} /></Grid>
                        <Grid item><Typography variant="h6" color={theme.palette.text.primary}>Select image source</Typography></Grid>
                        <Grid item><FolderAutocomplete folderMap={folders} folders={Object.values(folders)} setValue={setSelectedFolders} /></Grid>
                        <Grid item><Typography variant="h6" color={theme.palette.text.primary}>Include tags</Typography></Grid>
                        <Grid item><TagAutocomplete tags={availableTags.size > 0 ? Array.from(availableTags) : []} setValue={setSelectedIncludeTags} /></Grid>
                        <Grid item><Typography variant="h6" color={theme.palette.text.primary}>Exclude tags</Typography></Grid>
                        <Grid item><TagAutocomplete tags={availableTags.size > 0 ? Array.from(availableTags) : []} setValue={setSelectedExcludeTags} /></Grid>
                        <Grid item><Typography color={theme.palette.text.primary}>{images.length} images to rank</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <StepSelectionView stepStack={stepStack} setStepStack={setStepStack} images={images} />
                </Grid>
            </Grid >
            <Grid item><Divider sx={{ m: 4 }} /></Grid>
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={() => handleSubmit()} variant="contained">Begin</Button>
            </Grid>
        </Grid>
    );
}

interface SelectionProps {
    user: User;
    setImageRanking: Dispatch<SetStateAction<ImageRanking | undefined>>
    setStepStack: Dispatch<SetStateAction<Array<StepProps>>>
    setSelectedTags: Dispatch<SetStateAction<Array<string> | undefined>>
    setSelectedFolders: Dispatch<SetStateAction<Array<Folder> | undefined>>
    setAdvance: Dispatch<SetStateAction<boolean>>
}

export function StepSelectionView({ stepStack, setStepStack, images }: StepSelectionViewProps) {

    const theme = useTheme();

    const [formValues, setFormValues] = useState({ "quality": "", "cutoff": "", "random": "", "adjacent": "", "elo_S": "", "elo_K": "" });
    const [switchValues, setSwitchValues] = useState({ "quality": false, "cutoff": false, "random": false, "adjacent": false });

    function handleRemove(start: number) {
        setStepStack(steps => [...steps.slice(0, start), ...steps.slice(start + 1, steps.length)]);
    }

    function handleUp(start: number) {
        if (start > 0 && start < stepStack.length - 1) {
            setStepStack(steps => [...steps.slice(0, start - 1), steps[start], steps[start - 1], ...steps.slice(start + 1, steps.length)]);
        }
        else if (start > 0) {
            setStepStack(steps => [...steps.slice(0, start - 1), steps[start], steps[start - 1]]);
        }
        else {
            setStepStack(steps => steps);
        }

    }
    function handleDown(start: number) {
        if (start > 0 && start < stepStack.length - 2) {
            setStepStack(steps => [...steps.slice(0, start), steps[start + 1], steps[start], ...steps.slice(start + 2, steps.length)]);
        }
        else if (start > 0 && start < stepStack.length - 1) {
            setStepStack(steps => [...steps.slice(0, start), steps[start + 1], steps[start]]);
        }
        else if (start < stepStack.length - 2) {
            setStepStack(steps => [steps[start + 1], steps[start], ...steps.slice(start + 2, steps.length)]);
        }
        else if (start < stepStack.length - 1) {
            setStepStack(steps => [steps[start + 1], steps[start]]);
        }
        else {
            setStepStack(steps => steps);
        }
    }

    function handleAddStep(id: string) {
        switch (id) {
            case "quality":
                setStepStack(stepStack => {
                    return [...stepStack,
                    { name: "qualityRating", resolution: +formValues[id] } as QualityStepProps]
                })
                break;
            case "cutoff":
                setStepStack(stepStack => {
                    return [...stepStack,
                    { name: "cutoffFilter", percent: switchValues[id], threshold: +formValues[id] } as CutoffStepProps]
                })
                break;
            case "random":
                setStepStack(stepStack => {
                    return [...stepStack,
                    { name: "randomComparison", cycles: +formValues[id] } as RandomComparisonStepProps]
                })
                break;
            case "adjacent":
                setStepStack(stepStack => {
                    return [...stepStack,
                    { name: "adjacentComparison", cycles: +formValues[id] } as AdjacentComparisonStepProps]
                })
                break;
            case "elo_S":
                setStepStack(stepStack => {
                    return [...stepStack,
                    { name: "eloEvaluator", elo_S: +formValues["elo_S"], elo_K: +formValues["elo_K"] } as EloEvaluatorStepProps]
                })
                break;
        }
    }

    function handleFormChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) {
        setFormValues(formValues => { return { ...formValues, [id]: event.target.value } })
    }

    function handleSwitchChange(event: ChangeEvent<HTMLInputElement>, id: string) {
        setSwitchValues(switchValues => { return { ...switchValues, [id]: event.target.checked } })
    }

    const renderedSteps = useMemo(() => stepStack.map((step, index) => {
        let s = (() => {
            switch (step.name) {
                case "qualityRating":
                    return <Typography color={theme.palette.text.primary}>Quality Rating Step at resolution {(step as QualityStepProps).resolution}</Typography>;
                case "cutoffFilter":
                    return <Typography color={theme.palette.text.primary}>Cutoff Filter Step at {(step as CutoffStepProps).threshold}{(step as CutoffStepProps).percent ? "%" : ""} threshold</Typography>;
                case "randomComparison":
                    return <Typography color={theme.palette.text.primary}>Random Comparison Step with {(step as RandomComparisonStepProps).cycles} cycles
                        ({images.length * (((step as RandomComparisonStepProps).cycles) - images.length % 2) / 2 + (images.length % 2) * ((images.length - 1) / 2 + 1)} max comparisons) </Typography>;
                case "adjacentComparison":
                    return <Typography color={theme.palette.text.primary}>Adjacent Rating Comparison Step with {(step as AdjacentComparisonStepProps).cycles} cycles
                        ({images.length * (((step as RandomComparisonStepProps).cycles) - images.length % 2) / 2 + (images.length % 2) * ((images.length - 1) / 2 + 1)} max comparisons)</Typography>;
                case "eloEvaluator":
                    return <Typography color={theme.palette.text.primary}>Evaluate Elo</Typography>;
            }
        })();
        return <TableRow key={index}><TableCell>
            <Stack direction="row">
                <UpDownButtons
                    handleDown={() => handleDown(index)}
                    handleRemove={() => handleRemove(index)}
                    handleUp={() => handleUp(index)} />
                {s}
            </Stack>
        </TableCell></TableRow>
    }), [stepStack, images])

    return (
        <Box>
            <Grid container direction="column" alignContent="center" justifyContent="flex-start" spacing={2}>
                <Grid item><Typography variant="h6" color={theme.palette.text.primary}>Select steps</Typography></Grid>
                <Grid item sx={{ maxWidth: "100%" }}>
                    <TableContainer sx={{ maxWidth: "100%" }}>
                        <Table size="small">
                            <TableBody>
                                {stepStack.length == 0 || (stepStack[stepStack.length - 1].name != "randomComparison"
                                    && stepStack[stepStack.length - 1].name != "adjacentComparison") ?
                                    <TableRow><TableCell>
                                        <ParameterizedSelection
                                            id1={"quality"}
                                            text={"Quality Rating Step"}
                                            handleAdd={handleAddStep}
                                            formLabel1="resolution"
                                            formValue1={formValues["quality"]}
                                            default1="5"
                                            form1OnChange={handleFormChange} />
                                    </TableCell></TableRow> : null}
                                {stepStack.length > 0
                                    && (stepStack[stepStack.length - 1].name != "randomComparison"
                                        && stepStack[stepStack.length - 1].name != "adjacentComparison") ?
                                    <TableRow><TableCell>
                                        <ParameterizedSelection
                                            id1={"cutoff"}
                                            text={"Cutoff Filter Step"}
                                            handleAdd={handleAddStep}
                                            formLabel1="threshold"
                                            formValue1={formValues["cutoff"]}
                                            form1OnChange={handleFormChange}
                                            switchChecked={switchValues["cutoff"]}
                                            switchLeftLabel="Raw"
                                            switchRightLabel="%"
                                            switchOnChange={handleSwitchChange} />
                                    </TableCell></TableRow> : null}
                                {stepStack.length == 0 || (stepStack[stepStack.length - 1].name != "randomComparison"
                                    && stepStack[stepStack.length - 1].name != "adjacentComparison") ? <TableRow><TableCell>
                                        <ParameterizedSelection
                                            id1={"random"}
                                            text={"Random Comparison Step"}
                                            handleAdd={handleAddStep}
                                            formLabel1="cycles"
                                            formValue1={formValues["random"]}
                                            default1="5"
                                            form1OnChange={handleFormChange} />
                                    </TableCell></TableRow> : null}
                                {stepStack.length == 0 || (stepStack[stepStack.length - 1].name != "randomComparison"
                                    && stepStack[stepStack.length - 1].name != "adjacentComparison") ?
                                    <TableRow><TableCell>
                                        <ParameterizedSelection
                                            id1={"adjacent"}
                                            text={"Adjacent Rating Comparison Step"}
                                            handleAdd={handleAddStep}
                                            formLabel1="cycles"
                                            formValue1={formValues["adjacent"]}
                                            default1="5"
                                            form1OnChange={handleFormChange} />
                                    </TableCell></TableRow> : null}
                                {stepStack.length > 0
                                    && (stepStack[stepStack.length - 1].name === "randomComparison"
                                        || stepStack[stepStack.length - 1].name === "adjacentComparison") ?
                                    <TableRow><TableCell>
                                        <ParameterizedSelection
                                            id1={"elo_S"}
                                            id2={"elo_K"}
                                            text={"Evaluate Elo (recommended)"}
                                            formLabel1="S"
                                            formValue1={formValues["elo_S"]}
                                            default1="400"
                                            formLabel2="K"
                                            formValue2={formValues["elo_K"]}
                                            default2="32"
                                            form1OnChange={handleFormChange}
                                            form2OnChange={handleFormChange}
                                            handleAdd={handleAddStep} />
                                    </TableCell></TableRow> : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <TableContainer sx={{ maxWidth: "100%" }}>
                        <Table size="small">
                            <TableBody>
                                {renderedSteps}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    )
}

export interface StepSelectionViewProps {
    stepStack: Array<StepProps>
    setStepStack: Dispatch<SetStateAction<Array<StepProps>>>
    images: Array<ImageSrc>
}

export function TagAutocomplete(props: TagAutocompleteProps) {
    const [tags, setTags] = useState<Array<string>>([]);
    const [autocompleteValue, setAutocompleteValue] = useState<Array<string>>([]);
    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }

    useEffect(() => setTags(props.tags), [props.tags]);
    useEffect(() => props.setValue(autocompleteValue), [autocompleteValue]);

    return (
        <Autocomplete
            multiple
            options={tags}
            value={autocompleteValue}
            onChange={(event, value, reason) => setAutocompleteValue(value)}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    placeholder="Select Tags"
                    {...params}
                    sx={{ width: windowX > 900 ? "30vw" : "90vw" }}
                />
            )}
        />
    )
}

interface TagAutocompleteProps {
    tags: Array<string>
    setValue: Dispatch<SetStateAction<Array<string>>>
}

export function FolderAutocomplete(props: FolderAutocompleteProps) {
    const [folders, setFolders] = useState<Array<Folder>>([]);
    const [autocompleteValue, setAutocompleteValue] = useState<Array<Folder>>([]);
    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }


    useEffect(() => setFolders(props.folders), [props.folders]);
    useEffect(() => props.setValue(autocompleteValue), [autocompleteValue]);

    return (
        <Autocomplete
            multiple
            options={folders}
            value={autocompleteValue}
            onChange={(event, value, reason) => setAutocompleteValue(value)}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => [...option.parentFolderIds.map(id => props.folderMap[id].name), option.name].join(" > ")
                === [...value.parentFolderIds.map(id => props.folderMap[id].name), value.name].join(" > ")}
            getOptionLabel={option => [...option.parentFolderIds.map(id => props.folderMap[id].name), option.name].join(" > ")}
            renderInput={(params) => (
                <TextField
                    placeholder="Select Folders"
                    {...params}
                    sx={{ width: windowX > 900 ? "30vw" : "90vw" }}
                />
            )}
        />
    )
}

interface FolderAutocompleteProps {
    folderMap: { [index: string]: Folder }
    folders: Array<Folder>
    setValue: Dispatch<SetStateAction<Array<Folder>>>
}

function ParameterizedSelection(props: ParameterizedSeletionProps) {

    const form1 = useMemo(() => {
        if (props.formLabel1 !== undefined && props.formValue1 !== undefined && props.form1OnChange !== undefined) {
            return <TextField
                label={props.formLabel1}
                value={props.formValue1}
                type="number"
                defaultValue={props.default1}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                onChange={(e) => props.form1OnChange?.(e, props.id1) ?? void (0)}
                size="small" sx={{ maxWidth: "5rem" }} />;
        }
        else {
            return null;
        }
    }, [props])

    const form2 = useMemo(() => {
        if (props.id2 !== undefined && props.formLabel2 !== undefined && props.formValue2 !== undefined && props.form1OnChange !== undefined) {
            return <TextField
                label={props.formLabel2}
                value={props.formValue2}
                type="number"
                defaultValue={props.default2}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                onChange={(e) => props.form2OnChange?.(e, props.id2 ? props.id2 : "") ?? void (0)}
                size="small" sx={{ maxWidth: "5rem" }} />;
        }
        else {
            return null;
        }
    }, [props])

    const optionSwitch = useMemo(() => {
        if (props.switchChecked !== undefined && props.switchLeftLabel !== undefined
            && props.switchRightLabel !== undefined && props.switchOnChange !== undefined) {
            return <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{props.switchLeftLabel}</Typography>
                <Switch checked={props.switchChecked} size="small" onChange={(e) => props.switchOnChange?.(e, props.id1) ?? void (0)} />
                <Typography>{props.switchRightLabel}</Typography>
            </Stack>
        }
        else {
            return null;
        }
    }, [props])

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <IconButton onClick={() => props.handleAdd(props.id1)}><AddCircleOutline /></IconButton>
            <Typography sx={{ flexGrow: 1 }}>{props.text}</ Typography>
            {form1}
            {form2}
            {optionSwitch}
        </Stack>
    )
}

interface ParameterizedSeletionProps {
    id1: string
    id2?: string
    text: string
    handleAdd: (id: string) => void
    formLabel1?: string
    formValue1?: string
    default1?: string
    formLabel2?: string
    formValue2?: string
    default2?: string
    form1OnChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => void
    form2OnChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: string) => void
    switchLeftLabel?: string
    switchRightLabel?: string
    switchChecked?: boolean
    switchOnChange?: (event: ChangeEvent<HTMLInputElement>, id: string) => void
}