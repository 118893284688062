import { Box, Typography, Divider, useTheme, Paper, Link, Container } from "@mui/material";
import { useRecoilValue } from "recoil";
import { mainBarState } from "../Picelo";
import { MathComponent } from 'mathjax-react'
import { sizeHeight } from "@mui/system";

export default function AboutPage() {
    const theme = useTheme();
    const mainBarHeight = useRecoilValue(mainBarState);

    const expectationFormulaA = "{\\small  Expected_a = \\frac {1}{(1 + 10^{(Rating_a - Rating_b)/S})}}";
    const expectationFormulaB = "{\\small Expected_a = \\frac {1}{(1 + 10^{(Rating_b - Rating_a)/S})}}";
    const newRatingA = "{\\small Rating_a' = Rating_a + K(Score_{ab} - Expected_a)}";
    const newRatingB = "{\\small Rating_b' = Rating_b + K(Score_{ba} - Expected_b)}";

    return (
        <Container sx={{ mt: mainBarHeight + 8 + "px" }}>
            <Typography variant="h4" color={theme.palette.text.primary}>What is Picelo?</Typography>
            <Divider sx={{ mt: 2, mb: 4 }} />
            <Typography variant="body1" color={theme.palette.text.primary}>
                Picelo is a photo organization service that features a systematic way to rate every photo in your portfolio.
                Users can assign 1-5 ratings, or use side-by-side comparisons to create an "Elo" rating for their photos. 1-5 ratings
                are good, but it lacks the resolution to order things within a rating label. When rating a lot of photos, the meaning of 1 and 5
                could shift through the process, leading to multiple cycles of filtering. Picelo aims to structure that process.
                <br />
                <br />
                <Link href="https://en.wikipedia.org/wiki/Elo_rating_system"> Elo </Link>is a rating system invented by Arpad Elo to
                calculate the relative skill levels of payers in games such as Chess. After a match, the winning player's rating increases
                and the loser's rating decreases according to their score and starting ratings. One thing to note is that the Elo
                score is only valid within the rating group - two different scores from different tournaments do not necessarily
                have any significant relationship.
                The formulas we use are below, where<MathComponent display={false} tex={"S"} />and<MathComponent display={false} tex={"K"} />
                default to 400 and 32:
            </Typography>
            <Paper sx={{ p: 1 }}>
                <MathComponent tex={expectationFormulaA} />
                <MathComponent tex={expectationFormulaB} />
                <MathComponent tex={newRatingA} />
                <MathComponent tex={newRatingB} />
            </Paper>
            <Typography variant="h5" color={theme.palette.text.primary}>How is that applied to photos?</Typography>
            <Divider sx={{ mt: 2, mb: 4 }} />
            <Typography variant="body1" color={theme.palette.text.primary}>
                With photos, we set up "matches" between photos to obtain pairwise comparison data. The more matches there are,
                the more accurate the rating should be. However, this does mean that we will be reviewing the photos many times.
                For a portfolio of 100 photos and 5 comparisons per photo, that's <MathComponent display={false} tex={"250"} /> comparisons to make!
                <br />
                <br />
                There are some ways to make this less tedious, either through reducing the number of photos or cycles.
                <br />
                <br />
                We can reduce the number of photos by first assigning a 1-5 (or 1-100 if you like) rating flat out and then using a cutoff filter
                to get rid of ones that are clearly not going to make the cut. We can then use two strategies to generate our comparison pairs:
                1. randomly, or 2. adjacently. Random pairs help determine a photo's placement among the whole portfolio, especially as the number
                pairs icnrease. Adjacent pairs help determine the photo's placement among peer ratings, which is useful for when you have to choose
                the best out of the best.
                <br />
                <br />
                There should be fewer adjacent pairs (compared to random) required to come up with an accurate rating, but that's to be determined
                through experimentation.
            </Typography>
            <Divider sx={{ mt: 2, mb: 4, visibility: "hidden" }} />
            <Typography variant="h5" color={theme.palette.text.primary}>What's in the demo?</Typography>
            <Divider sx={{ mt: 2, mb: 4 }} />
            <Typography variant="body1" color={theme.palette.text.primary}>
                The demo is a lightweight example of the comparison workflow. Everything is done in-browser, so take care to not
                use up all your memory by loading in your entire photo album!
            </Typography>
            <Divider sx={{ mt: 2, mb: 4, visibility: "hidden" }} />
            <Typography variant="h5" color={theme.palette.text.primary}>Contact</Typography>
            <Divider sx={{ mt: 2, mb: 4 }} />
            <Typography variant="body1" color={theme.palette.text.primary}>
                Questions? Concerns? Find me on Twitter: <Link href="https://twitter.com/WillIMaybeAm">@WillIMaybeAm</Link>
            </Typography>
            <Divider sx={{ mt: 2, mb: 4, visibility: "hidden" }} />


        </Container>
    )
}

