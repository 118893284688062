import { IconButton, ImageListItem, ImageListItemBar, Skeleton } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { ImageMeta } from "./ImageUploadDialog";


export function ImageUploadTile(props: ImageUploadTileProps) {

    const [dataURL, setDataURL] = useState<string>("");

    useEffect(() => {
        var maxWidth = 200;
        var image = new Image();
        image.onload = function () {
            var canvas: HTMLCanvasElement = document.getElementById("canvas") as HTMLCanvasElement;
            if (image.width > maxWidth) {
                image.height *= maxWidth / image.width;
                image.width = maxWidth;
            }
            var ctx = canvas.getContext("2d");
            if (ctx != null) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0, image.width, image.height);
                setDataURL(canvas.toDataURL());
            }
        };
        image.src = props.url;
    }, [props.url]);

    return (
        <React.Fragment>
            {dataURL === "" ?
                <Skeleton variant="rectangular" animation="wave" width={props.small ? 100 : 200} height={props.small ? 100 : 200} />
                :
                <ImageListItem sx={{
                    willChange: "transform", border: "2px solid white", boxShadow: 1,
                    height: props.small ? 100 : 200,
                    width: props.small ? 100 : 200,
                }}>
                    <ImageListItemBar
                        title={props.imageMeta[props.index]?.name ?? "image"}
                        position="top"
                        actionIcon={<IconButton
                            aria-label="close"
                            onClick={props.clickHandler}
                            sx={{
                                color: "white",
                            }}>
                            {props.clickButton}
                        </IconButton>}
                        sx={{ willChange: "transform", height: "2rem" }} />
                    <img
                        crossOrigin="anonymous"
                        decoding="async"
                        src={dataURL}
                        onError={(e: SyntheticEvent<HTMLImageElement, Event>) => e.currentTarget.style.display = 'none'}
                        loading="lazy"
                        onLoad={props.onLoadHandler}
                        style={{
                            height: props.small ? 100 : 200,
                            width: props.small ? 100 : 200,
                            objectFit: "cover",
                            objectPosition: "center center"
                        }} />

                </ImageListItem>}
        </React.Fragment>);
}
export interface ImageUploadTileProps {
    loading: boolean;
    index: string;
    imageMeta: { [index: string]: ImageMeta; };
    url: string;
    small?: boolean
    clickButton: JSX.Element;
    clickHandler: (event: SyntheticEvent) => void;
    onLoadHandler: (event: any) => void;
}
