import RUNTIME_CONFIG from "../config/Configuration";
import { ImageRanking as ImageRanking, RankingStep } from "../datamodel/DataModel";

export async function createImageRanking(
    name: string,
    userId: string,
    imageIds: Array<string>,
    tags: Array<string>,
    signal?: AbortSignal): Promise<ImageRanking> {
    return fetch(RUNTIME_CONFIG.api_address + "/api/imageranking/",
        {
            method: "POST",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            signal: signal,
            body: JSON.stringify({ name: name, userId: userId, imageIds: imageIds, tags: tags })
        }).then(response => {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            }
            else {
                throw Error(response.statusText);
            }
        });
}

export async function createRankingSteps(steps: Array<RankingStep>, signal?: AbortSignal): Promise<Array<RankingStep>> {
    return fetch(RUNTIME_CONFIG.api_address + "/api/imageranking/step/",
        {
            method: "POST",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            signal: signal,
            body: JSON.stringify({ steps: steps })
        }).then(response => {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            }
            else {
                throw Error(response.statusText);
            }
        });
}

export async function uploadRankingState(ranking: ImageRanking, state: string, signal?: AbortSignal): Promise<String> {
    return fetch(RUNTIME_CONFIG.api_address + "/api/imageranking/state/",
        {
            method: "POST",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            signal: signal,
            body: JSON.stringify({ imageRanking: ranking, state: state })
        }).then(response => {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            }
            else {
                throw Error(response.statusText);
            }
        });
}

export async function getUserImageRankings(userId: string, signal?: AbortSignal): Promise<Array<ImageRanking>> {
    return fetch(RUNTIME_CONFIG.api_address + "/api/imageranking/user/" + userId,
        {
            method: "GET",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            signal: signal
        }).then(response => {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            }
            else {
                throw Error(response.statusText);
            }
        });
}

export async function getRankingSteps(imageRankingId: string, signal?: AbortSignal): Promise<Array<RankingStep>> {
    return fetch(RUNTIME_CONFIG.api_address + "/api/imageranking/step/" + imageRankingId,
        {
            method: "GET",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            signal: signal
        }).then(response => {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            }
            else {
                throw Error(response.statusText);
            }
        });
}


export async function updateImageRankingStep(imageRankingId: string, rankingStep: RankingStep, signal?: AbortSignal): Promise<ImageRanking> {
    return fetch(RUNTIME_CONFIG.api_address + "/api/imageranking/step/" + imageRankingId,
        {
            method: "PUT",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            signal: signal,
            body: JSON.stringify(rankingStep)
        }).then(response => {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            }
            else {
                throw Error(response.statusText);
            }
        });
}

export async function updateImageRankingImages(imageRankingId: String, images: Array<string>, signal?: AbortSignal): Promise<ImageRanking> {
    return fetch(RUNTIME_CONFIG.api_address + "/api/imageranking/images/" + imageRankingId,
        {
            method: "PUT",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            signal: signal,
            body: JSON.stringify({ images: images })
        }).then(response => {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            }
            else {
                throw Error(response.statusText);
            }
        });
}

export async function deleteImageRanking(ranking: ImageRanking, signal?: AbortSignal): Promise<ImageRanking> {
    return fetch(RUNTIME_CONFIG.api_address + "/api/imageranking/",
        {
            method: "DELETE",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            signal: signal,
            body: JSON.stringify(ranking)
        }).then(response => {
            if (response.status >= 200 && response.status <= 299) {
                return response.json();
            }
            else {
                throw Error(response.statusText);
            }
        });
}