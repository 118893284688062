import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";
import { Folder } from "../datamodel/DataModel";
import { FolderTree } from "../components/FolioDrawer";

export default function ImageMoveDialog(props: ImageMoveDialogProps) {
    const [value, setValue] = useState<string>("");
    return (
        <Dialog open={props.open}>
            <DialogTitle>New Folder</DialogTitle>
            <DialogContent>
                <FolderTree
                    folders={props.folders}
                    rootFolder={props.rootFolder}
                    handleFolderClick={folder => setValue(folder.id)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleCancel}>Cancel</Button>
                <Button onClick={(e) => props.handleSubmit(e, value)} variant="contained">Move</Button>
            </DialogActions>
        </Dialog >
    );
}

interface ImageMoveDialogProps {
    open: boolean
    rootFolder: Folder
    folders: { [index: string]: Folder }
    handleSubmit: (event: SyntheticEvent, folder: string) => any
    handleCancel: (event: SyntheticEvent) => any
}