import { Box, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import { ImageSrc, StepDatum } from "../datamodel/DataModel";
import { buildUrl } from "../util/buildUrl";
import { ImageDialog } from "./ImageDialog";

export function PreviewList(props: PreviewListProps) {
    const [highlight, setHighlight] = useState<string>();
    const [imageWidth, setImageWidth] = useState<number | string>(0);
    const [updated, setUpdated] = useState<boolean>(false);
    const [fullImageDialog, setFullImageDialog] = useState<JSX.Element>();

    const imageRef = (node: HTMLImageElement) => {
        if (node != null && !updated) {
            setImageWidth(node.clientWidth);
        }
    };

    useEffect(() => {
        if (updated == false) {
            setUpdated(true);
        }
        if (highlight != undefined) {
            setFullImageDialog(<ImageDialog
                imageSrc={props.imageSrcMap[highlight]}
                imageRef={imageRef}
                imageWidth={imageWidth}
                highlight={highlight}
                setHighlight={setHighlight}
                setUpdated={setUpdated} />)
        }
        else {
            setFullImageDialog(undefined);
        }
    }, [highlight, imageWidth, updated])

    function handleSelect(event: MouseEvent, imageId: string) {
        setHighlight(imageId);
        setUpdated(false);
    }

    function handleMouseClickImage(event: MouseEvent) {
        let id = event.currentTarget.getAttribute("id");
        if (id != null) {
            handleSelect(event, id)
        }
    }

    const rendered = useMemo(() =>
        props.imageData.map(imageDatum => {
            let image = props.imageSrcMap[imageDatum.id];
            return <ListImageTile
                key={image.id}
                id={image.id}
                name={image.name}
                rating={imageDatum.rating}
                handleMouseClickImage={handleMouseClickImage}
                image={
                    <img
                        key={image.id}
                        crossOrigin="anonymous"
                        decoding="async"
                        src={buildUrl(image.size === "thumbnail" ? image.source : image.alternateSizes["thumbnail"])}
                        typeof={image.type}
                        alt={image?.name ?? "image"}
                        loading="eager"
                        width={150}
                        height={150}
                        style={{ width: 150, height: 150, objectFit: "cover", objectPosition: "center center" }}
                    />
                } />;
        }), [props.imageData])

    return (
        <Box sx={{ width: props.contentWidth, height: props.height }}>
            {props.title ? <Typography variant="h6">{props.title}</Typography> : null}
            <ImageList
                variant="standard"
                rowHeight={150}
                cols={(props.contentWidth / 150) | 0}
                sx={{
                    zIndex: 1,
                    width: "100%",
                    maxHeight: props.height
                }}>
                {props.reverse ? rendered.reverse() : rendered}
            </ImageList>
            {fullImageDialog}
        </Box>
    )
}

interface PreviewListProps {
    title?: string
    reverse?: true
    imageData: Array<StepDatum>
    imageSrcMap: { [index: string]: ImageSrc }
    contentWidth: number
    height: number
}

function ListImageTile(props: ListImageTileProps) {
    const [mouseOver, setMouseOver] = useState<boolean>();

    function getBorders(): string {
        if (mouseOver) {
            return "4px solid gray";
        }
        return "4px solid white";
    }

    function getBoxShadow(): number {
        if (mouseOver) {
            return 4;
        }
        return 1;
    }

    function handleMouseEnterImage(event: MouseEvent) {
        let id = event.currentTarget.getAttribute("id");
        if (id != null) {
            setMouseOver(true);
        }
    }
    function handleMouseEnterTitle(event: MouseEvent) {
        event.preventDefault()
    }
    function handleMouseLeaveImage(event: MouseEvent) {
        setMouseOver(false)
    }
    function handleMouseLeaveTitle(event: MouseEvent) {
        event.preventDefault()
    }

    return <ImageListItem
        onMouseEnter={handleMouseEnterImage}
        onMouseLeave={handleMouseLeaveImage}
        onClick={props.handleMouseClickImage}
        key={props.id}
        id={props.id}
        sx={{
            willChange: "transform",
            border: getBorders(),
            boxShadow: getBoxShadow(),
            maxheight: 200,
            maxWidth: 200,
            overflow: "hidden",
            objectFit: "contain"
        }}>
        <ImageListItemBar title={props.name} subtitle={"Rating: " + props.rating} />
        {props.image}
    </ImageListItem>

}

interface ListImageTileProps {
    handleMouseClickImage?: (event: MouseEvent) => void
    key: string
    id: string
    image: JSX.Element
    rating: number
    name: string
}
