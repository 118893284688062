import { useEffect, useState } from 'react';

export const DEFAULT_RUNTIME_CONFIG = {
    environment: "",
    api_address: "",
    allow_login: true,
}

const RUNTIME_CONFIG: RuntimeConfiguration = DEFAULT_RUNTIME_CONFIG;
export default RUNTIME_CONFIG;

export function ConfigLoader(props: ConfigLoaderProps) {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        fetchRuntimeConfig()
            .then(() => setIsLoaded(true));
    }, [])

    if (isLoaded) {
        return props.isReady(RUNTIME_CONFIG);
    }
    else {
        return null;
    }

}

async function fetchRuntimeConfig() {
    return fetch('./runtime-config.json', {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then(result => result.json())
        .then(json => {
            RUNTIME_CONFIG.environment = json["environment"];
            RUNTIME_CONFIG.api_address = json["api_address"];
            RUNTIME_CONFIG.allow_login = json["allow_login"];
            return json;
        })
}

export interface ConfigLoaderProps {
    isReady: Function
}

export interface RuntimeConfiguration {
    environment: string,
    api_address: string,
    allow_login: boolean
}