import { Circle, CircleOutlined } from "@mui/icons-material";
import { IconButton, ImageList, ImageListItem, ImageListItemBar, useMediaQuery } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { Dispatch, MouseEvent, SetStateAction, useEffect, useMemo, useState } from "react";
import { BaseIdentifier, ImageSrc } from "../datamodel/DataModel";
import { Box } from '@mui/system';
import { ImageDialog } from "../components/ImageDialog";
import { buildUrl } from "../util/buildUrl";

export default function ImageListView(props: ImageListViewProps) {
    const queueSize = 64;
    const theme: Theme = useTheme();
    const [queue, setQueue] = useState<Array<ImageSrc>>([]);

    const [images, setImages] = useState<Array<[string, string, JSX.Element]>>([]);
    const [thumbnails, setThumbnails] = useState<{ [index: string]: ListImageTileProps }>({});
    const [fullImageDialogue, setFullImageDialogue] = useState<JSX.Element>();
    const [highlight, setHighlight] = useState<string>();

    const [lastSelected, setLastSelected] = useState<string>();

    const [imageWidth, setImageWidth] = useState<number | string>(0);
    const [updated, setUpdated] = useState(false);

    const [loading, setLoading] = useState(0);

    const imageRef = (node: HTMLImageElement) => {
        if (node != null && !updated) {
            setImageWidth(node.clientWidth);
        }
    };


    function handleSelect(event: MouseEvent, imageId: string) {
        if (event.detail >= 2) {
            if (imageId === highlight) {
                setHighlight(undefined);
            }
            else {
                setHighlight(imageId);
                setUpdated(false);
            }
        }
        else {
            let ids: Array<string> = [];
            if (event.shiftKey) {
                let start = props.images.ordering.findIndex(element => element === lastSelected);
                let end = props.images.ordering.findIndex(element => element === imageId);
                if (start <= end) {
                    ids = props.images.ordering.slice(start + 1, end + 1).map(element => element);
                }
                else {
                    ids = props.images.ordering.slice(end, start).map(element => element);
                }
            }
            else {
                ids = [imageId];
            }
            ids.map(id =>
                props.selected.has(id) ?
                    props.setSelected(s => { let t = new Set(s); t.delete(id); return t }) :
                    props.setSelected(s => { let t = new Set(s); t.add(id); return t })
            )
        }
        setLastSelected(imageId);
    }

    useEffect(() => {
        // Some questionable rate limiting logic
        if (loading < queueSize && queue.length > 0) {
            for (let u = 0; u < queueSize - loading; u++) {
                let imageSrc = queue.pop();
                if (imageSrc != null && imageSrc != undefined) {
                    let newImageSrc = imageSrc;
                    setLoading(loading => loading + 1);
                    setImages(i => [...[...i].filter(([id, element]) => id != newImageSrc.id),
                    [newImageSrc.id, newImageSrc.name,
                    <img
                        key={newImageSrc.id}
                        crossOrigin="anonymous"
                        decoding="async"
                        src={buildUrl(newImageSrc.size === "thumbnail" ? newImageSrc.source : newImageSrc.alternateSizes["thumbnail"])}
                        typeof={newImageSrc.type}
                        alt={imageSrc?.name ?? "image"}
                        loading="eager"
                        onLoad={() => setLoading(loading => loading - 1)}
                        style={{
                            width: props.contentWidth > 800 ? 200 : 100,
                            height: props.contentWidth > 800 ? 200 : 100,
                            objectFit: "cover",
                            objectPosition: "center center"
                        }}
                    />]])
                }
            }
        }
    }, [loading, queue])


    useEffect(() => {
        setQueue(props.images.ordering.map(id =>
            props.images.images[id]
        ).reverse())
    }, [props.images])


    function handleMouseClickImage(event: MouseEvent) {
        let id = event.currentTarget.getAttribute("id");
        if (id != null) {
            handleSelect(event, id)
        }
    }

    useEffect(() => {
        setThumbnails(
            images.reduce((mapping, [id, name, image]) => {
                mapping[id] = {
                    handleMouseClickImage: handleMouseClickImage,
                    key: id,
                    id: id,
                    image: image,
                    selected: props.selected,
                    name: name,
                    rating: props.ratingMap != undefined && props.ratingMap[id] != undefined ? props.ratingMap[id].toString() : undefined
                };
                return mapping;
            }, {} as { [index: string]: ListImageTileProps }));
    }, [images, props.selected, props.ratingMap])

    useEffect(() => {
        if (updated == false) {
            setUpdated(true)
        }
        if (highlight != undefined) {
            setFullImageDialogue(
                <ImageDialog
                    imageSrc={props.images.images[highlight]}
                    imageRef={imageRef}
                    imageWidth={imageWidth}
                    highlight={highlight}
                    setHighlight={setHighlight}
                    setUpdated={setUpdated} />
            );
        }
        else {
            setFullImageDialogue(undefined);
        }
    }, [highlight, imageWidth, updated])

    const rendered = useMemo(() => props.images.ordering.filter(id =>
        thumbnails[id] != undefined).map(id => <ListImageTile {...thumbnails[id]} />), [thumbnails])

    return (
        <Box sx={{ width: "100%" }}>
            <ImageList
                variant="standard"
                rowHeight={props.contentWidth > 800 ? 200 : 100}
                cols={Math.floor(props.contentWidth / (props.contentWidth > 800 ? 200 : 100))}
                sx={{
                    zIndex: 1,
                    width: "100%",
                }}>
                {rendered}
            </ImageList>
            {fullImageDialogue}
        </Box>
    )
}

export interface ImageListViewProps {
    images: { ordering: Array<string>, images: { [index: string]: ImageSrc } }
    ratingMap?: { [index: string]: number }
    contentWidth: number
    selected: Set<string>
    setSelected: Dispatch<SetStateAction<Set<string>>>
}

function ListImageTile(props: ListImageTileProps) {
    const [mouseOver, setMouseOver] = useState<boolean>();

    function getBorders(): string {
        if (mouseOver) {
            return "4px solid gray";
        }
        return "4px solid white";
    }

    function getBoxShadow(): number {
        if (mouseOver) {
            return 4;
        }
        return 1;
    }

    function handleMouseEnterImage(event: MouseEvent) {
        let id = event.currentTarget.getAttribute("id");
        if (id != null) {
            setMouseOver(true);
        }
    }
    function handleMouseEnterTitle(event: MouseEvent) {
        event.preventDefault()
    }
    function handleMouseLeaveImage(event: MouseEvent) {
        setMouseOver(false)
    }
    function handleMouseLeaveTitle(event: MouseEvent) {
        event.preventDefault()
    }

    return <ImageListItem
        onMouseEnter={handleMouseEnterImage}
        onMouseLeave={handleMouseLeaveImage}
        onClick={props.handleMouseClickImage}
        key={props.id}
        id={props.id}
        sx={{
            willChange: "transform",
            border: getBorders(),
            boxShadow: getBoxShadow(),
            maxheight: 200,
            maxWidth: 200,
            overflow: "hidden",
            objectFit: "scale-down"
        }}>
        {props.image}
        {mouseOver || props.selected.has(props.id) ?
            <ImageListItemBar
                onMouseEnter={handleMouseEnterTitle}
                onMouseLeave={handleMouseLeaveTitle}
                position="top"
                title={props.name}
                subtitle={props.rating ? "Rating: " + props.rating : undefined}
                actionIcon={
                    <IconButton>
                        {props.selected.has(props.id) ? <Circle color="secondary" /> : <CircleOutlined color="secondary" />}
                    </IconButton>
                }
                sx={{ height: "2.5rem" }} /> : null}
    </ImageListItem>
}

interface ListImageTileProps {
    handleMouseClickImage: (event: MouseEvent) => void
    key: string
    id: string
    image: JSX.Element
    selected: Set<string>
    name: string
    rating?: string
}
