import { Clear, Close, KeySharp } from "@mui/icons-material";
import { Alert, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Input, LinearProgress, Typography, useMediaQuery } from "@mui/material";
import React, { DOMAttributes, MouseEvent, useEffect, useMemo, useState } from "react";
import { Box } from '@mui/system';
import RUNTIME_CONFIG from "../../config/Configuration";
import { Folder, ImageSrc } from "../../datamodel/DataModel";
import { Theme, useTheme } from "@mui/material/styles";
import { uploadImage } from "../../api/ImageApi";
import { ImagePreview } from "./ImagePreview";

export default function ImageUploadDialog(props: ImageUploadDialogProps) {
    const [imageUrls, setImageUrls] = useState<{ [index: string]: string }>({});
    const [imageFiles, setImageFiles] = useState<{ [index: string]: File }>({});
    const [uploaded, setUploaded] = useState<[number, number]>([0, 0]);
    const [uploadedMap, setUploadedMap] = useState<{ [index: string]: boolean }>({});
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [infoMessage, setInfoMessage] = useState<string>("");
    const [uploadSubmitted, setUploadSubmitted] = useState<boolean>(false);

    const controller = new AbortController();
    const signal = controller.signal;

    const theme: Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    // 1. Get the image files processed
    useEffect(() => {
        setErrorMessage("");
        setImageUrls(imageUrls => Object.entries(imageFiles).reduce((dict, [index, imageFile]) => {
            dict[+index] = URL.createObjectURL(imageFile); return dict;
        }, {} as { [index: number]: string }));
        setUploaded([0, Object.keys(imageFiles).length]);
        setUploadedMap(Object.entries(imageFiles).reduce((dict, [index, imageFile]) => {
            dict[+index] = false; return dict;
        }, {} as { [index: number]: boolean }))
    }, [imageFiles])

    useEffect(() => {
        Object.entries(imageFiles).map(([key, imageFile]) => {
            if (signal.aborted || errorMessage != "" || !uploadSubmitted) {
                return true;
            }
            else {
                uploadImage(imageFile, props.currentFolder, signal).then(image => {
                    if (image === null || image === undefined) {
                        throw Error("image upload failed")
                    }
                    setUploadedMap(uploadMap => { return { ...uploadMap, [key]: true } })
                    setUploaded(uploaded => [uploaded[0] + 1, uploaded[1]])
                })
            }
        })
    }, [uploadSubmitted])

    function handleClose(event: MouseEvent<HTMLButtonElement>) {
        props.setIsOpen(false);
        Object.values(imageUrls).forEach(imageUrl => URL.revokeObjectURL(imageUrl));
        setImageFiles({});
        setImageUrls({});
        window.location.reload();
        props.setReload(true);
    }

    function handleCancel(event: MouseEvent<HTMLButtonElement>) {
        setUploadSubmitted(false);
        controller.abort();
        setErrorMessage("Canceled upload - uploaded files will remain")
        props.setReload(true);
    }

    function handleConfirm(event: MouseEvent<HTMLButtonElement>) {
        setErrorMessage("");
        setUploaded(uploaded => [0, uploaded[1]])
        setUploadSubmitted(true);
    }

    const dialogTitle = useMemo(() =>
        <DialogTitle sx={{
            mt: fullScreen ? 8 : 0
        }} >
            Upload Images
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    mt: fullScreen ? 8 : 0,
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "text.primary"
                }}>
                <Close />
            </IconButton>
            <Divider sx={{ mt: 1 }} />

        </DialogTitle>,
        [imageFiles, fullScreen, infoMessage])

    const dialogActionMessage = useMemo(() =>
        <React.Fragment>
            {errorMessage != "" ? <Alert severity="error">{errorMessage}</Alert> : null}
            {successMessage != "" ? <Alert icon={false} severity="success">{successMessage}</Alert> : null}
        </React.Fragment>,
        [errorMessage, successMessage]);

    const dialogActionStatus = useMemo(() =>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                {(uploadSubmitted || errorMessage != "") ?
                    <React.Fragment>
                        <LinearProgress
                            variant="determinate"
                            value={100 * uploaded[0] / uploaded[1]}
                            color={uploaded[0] == uploaded[1] ? "success" : errorMessage == "" ? "primary" : "error"} />
                        <Box sx={{ minWidth: 35 }}>
                            <Typography variant="body2" color="text.secondary">{uploaded[0]}/{uploaded[1]}</Typography>
                        </Box>
                    </React.Fragment>
                    : null}
            </Box>
        </Box>,
        [imageUrls, uploadSubmitted, errorMessage, uploaded]);

    const dialogActionButtons = useMemo(() =>
        uploaded[0] == uploaded[1] && uploaded[1] > 0 ?
            <Button variant="outlined" onClick={handleClose}>
                Close
            </Button> :
            uploadSubmitted ?
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                :
                <Button variant="contained" onClick={handleConfirm}>
                    Upload
                </Button>,
        [uploaded, uploadSubmitted])

    const dialogActions = useMemo(() =>
        <DialogActions sx={{mt:4}}>
            <Container>
                {dialogActionMessage}
                <Divider sx={{ m: 1, visibility: 'hidden' }} />
                {dialogActionStatus}
            </Container>
            {dialogActionButtons}
        </DialogActions>,
        [dialogActionButtons, dialogActionStatus, dialogActionMessage,])

    return (
        <React.Fragment>
            <canvas id="canvas" style={{ visibility: 'hidden' }} />
            <Dialog
                open={props.isOpen}
                maxWidth="md"
                fullWidth
                fullScreen={fullScreen}>
                {dialogTitle}
                <ImagePreview maxHeight="24rem" imageFiles={imageFiles} setImageFiles={setImageFiles} uploadedMap={uploadedMap} />
                {dialogActions}
            </Dialog >
        </React.Fragment>
    )
}

export interface ImageUploadDialogProps {
    setReload: Function,
    isOpen: boolean,
    setIsOpen: Function,
    currentFolder: Folder
}

export interface ImageMeta {
    name: string,
    lastModified: number,
    type: string
}


