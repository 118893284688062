import { bothNum } from "./bothNum";


export function sortString(a: { name: string; }, b: { name: string; }): number {

  const aPart = a.name.match(/\d+|\D+/g) ?? [""];
  const bPart = b.name.match(/\d+|\D+/g) ?? [""];
  let shortEnd = Math.min(aPart.length, bPart.length);
  // We sort by assuming the beginning is the priority
  let result = 0;
  for (let i = 0; i < shortEnd; i++) {
    if (bothNum(aPart[i], bPart[i])) {
      result += 10 ** (shortEnd - i) * (+aPart[i] - +bPart[i]);
    }
    else {
      result += 10 ** (shortEnd - i) * (aPart[i].localeCompare(bPart[i]));
    }
  }
  return result;
}
