import RUNTIME_CONFIG from "../config/Configuration";
import { Folder } from "../datamodel/DataModel";


export async function createFolder(name: string, userId: string, parentFolderIds: Array<string>): Promise<Folder> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/folder", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    mode: "cors",
    cache: "default",
    body: JSON.stringify({
      name: name,
      userId: userId,
      parentFolderIds: parentFolderIds
    })
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  });
}

export async function deleteFolder(folder: Folder): Promise<Folder> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/folder", {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    mode: "cors",
    cache: "default",
    body: JSON.stringify(folder)
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  });
}

export async function moveFolder(folder: Folder, destination: String): Promise<Folder> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/folder/" + destination, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    mode: "cors",
    cache: "default",
    body: JSON.stringify(folder)
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  });
}

export async function getFoldersByUserId(folderId: string, signal?: AbortSignal): Promise<{ [index: string]: Folder; }> {
  return await fetch(RUNTIME_CONFIG.api_address + "/api/folder/user/" + folderId, {
    method: "GET",
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    },
    signal: signal
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  })
}
