import { BackspaceOutlined, CheckCircleOutline, Close, FileCopyOutlined } from "@mui/icons-material";
import { Button, Divider, ImageList, LinearProgress, Theme, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { ChangeEvent, Dispatch, DragEvent, MouseEvent, SetStateAction, useEffect, useMemo, useState } from "react";
import { Box } from '@mui/system';
import { sortString } from "../../util/sortString";
import { ImageMeta } from "./ImageUploadDialog";
import { ImageUploadTileProps, ImageUploadTile } from "./ImageUploadTile";


export function ImagePreview({ uploadedMap, maxHeight, imageFiles, setImageFiles }: ImagePreviewProps) {

    const [thumbnails, setThumbnails] = useState<{ [index: string]: ImageUploadTileProps; }>({});
    const [imageUrls, setImageUrls] = useState<{ [index: string]: string; }>({});
    const [imageMeta, setImageMeta] = useState<{ [index: string]: ImageMeta; }>({});
    const [uploadBoxBorder, setUploadBoxBorder] = useState<string>("1px dashed #0288d1");
    const [rendering, setRendering] = useState<number>(0);
    const [renderQueue, setRenderQueue] = useState<Array<[string, string]>>([]);
    const [rendered, setRendered] = useState<number>(0);

    const [listWidth, setListWidth] = useState(0);

    const theme: Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const listRef = (node: HTMLUListElement) => {
        if (node != null) {
            setListWidth(node.clientWidth);
        }
    };

    useEffect(() => {
        setImageUrls(imageUrls => Object.entries(imageFiles).reduce((dict, [index, imageFile]) => {
            dict[+index] = URL.createObjectURL(imageFile); return dict;
        }, {} as { [index: number]: string; }));
        setImageMeta(imageMeta => Object.entries(imageFiles).reduce((dict, [index, imageFile]) => {
            dict[+index] = { name: imageFile.name, lastModified: imageFile.lastModified, type: imageFile.type }; return dict;
        }, {} as { [index: number]: ImageMeta; }));
    }, [imageFiles]);


    // 2. Then render the images
    const queueSize = 16;
    useEffect(() => {
        setRenderQueue(Object.keys(imageUrls).map(a => [a, imageUrls[a]]));
        setRendering(0);
        setRendered(0);
        setThumbnails(Object.keys(imageUrls)
            .reverse()
            .reduce((loadingGrid: { [index: number]: ImageUploadTileProps; }, key: string) => {
                loadingGrid[+key] = {
                    loading: true,
                    index: key,
                    imageMeta: {},
                    url: "",
                    small: fullScreen ? fullScreen : undefined,
                    clickButton: <React.Fragment />,
                    clickHandler: () => void (0),
                    onLoadHandler: () => void (0),
                };
                return loadingGrid;
            },
                {} as { [index: number]: ImageUploadTileProps; }));
    }, [imageUrls, fullScreen]);

    useEffect(() => {
        if (rendering < queueSize && renderQueue.length > 0) {
            for (let u = 0; u < Math.min(queueSize - rendering, renderQueue.length); u++) {
                let [index, url] = renderQueue[u] ?? [null, null] as unknown as [string, string];
                if (index != null && url != null) {
                    setThumbnails(thumbnails => {
                        return {
                            ...thumbnails,
                            [index]: {
                                loading: false,
                                index: index,
                                imageMeta: imageMeta,
                                url: url,
                                small: fullScreen ? fullScreen : undefined,
                                clickButton: <Close />,
                                clickHandler: () => handleRemoveImage(index),
                                onLoadHandler: (e) => { e.currentTarget.style.display = "inline-block"; setRendering(r => r - 1); setRendered(r => r + 1); }
                            }
                        };
                    }
                    );
                }
            }
            setRendering(r => r + Math.min(queueSize - rendering, renderQueue.length));
            setRenderQueue(r => r.slice(Math.min(queueSize - rendering, renderQueue.length), r.length));

        }
    }, [rendering, renderQueue, fullScreen]);

    useEffect(() => {
        if (uploadedMap != undefined) {
            setThumbnails(thumbnails => {
                return Object.entries(thumbnails).reduce((dict, [index, value]) => {
                    //@ts-ignore
                    let button = uploadedMap[value.index] ? <CheckCircleOutline /> : <Close />;
                    dict[value.index] = {
                        ...value, clickButton: button
                    };
                    return dict;
                }, {} as { [index: string]: ImageUploadTileProps; });
            });
        }
    }, [uploadedMap]);

    function handleRemoveImage(index: string) {
        URL.revokeObjectURL(imageUrls[index]);
        setThumbnails(thumbnails => { let newThumbnails = { ...thumbnails }; delete newThumbnails[index]; return newThumbnails; });
        setImageUrls(imageUrls => { let newImageUrls = { ...imageUrls }; delete newImageUrls[index]; return newImageUrls; });
        setImageMeta(imageMeta => { let newImageMeta = { ...imageMeta }; delete newImageMeta[index]; return newImageMeta; });
        setImageFiles(imageFiles => { let newImageFiles = { ...imageFiles }; delete newImageFiles[index]; return newImageFiles; });
    }


    function handleClear(event: MouseEvent<HTMLButtonElement>) {
        Object.values(imageUrls).forEach(imageUrl => URL.revokeObjectURL(imageUrl));
        setImageFiles({});
        setImageUrls({});
        setImageMeta({});
        setThumbnails({});
        setRendered(0);
    }

    function handleDrag(event: DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
    }
    function handleDragIn(event: DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        setUploadBoxBorder("2px solid #0288d1");
    }
    function handleDragOut(event: DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        setUploadBoxBorder("1px dashed #0288d1");
    }

    function handleDrop(event: DragEvent<HTMLDivElement>) {
        event.preventDefault();
        event.stopPropagation();
        setUploadBoxBorder("1px dashed #0288d1");

        setImageFilesFromFileArray(Array.from(event.dataTransfer?.files ?? []));

        event.dataTransfer.clearData();
    }

    function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
        setImageFilesFromFileArray(Array.from(event.target?.files ?? []));
    }

    function setImageFilesFromFileArray(files: Array<File>) {
        files.sort(sortString);
        setImageFiles(imageFiles => files.reduce((newImageFiles, file) => {
            return {
                ...newImageFiles,
                [getNextAvailableId(newImageFiles)]: file
            };
        }, { ...imageFiles } as { [index: number]: File; }));
    }


    function getNextAvailableId(object: { [index: number]: any; }): number {
        let keys = Object.keys(object);
        if (keys.length == 0) {
            return 0;
        }
        else {
            keys.sort((a, b) => (+a) - (+b));
            if (+keys[keys.length - 1] == (keys.length - 1)) {
                return keys.length;
            }
            else {
                for (let i = 0; i < keys.length; i++) {
                    if (i != +keys[i]) {
                        return i;
                    }
                }
            }
            return keys.length;
        }
    }

    const toolbarRender = useMemo(() => <Toolbar disableGutters>
        <Button
            variant="contained"
            component="label">
            Select
            <FileCopyOutlined sx={{ ml: "0.5rem" }} />
            <input
                type="file"
                key={Object.keys(imageFiles).length}
                multiple
                onChange={handleFileUpload}
                hidden />
        </Button>
        <Divider orientation="vertical" sx={{ flexGrow: 1, visibility: "hidden" }} />
        <Button
            onClick={handleClear}
            variant="text">
            Clear
            <BackspaceOutlined sx={{ ml: "0.5rem" }} />
        </Button>
    </Toolbar>,
        [imageFiles]);

    const progressBarRender = useMemo(() => rendered < Object.keys(imageUrls).length ?
        <React.Fragment>
            <LinearProgress
                variant="determinate"
                value={100 * rendered / Object.keys(imageUrls).length}
                color={"primary"} />
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2"
                    color="text.secondary">
                    {rendered}/{Object.keys(imageUrls).length}
                </Typography>
            </Box> </React.Fragment>
        : null,
        [imageUrls, rendered, renderQueue]);

    const imagePreviewRender = useMemo(() =>
        <Box sx={{
            height: maxHeight,
            my: 4, mx: 1
        }}>
            {toolbarRender}
            <Box
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onDragEnter={handleDragIn}
                onDragLeave={handleDragOut}
                onChange={() => { }}
                sx={{
                    ml: 1, mr: 1, mb: 1, pl: 1, pr: 1, pt: 0, pb: 0,
                    border: uploadBoxBorder,
                    borderRadius: 1,
                    height: "95%"
                }}>
                <ImageList
                    ref={listRef}
                    cols={Math.floor(listWidth / (fullScreen ? 100 : 200))}
                    rowHeight={fullScreen ? 100 : 200}
                    sx={{
                        maxHeight: "95%",
                    }}>
                    {Object.keys(thumbnails).sort((a, b) => (+a) - (+b)).map(index => <ImageUploadTile key={index} {...thumbnails[+index]} />)}
                </ImageList>
            </Box>
            {progressBarRender}
        </Box>,
        [uploadBoxBorder, maxHeight, thumbnails, listWidth, progressBarRender, toolbarRender]);

    return (imagePreviewRender);

}

export interface ImagePreviewProps {
    uploadedMap?: { [index: string]: boolean; };
    maxHeight: number | string;
    imageFiles: { [index: string]: File; };
    setImageFiles: Dispatch<SetStateAction<{ [index: string]: File; }>>;
}
