import { Alert, Button, Checkbox, Container, FormControlLabel, Grid, TextField, Typography, useTheme } from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser, loginUser } from "../api/ApiCalls";
import { User } from "../datamodel/DataModel";
import CreateUserPage from "./CreateUserPage";

/**
 * Component that is shown in place of the gallery. The application 
 * will provide a callback to receive user information.
 * 
 * The login API will use the username and a hashed password (which will be plaintext 
 * while I research how to do this the best way)
 * TODO: make the password not just a plaintext security chasm
 */
export default function LoginPage(props: LoginComponentProps) {
    const theme = useTheme();
    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
    }

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [error, setError] = useState([false, ""]);
    const [loginSubmitted, setLoginSubmitted] = useState(false);
    const [loginSucceeded, setLoginSucceeded] = useState(false);

    const passwordRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    useEffect(() => {
        setError([false, ""])
    }, [username, password])

    useEffect(() => {
        if (loginSubmitted) {
            const controller = new AbortController();
            const signal = controller.signal;

            handleLogin(signal);

            setLoginSubmitted(!loginSubmitted)
        }
    }, [loginSubmitted])

    useEffect(() => {
        if (loginSucceeded) {
            const controller = new AbortController();
            const signal = controller.signal;
            getUser(username, signal).catch(error => {
                if (error instanceof Error) {
                    if (error.message === "Not Found") {
                        setError([true, "User not found or password incorrect"])
                        setLoginSucceeded(false);
                        setLoginSubmitted(false);
                    }
                }
            }).then(user => {
                if (user != undefined) {
                    props.submitted(user);
                }
            })
        }
    }, [loginSucceeded])

    function handleLogin(signal: AbortSignal) {
        try {
            isValidUsername(username);
            isValidPassword(password);

            if (true === error[0]) {
                setError([false, ""]);
            }

            loginUser(username, password, signal)
                .then(result =>
                    setLoginSucceeded(true)
                );
        }
        catch (e: unknown) {
            if (e instanceof Error) {
                setError([true, e.message]);
            }
        }

    }

    function handleUsername(event: ChangeEvent<HTMLInputElement>) {
        setUsername(event.target.value);
    }

    function handlePassword(event: ChangeEvent<HTMLTextAreaElement>) {
        setPassword(event.target.value);
    }

    function handleCreateAccount() {
        navigate("../create_user")
    }

    function handleShowPassword() {
        "password" === passwordType ? setPasswordType("text") : setPasswordType("password")
    }

    function handleUsernameSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        passwordRef?.current?.focus();
    }

    function handlePasswordSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setLoginSubmitted(true);
    }

    return (
        <Container maxWidth="xs" sx={{ display: "flex", mt: 10, height: windowY - 80 }}>
            <br />
            <Grid container direction={"column"} spacing={1}>
                <Grid item>
                    <Typography align="center" variant="h3" component="div" gutterBottom={true} color={theme.palette.text.primary}>Login</Typography>
                </Grid>
                <Grid item>
                    <form onSubmit={handleUsernameSubmit}>
                        <TextField
                            fullWidth
                            label="Username"
                            value={username}
                            onChange={handleUsername}
                        />
                    </form>
                </Grid>
                <Grid item>
                    <form onSubmit={handlePasswordSubmit}>
                        <TextField
                            inputRef={passwordRef}
                            fullWidth
                            label="Password"
                            type={passwordType}
                            value={password}
                            onChange={handlePassword}
                        />
                    </form>
                </Grid>
                <Grid item >
                    <FormControlLabel sx={{ display: "flex", alignContent: "flex-start", mx: "auto" }}
                        control={
                            <Checkbox
                                checked={passwordType === "text"}
                                onChange={handleShowPassword} />
                        }
                        label={<Typography color={theme.palette.text.primary}>Show password</Typography>}
                    />
                </Grid>
                <Grid item>
                    {error[0] === false ? null : <Alert severity="error" >{error[1]}</Alert>}
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setLoginSubmitted(true)}>
                        Login
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={handleCreateAccount}>
                        Create Account
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

function isValidUsername(text: string) {
    if (text.length === 0) {
        throw new Error("Username cannot be blank");
    }
    if (text.length < 3) {
        throw new Error("Username must be 3 characters or longer");
    }
}

function isValidPassword(text: string) {
    if (text.length === 0) {
        throw new Error("Password cannot be blank")
    }
    if (text.length < 8) {
        throw new Error("Password must be at least 8 charaters")
    }
}

export interface LoginComponentProps {
    submitted: (user: User) => void;
}
