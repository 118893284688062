import { Cancel, Filter } from "@mui/icons-material";
import { Autocomplete, Box, Button, Chip, createFilterOptions, IconButton, Paper, Popper, TextField, Tooltip } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";


export default function TagBar(props: TagBarProps) {
    const [tagPool, setTagPool] = useState<Array<string>>([]);
    const [autocompleteValue, setAutocompleteValue] = useState<Array<string | { label: string, base: string }>>([]);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [submit, setSubmit] = useState<boolean>(false);

    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }

    const filter = createFilterOptions<string | { label: string, base: string }>();

    useEffect(() => setTagPool(props.tags), [props.tags]);
    useEffect(() => setAutocompleteValue(props.selectedTags), [props.selectedTags]);


    useEffect(() => {
        if (submit) {
            let values = autocompleteValue.map(v => typeof v === "string" ? v : v.base);
            let existingTagsSet = new Set(props.selectedTags);
            let valueSet = new Set(values);
            props.setUpdatedTags(
                {
                    new: values.filter(tag => !existingTagsSet.has(tag)),
                    deleted: props.selectedTags.filter(tag => !valueSet.has(tag))

                });
            setSubmit(false);
        }
    }, [submit])

    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            {open || windowX < 800 ? null : <ReadTagBar selectedTags={props.selectedTags} />}
            <Tooltip title={open ? "Cancel" : "Tags"}>
                <IconButton
                    color="primary"
                    onClick={(e) => { setOpen(o => !o); setAnchorEl(e.currentTarget); }}>
                    {open ? <Cancel /> : <Filter />}
                </IconButton>
            </Tooltip>
            <Popper
                open={open}
                placement={props.direction === "left" ? "left-start" : props.direction === "bottom" ? "bottom" : "auto"}
                anchorEl={anchorEl}
            >
                <Paper sx={{ display: "flex", maxWidth: windowX > 800 ? "35vw" : "90vw" }}>
                    <Autocomplete
                        multiple
                        options={tagPool}
                        value={autocompleteValue}
                        sx={{ width: windowX > 800 ? "35vw" : "90vw" }}
                        onChange={(event, value, reason) => {
                            setAutocompleteValue(value);
                        }}
                        freeSolo={props.freeSolo}
                        filterSelectedOptions
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (props.freeSolo) {
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({ label: "Add " + inputValue, base: inputValue });
                                }
                            }
                            return filtered;
                        }}
                        getOptionLabel={(option) => {
                            if (typeof option === "string") {
                                return option
                            }
                            else {
                                return option.base;
                            }

                        }}
                        renderOption={(props, option) => <li {...props}>{typeof option === "string" ? option : (option).label}</li>}
                        renderTags={(value: Array<string | { label: string, base: string }>, getTagProps) =>
                            value.map((option: string | { label: string, base: string }, index: number) => (
                                <Chip
                                    size="medium"
                                    variant="filled"
                                    label={typeof option === "string" ? option : option.base}
                                    sx={{ height: "1rem" }}
                                    {...getTagProps({ index })} />
                            ))}
                        renderInput={(params) => (
                            <TextField
                                variant="outlined"
                                margin="none"
                                placeholder="Set tags"
                                {...{ ...params, size: "small", InputProps: { ...params.InputProps, sx: { m: 0, p: 0, alignContent: "center" } } }}
                                sx={{ width: "100%" }} />
                        )} />
                    {props.selectedTags.length != autocompleteValue.length || props.deletedTags.length > 0 ?
                        <Button variant="outlined" onClick={() => setSubmit(true)}>Submit</Button> : null}
                </Paper>
            </Popper>
        </Box>
    );
}

interface TagBarProps {
    tags: Array<string>
    selectedTags: Array<string>
    deletedTags: Array<string>
    setUpdatedTags: Dispatch<SetStateAction<{ new: Array<string>, deleted: Array<string> }>>
    freeSolo?: boolean
    direction?: "left" | "bottom"
}

function ReadTagBar(props: ReadTagBarProps) {

    const tags = props.selectedTags.map(tag =>
        <Chip
            key={tag}
            size="medium"
            variant="outlined"
            label={tag}
            sx={{ height: "1rem" }} />)

    return (
        <Box sx={{ mr: 2, display: "flex", height: "100%", width: "100%", alignItems: "center" }}>
            {tags}
        </Box>
    )
}

interface ReadTagBarProps {
    selectedTags: Array<string>
}
