import { Box, ButtonBase, Container, Typography, useTheme } from '@mui/material';
import { SyntheticEvent } from 'react';
import { ImageSrc } from '../datamodel/DataModel';
import { buildUrl } from "../util/buildUrl";


export function SingleImage(props: SingleImageProps) {
    const theme = useTheme();
    const imageSrc = props.imageSrc;

    if (imageSrc !== undefined) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", flexShrink: 1, my: 1, alignContent: "flex-start", justifyContent: "center" }}>
                {props.title ? <Typography variant="h6" align='center' color={theme.palette.text.primary}>{props.title}</Typography> : null}
                <Box sx={{
                    display: "flex",
                    flexShrink: 1,
                    alignContent: "flex-start",
                    boxShadow: 4,
                    border: "5px solid white",
                    justifyContent: "center",
                    height: props.height,
                }}>
                    <ButtonBase onClick={props.onClick}>
                        <img
                            crossOrigin="anonymous"
                            decoding="async"
                            src={buildUrl(imageSrc.size === "medium" ? imageSrc.source : imageSrc.alternateSizes["medium"])}
                            typeof={imageSrc.type}
                            alt={imageSrc?.name ?? "image"}
                            loading="eager"
                            style={{
                                overflow: "hidden",
                                width: "auto",
                                height: "auto",
                                maxHeight: "100%",
                                maxWidth: "100%",
                                objectFit: "contain",
                                willChange: "transform",
                            }} />
                    </ButtonBase>

                </Box>
            </Box>
        );
    }
    else {
        return (<Container sx={{ display: "flex", flexShrink: 1, alignContent: "center", justifyContent: "center" }}>
        </Container>);
    }
}
interface SingleImageProps {
    imageSrc: ImageSrc | undefined;
    nextImage?: ImageSrc;
    height: number;
    title?: string;
    onClick?: (event: SyntheticEvent) => void;
}
