import { Box } from '@mui/material';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { atom, SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { ImageSrc } from '../datamodel/DataModel';
import { mainBarState, subBarState } from '../Picelo';
import FourPaneView from '../components/FourPaneView';
import { SingleImage } from '../components/SingleImage';
import { buildUrl } from '../util/buildUrl';

export const singleHighlightedState = atom({
    key: "singleHighlightedState",
    default: undefined as unknown as ImageSrc
})


export default function CarouselView(props: CarouselViewProps) {
    const [items, setItems] = useState<Array<ImageSrc>>([]);
    const [feedHeight, setFeedHeight] = useState<number>(0);

    const mainBarHeight = useRecoilValue(mainBarState);
    const subBarHeight = useRecoilValue(subBarState);

    const [selected, setSelected] = useRecoilState<ImageSrc>(singleHighlightedState);


    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }

    useEffect(() => {
        setItems(props.images.ordering.map(id => props.images.images[id]))
        if (selected === undefined) {
            setSelected(props.selected)
        }
    }, [props.images, props.selected])

    return (
        <FourPaneView
            leftPane={<React.Fragment />}
            middlePane={<SingleImage
                imageSrc={selected}
                height={windowY - (mainBarHeight + subBarHeight + feedHeight + 50)} />}
            rightPane={<React.Fragment />}
            bottomPane={
                <CarouselHorizontalFeed
                    setFeedHeight={setFeedHeight}
                    images={items}
                    windowX={windowX}
                    setSelected={setSelected}
                    selected={selected} />}
        />
    )
}

interface CarouselViewProps {
    images: { ordering: Array<string>, images: { [index: string]: ImageSrc } }
    selected: ImageSrc
}

function CarouselHorizontalFeed(props: CarouselFeedProps) {
    const feedRef = useRef<HTMLDivElement>(null);
    const innerFeedRef = useRef<HTMLDivElement>(null);
    const [images, setImages] = useState<Array<JSX.Element>>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        props.setFeedHeight(feedRef?.current?.clientHeight ?? 0);
    }, [images])


    useEffect(() => {
        setImages(props.images.map((image, index) => {
            if (image.id === props.selected?.id ?? null) {
                setCurrentIndex(index)
            }
            return <CarouselItem
                key={image.id}
                windowX={props.windowX}
                selected={props.selected}
                setSelected={props.setSelected}
                imageSrc={image} />
        }))
    }, [props.images, props.selected, props.windowX])

    useEffect(() => {
        document.body.addEventListener("wheel", wheelEventHandler as unknown as EventListener, true);
        return (() =>
            document.body.removeEventListener("wheel", wheelEventHandler as unknown as EventListener, true)
        )
    }, [])

    useEffect(() => {
        document.body.addEventListener("keydown", keyPressEventHandler as unknown as EventListener, true);
        return (() =>
            document.body.removeEventListener("keydown", keyPressEventHandler as unknown as EventListener, true)
        )
    }, [props.images, currentIndex])

    function wheelEventHandler(event: WheelEvent) {
        if (innerFeedRef.current != null) {
            innerFeedRef.current.scrollLeft += event.deltaY;
        }
    }

    function keyPressEventHandler(event: KeyboardEvent) {
        switch (event.key) {
            case "ArrowLeft":
                if (currentIndex > 0) {
                    event.preventDefault();
                    props.setSelected(props.images[currentIndex - 1]);
                }
                break;
            case "ArrowRight":
                if (currentIndex < props.images.length - 1) {
                    event.preventDefault();
                    props.setSelected(props.images[currentIndex + 1]);
                }
                break;
            case "ArrowUp":
                if (currentIndex > 0) {
                    event.preventDefault();
                    props.setSelected(props.images[currentIndex - 1]);
                }
                break;
            case "ArrowDown":
                if (currentIndex < props.images.length - 1) {
                    event.preventDefault();
                    props.setSelected(props.images[currentIndex + 1]);
                }
                break;
        }
    }

    return (
        <Box ref={feedRef} sx={{ display: "inline-flex", width: "100%", willChange: "transform" }}>
            <Box ref={innerFeedRef} sx={{ display: "inline-flex", width: "100%", overflow: "hidden", willChange: "transform" }}>
                {images}
            </Box>
        </Box>
    )
}

interface CarouselFeedProps {
    images: Array<ImageSrc>
    selected: ImageSrc | undefined
    setSelected: SetterOrUpdater<ImageSrc>
    setFeedHeight: Function
    windowX: number
}

function CarouselItem(props: CarouselItemProps) {
    const imageSrc = props.imageSrc;
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props?.selected?.id === imageSrc.id && selectRef.current != null) {
            selectRef.current.scrollIntoView({ behavior: "smooth", inline: "center" });
        }
    }, [props.selected])

    function getBorder() {
        if (props.selected?.id === imageSrc.id) {
            return "4px solid black";
        }
        if (mouseOver) {
            return "4px solid gray";
        }
        return "4px solid white";
    }

    function getBoxShadow() {
        if (mouseOver) {
            return 4;
        }
        return 1;
    }

    if (props.windowX < 500) {
        return (
            <Box
                onMouseOver={() => setMouseOver(true)}
                onMouseOut={() => setMouseOver(false)}
                onClick={() => props.setSelected(imageSrc)}
                ref={selectRef}
                sx={{ display: "inline-flex", flexShrink: 1, willChange: "transform", border: getBorder(), boxShadow: getBoxShadow() }}>
                <img
                    crossOrigin="anonymous"
                    decoding="async"
                    src={buildUrl(imageSrc.size === "thumbnail" ? imageSrc.source : imageSrc.alternateSizes["thumbnail"])}
                    typeof={imageSrc.type}
                    alt={imageSrc?.name ?? "image"}
                    loading="eager"
                    style={{
                        height: 100,
                        width: 50,
                        overflow: "hidden",
                        objectFit: "cover"
                    }}
                />
            </Box>
        );
    }
    else {
        return (
            <Box
                onMouseOver={() => setMouseOver(true)}
                onMouseOut={() => setMouseOver(false)}
                onClick={() => props.setSelected(imageSrc)}
                ref={selectRef}
                sx={{ display: "inline-flex", flexShrink: 1, willChange: "transform", border: getBorder(), boxShadow: getBoxShadow() }}>
                <img
                    crossOrigin="anonymous"
                    decoding="async"
                    src={buildUrl(imageSrc.size === "thumbnail" ? imageSrc.source : imageSrc.alternateSizes["thumbnail"])}
                    typeof={imageSrc.type}
                    alt={imageSrc?.name ?? "image"}
                    loading="eager"
                    style={{
                        height: 100,
                        width: "auto",
                        willChange: "transform",
                    }}
                />
            </Box>
        );
    }
}

interface CarouselItemProps {
    imageSrc: ImageSrc
    selected: ImageSrc | undefined
    setSelected: SetterOrUpdater<ImageSrc>
    windowX: number
}
