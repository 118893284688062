import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Dispatch, Ref, SetStateAction } from "react";
import { ImageSrc } from "../datamodel/DataModel";
import { Box } from '@mui/system';
import { buildUrl } from "../util/buildUrl";


export function ImageDialog(props: ImageDialogProps) {
    const imageSrc = props.imageSrc;
    return (
        <Dialog
            maxWidth="lg"
            open={props.highlight != undefined}
            onClose={() => props.setHighlight(undefined)}
            sx={{ mt: 10 }}>
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => props.setHighlight(undefined)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "grey",
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", flexGrow: 1 }}></Box>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    boxShadow: 4,
                    border: "5px solid white",
                    overflow: "hidden",
                    maxWidth: props.imageWidth
                }}>
                    <img
                        ref={props.imageRef}
                        crossOrigin="anonymous"
                        decoding="async"
                        src={buildUrl(imageSrc.size === "full" ? imageSrc.source : imageSrc.alternateSizes["full"])}
                        typeof={imageSrc.type}
                        alt={imageSrc?.name ?? "image"}
                        loading="eager"
                        onLoad={() => props.setUpdated(false)}
                        style={{
                            width:"100%",
                            objectFit: "scale-down",
                        }} />
                </Box>
                <Box sx={{ display: "flex", flexGrow: 1 }}></Box>
            </DialogContent>
        </Dialog>
    );
}
interface ImageDialogProps {
    imageSrc: ImageSrc;
    imageRef: Ref<HTMLImageElement>;
    imageWidth: string | number;
    highlight: string;
    setHighlight: Dispatch<SetStateAction<string | undefined>>;
    setUpdated: Dispatch<SetStateAction<boolean>>;
}
