import RUNTIME_CONFIG from "../config/Configuration";
import { NewUserDetails } from '../user/CreateUserPage';
import { User } from './../datamodel/DataModel';
import { Buffer } from 'buffer';

export async function createUser(newUserDetails: NewUserDetails): Promise<User> {
  return fetch(RUNTIME_CONFIG.api_address + "/auth/user", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    mode: "cors",
    cache: "default",
    body: JSON.stringify(newUserDetails)
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  })
}

export async function checkUsername(username: string, signal?: AbortSignal): Promise<boolean> {
  return fetch(RUNTIME_CONFIG.api_address + "/auth/user/username/" + username, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    mode: "cors",
    cache: "default",
    signal: signal
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  })
}

export async function checkEmail(email: string, signal?: AbortSignal): Promise<boolean> {
  return fetch(RUNTIME_CONFIG.api_address + "/auth/user/email/" + email, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    mode: "cors",
    cache: "default",
    signal: signal
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  })
}

export async function getUser(username: string, signal?: AbortSignal): Promise<User> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/user/info/" + username, {
    method: "GET",
    credentials: "include",
    mode: "cors",
    signal: signal
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  })
}

export async function loginUser(username: string, password: string, signal?: AbortSignal): Promise<boolean | Error> {
  return fetch(RUNTIME_CONFIG.api_address + "/auth/login", {
    method: "POST",
    credentials: "include",
    headers: {
      "Authorization": "Basic " + Buffer.from(username + ":" + password).toString("base64")
    },
    mode: "cors",
    cache: "no-cache",
    signal: signal
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return true;
    }
    else {
      throw Error(response.statusText);
    }
  })
}