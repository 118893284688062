import { ChevronRight, DeleteOutlined, DriveFileMoveOutlined, ExpandMore, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/lab";
import { Box, ButtonGroup, Divider, Drawer, IconButton, Toolbar, Typography } from "@mui/material";
import React, { Dispatch, MouseEvent, SetStateAction, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Folder } from "../datamodel/DataModel";

export function FolioDrawer(props: FolioDrawerProps) {

    const [offsetLeft, setOffsetLeft] = useState<number>(0);
    const [offsetHeight, setOffsetHeight] = useState<number>(0);

    const handleResize = useCallback(() => {
        setOffsetLeft(document.body.offsetLeft);
        setOffsetHeight(document.body.offsetHeight);
    }, []);

    window.addEventListener("resize", handleResize);

    useEffect(() => {
        handleResize();
    }, [])

    const handleMouseDown = useCallback((event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        if (props.anchor === "left" || props.anchor === "right") {
            document.addEventListener("mousemove", handleMouseMoveX as unknown as EventListener, true)
        }
        else {
            document.addEventListener("mousemove", handleMouseMoveY as unknown as EventListener, true)
        }
        document.addEventListener("mouseup", handleMouseUp as unknown as EventListener, true)
    }, []);

    const handleMouseUp = useCallback((event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        if (props.anchor === "left" || props.anchor === "right") {
            document.removeEventListener("mousemove", handleMouseMoveX as unknown as EventListener, true)
        }
        else {
            document.removeEventListener("mousemove", handleMouseMoveY as unknown as EventListener, true)

        }
        document.removeEventListener("mouseup", handleMouseUp as unknown as EventListener, true)
    }, []);

    var throttled = false;

    const handleMouseMoveX = useCallback((event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        if (!throttled) {
            props.setSize(event.clientX - offsetLeft);
            throttled = true;
            setTimeout(() => throttled = false, 10);
        }
    }, [offsetLeft])

    const handleMouseMoveY = useCallback((event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        if (!throttled) {
            props.setSize(offsetHeight - event.clientY);
            throttled = true;
            setTimeout(() => throttled = false, 10);
        }
    }, [offsetHeight]);

    const appBarSpacers = useMemo(() =>
        props.anchor === "left" || props.anchor === "right" ?
            <React.Fragment>
                <Toolbar />
                <Toolbar />
                <Divider />
            </React.Fragment>
            : null
        , [props.anchor])

    const dragBoxVertical = useMemo(() => props.anchor === "left" || props.anchor === "right" ? null :
        <Box onMouseDown={handleMouseDown}
            sx={{ width: "100%", height: "5px", cursor: "n-resize", draggable: "true" }}>
        </Box>,
        [props.anchor])

    const dragBoxHorizontal = useMemo(() =>
        props.anchor === "left" || props.anchor === "right" ?
            <Box onMouseDown={handleMouseDown}
                sx={{ width: "5px", height: "100%", cursor: "w-resize", draggable: "true" }}>
            </Box> : null,
        [props.anchor])

    const directionIcon = useMemo(() => {
        switch (props.anchor) {
            case "left": return <KeyboardArrowLeft />;
            case "right": return <KeyboardArrowRight />;
            case "top": return <KeyboardArrowUp />;
            case "bottom": return <KeyboardArrowDown />;
            default: return null;
        }
    }, [props.anchor])

    return (
        <Drawer
            anchor={props.anchor}
            variant={props.variant}
            open={props.isDrawerOpen}
            onClose={() => props.handleDrawer()}
            sx={{
                zIndex: 10,
                [`& .MuiDrawer-paper`]: {
                    overflowX: "hidden",
                    flexShrink: 1,
                    willChange: "transform",
                    justifyContent: "center",
                },
            }}>
            <Box sx={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "center",
                width: props.drawerWidth, height: props.drawerHeight
            }}>
                {dragBoxVertical}
                <Box sx={{
                    width: "100%",
                    justifyContent: "center",
                }}>
                    {appBarSpacers}
                    <Toolbar variant="dense">
                        <Divider orientation="vertical" sx={{ flexGrow: 1, visibility: 'hidden' }} />
                        <IconButton onClick={() => props.handleDrawer()}>
                            {directionIcon}
                        </IconButton>
                    </Toolbar>
                    {props.components}
                </Box>
                {dragBoxHorizontal}
            </Box>
        </Drawer >
    )
}

export interface FolioDrawerProps {
    isDrawerOpen: boolean,
    handleDrawer: Function,
    drawerWidth: number | string
    drawerHeight: number | string
    anchor: "left" | "top" | "right" | "bottom" | undefined;
    variant: "permanent" | "persistent" | "temporary" | undefined;
    components: Array<JSX.Element>
    setSize: Dispatch<SetStateAction<number>>;
}

export function FolderTree(props: FolderTreeProps) {
    const [nestedFolders, setNestedFolders] = useState<Array<JSX.Element>>([]);
    const [expanded, setExpanded] = useState<Array<string>>([]);
    const [selected, setSelected] = useState<string>("");

    useEffect(() => {
        if (props.rootFolder != null && props.rootFolder != undefined) {
            setExpanded([props.rootFolder.id]);
        }
    }, [props.rootFolder])

    useEffect(() => {
        if (props.selected != undefined) {
            setSelected(props.selected)
        }
    }, [props.selected])

    useEffect(() => {
        if (props.rootFolder != null && props.rootFolder != undefined) {
            setNestedFolders(renderNestedFolders(props.rootFolder));
        }
    }, [expanded, selected])


    function handleDeleteFolder(folder: Folder) {
        setSelected(folder.parentFolderIds[folder.parentFolderIds.length - 1])
        props.handleFolderDelete?.(folder);
    }

    function renderNestedFolders(folder: Folder): Array<JSX.Element> {
        let rendered: Array<JSX.Element> = [];
        if (folder != undefined && Object.keys(folder).length > 0) {
            rendered.push(
                <TreeItem
                    key={folder.id}
                    label={<Box sx={{ display: "inline-flex", width: "100%", alignContent: "flex-end" }}>
                        <Typography sx={{ flexGrow: 1 }}>{folder.name}</Typography>
                        <ButtonGroup size="small" sx={{ height: "1rem" }}>
                            {
                                props.deletable &&
                                    selected !== props.rootFolder?.id
                                    && selected === folder.id
                                    && folder.folders.length === 0 ?
                                    <IconButton size="small" onClick={() => handleDeleteFolder(folder)} ><DeleteOutlined /></IconButton> : null}
                            {
                                props.moveable &&
                                    selected !== props.rootFolder?.id
                                    && selected === folder.id ?
                                    <IconButton size="small" onClick={() => props.handleFolderSelectMove?.(folder)} ><DriveFileMoveOutlined /></IconButton> : null}
                        </ButtonGroup>

                    </Box>}
                    nodeId={folder.id}
                >
                    {folder.folders.map((innerFolder) => {
                        return renderNestedFolders(props.folders[innerFolder]);
                    })}
                </TreeItem>
            )
        }
        return rendered;
    }

    return (
        <TreeView
            {...props.multiSelect}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={[...expanded, props.rootFolder?.id ?? ""]}
            selected={selected}
            onNodeToggle={(event: SyntheticEvent, nodes: Array<string>) => setExpanded(nodes)}
            onNodeSelect={(event: SyntheticEvent, id: string) => { setSelected(id); props.handleFolderClick(props.folders[id]) }}>
            {nestedFolders}
        </TreeView>
    )
}


export interface FolderTreeProps {
    multiSelect?: true | undefined
    selected?: string
    folders: { [index: string]: Folder }
    rootFolder: Folder | undefined
    handleFolderClick: (folder: Folder) => void
    deletable?: boolean
    moveable?: boolean
    handleFolderDelete?: (folder: Folder) => void
    handleFolderSelectMove?: (folder: Folder) => void
}
