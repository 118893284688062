import React from 'react';
import ReactDOM from 'react-dom';
import Picelo from './Picelo';
import reportWebVitals from './reportWebVitals';
import { ConfigLoader } from './config/Configuration';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./index.css"
import { RecoilRoot } from 'recoil';


ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<ConfigLoader isReady={() => <Picelo />} />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
