import { Add, DeleteOutlined } from "@mui/icons-material";
import { Autocomplete, Box, Button, Card, CardActionArea, CardActions, CardContent, Chip, createFilterOptions, Grid, TextField, Toolbar, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { deleteImageRanking, getUserImageRankings } from "../api/ImageRankingApi";
import { getUserTags } from "../api/TagApi";
import { User } from "../datamodel/DataModel";
import { ImageRanking } from '../datamodel/DataModel';
import { sortString } from "../util/sortString";
import { loggedInTabState } from "../Picelo"

export function RankingExplorer(props: RankingExplorerProps) {
    const controller = new AbortController();
    const signal = controller.signal;

    const [rankings, setRankings] = useState<{ [index: string]: ImageRanking; }>({});
    const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
    const [selectedRankings, setSelectedRankings] = useState<Array<ImageRanking>>([]);
    const [displayed, setDisplayed] = useState<Array<JSX.Element>>([]);
    const [order, setOrder] = useState<string>("create-time-descending");
    const [userTags, setUserTags] = useState<Array<string>>([]);
    const [rankingToTag, setRankingToTag] = useState<{ [index: string]: Array<string> }>({});
    const [reload, setReload] = useState<boolean>(true)

    const [tab, setTab] = useRecoilState(loggedInTabState);

    const navigate = useNavigate();

    const filterOptions = useMemo(() => createFilterOptions({ trim: true, stringify: (option: string) => option }), []);

    useEffect(() => {
        getUserTags(props.user.id, signal).then(tags => {
            setUserTags(tags.map(tag => tag.name));
            for (let tag of tags) {
                for (let ranking of tag.imageRankingIds) {
                    if (!rankingToTag[ranking]) {
                        rankingToTag[ranking] = [tag.name]
                    }
                    else {
                        rankingToTag[ranking] = Array.from(new Set(rankingToTag[ranking]).add(tag.name));
                    }
                }
            }
            setRankingToTag({ ...rankingToTag });
        });
    }, [props.user]);

    useEffect(() => {
        if (reload) {
            props.setTitle("Rankings");
            getUserImageRankings(props.user.id).then(result => {
                if (result instanceof Error) {
                }
                else {
                    setRankings(result.reduce((dict, element) => { return { ...dict, [element.id]: element } }, {} as { [index: string]: ImageRanking }));
                }
            });
        }
        setReload(false);

    }, [props.user, reload]);


    function handleDelete(imageRanking: ImageRanking) {
        deleteImageRanking(imageRanking).then(response => setReload(true));
    }

    const renderedRankings = useMemo(() =>
        <React.Fragment>
            {sortByOrdering(order, Object.values(rankings)).map(id => rankings[id]).map(ranking => {
                let createDate = new Date(ranking.createDate);
                let modifyDate = new Date(ranking.lastModified);
                return <Grid item key={ranking.id}>
                    <Card>
                        <CardContent>
                            <Typography>{ranking.name}</Typography>
                            <Typography>Created: {createDate.toLocaleString()}</Typography>
                            <Typography>Last Modified: {modifyDate.toLocaleString()}</Typography>
                            <Typography>Number of Images: {ranking.imageIds.length}</Typography>
                            {rankingToTag[ranking.id] ? rankingToTag[ranking.id].map(tag =>
                                <Chip
                                    key={tag}
                                    size="medium"
                                    variant="filled"
                                    label={tag}
                                    sx={{ height: "1rem" }} />) : null}
                        </CardContent>
                        <CardActions>
                            <Button startIcon={<DeleteOutlined />} onClick={() => handleDelete(ranking)}>Delete</Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    localStorage.setItem("selectedFolio", JSON.stringify(ranking)); setTab("gallery"); navigate("../gallery");
                                }}>
                                View in Gallery
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            })}
        </React.Fragment >
        , [order, rankings, rankingToTag])

    const tagAndNameAutoComplete = useMemo(() =>
        <TagAndNameAutocomplete
            tags={userTags}
            imageRankings={Object.values(rankings)}
            setTags={setSelectedTags}
            setImageRankings={setSelectedRankings} />,
        [userTags, rankings])


    return (
        <Grid container direction="column" justifyContent="center" spacing={2} sx={{ mt: 8, width: "100%" }}>
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" onClick={() => props.setPage("new")}>
                    <Box><Add />
                        <Typography>Create New Ranking</Typography>
                    </Box>
                </Button>
            </Grid>
            <Grid container item >
                <Grid item xs></Grid>
                <Grid item xs>
                    <Toolbar>
                        {tagAndNameAutoComplete}
                    </Toolbar>
                </Grid>
                <Grid item xs></Grid>
            </Grid>
            <Grid container item spacing={1} sx={{ width: "80vw", display: "flex", justifyContent: "flex-start" }}>
                {renderedRankings}
            </Grid>
        </Grid>
    );
}
interface RankingExplorerProps {
    user: User;
    setPage: Dispatch<SetStateAction<string>>;
    setTitle: Dispatch<SetStateAction<string>>;
}


export function TagAndNameAutocomplete(props: TagAndNameAutocompleteProps) {
    const [options, setOptions] = useState<Array<AutocompleteOption>>([]);
    const [autocompleteValue, setAutocompleteValue] = useState<Array<AutocompleteOption>>([]);


    const [windowY, setWindowY] = useState<number>(window.innerHeight);
    const [windowX, setWindowX] = useState<number>(window.innerWidth);

    window.addEventListener("resize", handleResize);

    function handleResize() {
        setWindowY(window.innerHeight);
        setWindowX(window.innerWidth);
    }

    useEffect(() =>
        setOptions([...props.tags.map(tag => { return { type: "tag", value: tag } as AutocompleteOption }),
        ...props.imageRankings.map(ranking => { return { type: "ranking", value: ranking } as AutocompleteOption })]),
        [props.tags, props.imageRankings]);

    useEffect(() => {
        props.setTags(autocompleteValue.filter(value => value.type === "tag").map(value => value.value as string));
        props.setImageRankings(autocompleteValue.filter(value => value.type === "ranking").map(value => value.value as ImageRanking))
    }, [autocompleteValue]);

    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            options={options}
            value={autocompleteValue}
            onChange={(event, value, reason) => setAutocompleteValue(value)}
            isOptionEqualToValue={(option, value) => option.type == value.type && option.value == value.value}
            filterSelectedOptions
            getOptionLabel={(option) => {
                if (option.type === "tag") {
                    return option.value as string;
                }
                else {
                    return (option.value as ImageRanking).name
                }
            }}
            renderInput={(params) => (
                <TextField
                    placeholder="Select Tags or Titles"
                    {...params}
                    sx={{ width: windowX > 900 ? "30vw" : "90vw" }}
                />
            )}
        />
    )
}

interface TagAndNameAutocompleteProps {
    tags: Array<string>
    imageRankings: Array<ImageRanking>
    setTags: Dispatch<SetStateAction<Array<string>>>
    setImageRankings: Dispatch<SetStateAction<Array<ImageRanking>>>
}

interface AutocompleteOption {
    type: "tag" | "ranking"
    value: string | ImageRanking
}

function sortByOrdering(ordering: string, imageRankings: Array<ImageRanking>): Array<string> {
    switch (ordering) {
        case "none":
            return imageRankings.map(ranking => ranking.id);
        case "name-ascending":
            return imageRankings.sort(sortString).map(ranking => ranking.id);
        case "name-descending":
            return imageRankings.sort(sortString).reverse().map(ranking => ranking.id);
        case "create-time-ascending":
            return imageRankings.sort((a, b) => a.createDate - b.createDate).map(ranking => ranking.id); case "create-time-ascending":
        case "create-time-descending":
            return imageRankings.sort((a, b) => -(a.createDate - b.createDate)).map(ranking => ranking.id); case "create-time-ascending":
        default:
            return imageRankings.map(ranking => ranking.id);
    }
}