import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";

export default function NewFolderDialog(props: NewFolderDialogProps) {
    const [value, setValue] = useState<string>("");
    return (
        <Dialog open={props.open}>
            <DialogTitle>New Folder</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => { e.preventDefault(); props.handleSubmit(e, value) }} >
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Folder name"
                        fullWidth
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        variant="standard" />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleCancel}>Cancel</Button>
                <Button onClick={(e) => props.handleSubmit(e, value)} variant="contained">Create</Button>
            </DialogActions>
        </Dialog >
    );
}

interface NewFolderDialogProps {
    open: boolean;
    handleSubmit: (event: SyntheticEvent, name: string) => any
    handleCancel: (event: SyntheticEvent) => any
}