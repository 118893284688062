import RUNTIME_CONFIG from "../config/Configuration";
import { Folder, ImageSrc } from "../datamodel/DataModel";


export async function moveImages(images: Array<ImageSrc>, folderId: String): Promise<Array<ImageSrc>> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/images/folder/" + folderId, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    mode: "cors",
    cache: "default",
    body: JSON.stringify(images)
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  });
}

export async function deleteImage(image: ImageSrc): Promise<ImageSrc> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/image", {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    mode: "cors",
    cache: "default",
    body: JSON.stringify(image)
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  });
}

export async function deleteImages(images: Array<ImageSrc>): Promise<Array<ImageSrc>> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/images", {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    mode: "cors",
    cache: "default",
    body: JSON.stringify(images)
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    }
    else {
      throw Error(response.statusText);
    }
  });
}

export async function getFolderImages(folderId: string, signal?: AbortSignal): Promise<Array<ImageSrc>> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/images/folder/" + folderId,
    {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      signal: signal
    }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      }
      else {
        throw Error(response.statusText);
      }
    });
}

export async function getImagesByIds(imageIds: Array<string>, signal?: AbortSignal): Promise<Array<ImageSrc>> {
  return fetch(RUNTIME_CONFIG.api_address + "/api/images/" + imageIds.join(","),
    {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      signal: signal
    }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      }
      else {
        throw Error(response.statusText);
      }
    });
}

export async function uploadImage(image: File, folder: Folder, signal: AbortSignal): Promise<ImageSrc> {
  let formData = new FormData();
  formData.set("image", image)
  formData.set("name", image.name)
  formData.set("folderId", folder.id)
  formData.set("type", image.type)
  formData.set("lastModified", image.lastModified.toString())

  return fetch(RUNTIME_CONFIG.api_address + "/api/image/", {
      method: "POST",
      credentials: "include",
      headers: {
      },
      mode: "cors",
      body: formData,
      signal: signal
  }).then(response => {
      if (response.status >= 200 && response.status <= 299) {
          return response.json();
      }
      else {
          throw Error(response.statusText);
      }
  })
}